import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import { LANG } from '../../../../config/config';
import Switch from 'react-switch';
import { SlideScenarioLayout } from '../SlideScenarioLayout';
import { ImageBlock } from '../../../../components/ImageBlock';
import { Col, Row } from 'react-bootstrap';
import { ImageService } from '../../../../services/image.service';
import { QUESTION_LAYOUT } from '../../../../config/constants/layout';

class SlideInformation extends Component {

  constructor(props) {
    super(props);

    this.ImageService = new ImageService(this.props.book.id);

    this.state = {
      slide: props.slide,
      loading: false
    };
  }

  componentDidMount() {
    this.loadImage();
  }

  static getDerivedStateFromProps(props, state) {
    if (state.slide !== props.slide) {
      return { slide: props.slide };
    }
    return null;
  }

  loadImage = () => {
    const slide = this.state.slide;

    if (!slide.image.name) return;

    this.setState({
      loading: true
    });

    this.ImageService.getImageByFilename(slide.image.name)
    .then(image => {
      slide.image = image;

      this.setState({
        loading: false
      });
    })
    .catch(() => {
      this.setState({
        loading: false
      });
    });
  };

  setLayout = (selector) => {
    const layout = selector[selector.selectedIndex].value;
    this.props.handleChange('layout', layout);
  };

  handleEditorChange = (content) => {
    this.props.handleChange('desc', content);
  };

  handleImageChange = (field, value) => {
    let slide = this.state.slide;
    if (!slide) return;

    slide[field] = value;
    this.setState({ slide: slide });
  };


  render() {
    const { slide, loading } = this.state;
    const { isIntro, book } = this.props;
    const offStyle = { opacity: .25 };

    return (
      <Form className="Slide-Information">
        <Row>
          <Col lg={slide.is3d ? 12 : 7}>
            <Form.Group>
              <Form.Label>
                {LANG.Name}
              </Form.Label>
              <Form.Control type="text"
                            size='lg'
                            className='font-weight-bold'
                            value={slide.title}
                            onChange={(e) => this.props.handleChange('title', e.target.value)}/>
            </Form.Group>

            {!isIntro &&
            <Form.Group>
              <div className='d-flex align-items-center justify-content-between'>
                <Form.Label>
                  {LANG.ContentType}
                </Form.Label>
                <div className='d-flex align-items-center'>
                  <strong style={slide.is3d ? offStyle : {}}>2D {LANG.Content}</strong>
                  <Switch onChange={() => this.props.handleChange('is3d', !slide.is3d)}
                          checked={slide.is3d}
                          onColor={'#6c757d'}
                          offColor={'#6c757d'}
                          uncheckedIcon={null}
                          checkedIcon={null}
                          className='mx-3'/>
                  <strong style={!slide.is3d ? offStyle : {}}>3D {LANG.Content}</strong>
                </div>
              </div>
            </Form.Group>}

            {!slide.is3d && <Form.Group>
              <Form.Label>
                {LANG.ImagePlacement}
              </Form.Label>
              <Form.Control as="select" onChange={(e) => this.setLayout(e.currentTarget)}
                            value={slide.layout}>
                <option value={QUESTION_LAYOUT.IMG_UP}>{LANG.ImagePlacementUp}</option>
                <option value={QUESTION_LAYOUT.IMG_DOWN}>{LANG.ImagePlacementDown}</option>
                <option value={QUESTION_LAYOUT.IMG_LEFT}>{LANG.ImagePlacementLeft}</option>
                <option value={QUESTION_LAYOUT.IMG_RIGHT}>{LANG.ImagePlacementRight}</option>
              </Form.Control>
            </Form.Group>}

            {slide.is3d && <SlideScenarioLayout activeScenario={slide.scenarioId}
                                                handleChange={this.props.handleChange}/>}
          </Col>

          {!slide.is3d && <Col lg={5}>
            <ImageBlock image={slide.image}
                        title="Pozadí obrazovky"
                        loading={loading}
                        book={book}
                        handleChange={this.handleImageChange}/>
          </Col>}
        </Row>
      </Form>
    );
  }
}

export { SlideInformation };
