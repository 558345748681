import { SlideContent } from './SlideContent';

export class Audio extends SlideContent {
  fetchUrl;
  name;
  label;

  constructor(order = null, name = '', label = '') {
    super(order);
    this.fetchUrl = '';
    this.name = name;
    this.label = label;
  }
}

