import { SlideContent } from './SlideContent';

export class Question extends SlideContent {

    // attributes
    type; // QUESTION enum type
    shuffle; // boolean
    validate; // boolean

    // fillText
    caseSensitive; // boolean

    // interval question
    interval; // list of objects [{valid: boolean, start: string, end: string}]
    step; // string

    // toggle
    options; // list of objects [{id: int, text: string}]

    // common
    description; // string
    response; // string
    variant; // string // list of strings // list of objects [{text: string, valid: boolean, option: int}]

    constructor(order = -1) {
        super(order);
    }
}
