import React from 'react';
import Form from 'react-bootstrap/Form';
import { LANG } from '../../../../config/config';

export const Shuffle = ({ question, id, handleChange }) =>
  <Form.Check type='checkbox'
              custom
              label={LANG.ShuffleAnswers.toLowerCase()}
              name='shuffle'
              id={`shuffle-${question.type}-${id}`}
              defaultChecked={question.shuffle}
              onClick={(e) => handleChange(e.target)}/>;
