import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { PrivateRoute } from './app/helpers/PrivateRoute';
import { changeConfigLang, CONFIG } from './app/config/config';
import { ROLE } from './app/config/constants/role';
import { LoginPage } from './app/pages/LoginPage/LoginPage';
import { AuthService } from './app/services/auth.service';
import { UsersPage } from './app/pages/UsersPage/UsersPage';
import { ApiKeys } from './app/pages/ApiKeysPage/ApiKeys';
import { ScenesPage } from './app/pages/ScenesPage/ScenesPage';
import { BooksList } from './app/pages/BooksList/BooksList';
import { ResetPassword } from './app/pages/LoginPage/ResetPassword';
import { ResetPasswordRequest } from './app/pages/LoginPage/ResetPasswordRequest';
import { BookPage } from './app/pages/BookPage/BookPage';

class App extends React.Component {

  constructor(props) {
    super(props);

    this.Auth = new AuthService();
    const localLang = localStorage.getItem('lang');

    this.state = {
      lang: localLang ? localLang : 'cs'
    };
    changeConfigLang(this.state.lang);
  }

  componentDidMount() {
    if (!this.Auth.isLoggedIn()) {
      this.Auth.logout();
      return <Redirect to={{ pathname: '/login' }}/>;
    }
  }

  updateLang = (newLang) => {
    localStorage.setItem('lang', newLang);
    this.setState({
      lang: newLang
    }, this.forceUpdate);
  };

  toggleLang = () => {
    const lang = this.state.lang;
    const newLang = lang === 'cs' ? 'en' : 'cs';

    changeConfigLang(newLang);
    this.updateLang(newLang);
  };

  render() {
    return (
      <Router basename={CONFIG.basename}>
        <Switch>

          {/* homepage */}
          <Route exact path="/" render={props => {
            return <Redirect to={{ pathname: '/books', state: { from: props.location } }}/>;
          }}/>

          {/* books editor */}
          <PrivateRoute path="/books" roles={[ROLE.Editor, ROLE.InstitutionAdmin]} component={BooksList} toggleLang={this.toggleLang}/>
          <PrivateRoute path="/book/:bookId" roles={[ROLE.Editor, ROLE.InstitutionAdmin]} component={BookPage} toggleLang={this.toggleLang}/>

          {/* scenes editor */}
          <PrivateRoute path="/scenes" roles={[ROLE.Editor, ROLE.InstitutionAdmin]} component={ScenesPage} toggleLang={this.toggleLang}/>

          {/* users editor */}
          <PrivateRoute path="/users" roles={[ROLE.InstitutionAdmin]} component={UsersPage} toggleLang={this.toggleLang}/>

          {/* api keys editor */}
          <PrivateRoute path="/keys" roles={[ROLE.InstitutionAdmin]} component={ApiKeys} toggleLang={this.toggleLang}/>

          {/* anonymous */}
          <Route path="/login" component={LoginPage}/>
          <Route path="/reset-request" component={ResetPasswordRequest}/>
          <Route path="/reset/:token" component={ResetPassword}/>
          <Route path="/logout" render={props => {
            this.Auth.logout();
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }}/>;
          }}/>

          {/* other */}
          <Redirect to={{ pathname: '/login' }}/>;
        </Switch>
      </Router>
    );
  }
}

export default App;
