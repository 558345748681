import decode from 'jwt-decode';
import { CONFIG } from '../config/config';
import { CS as LANG } from '../config/languages/cs';

class AuthService {
    login(username, password) {

        // console.log('logining in with: ' + username + ' ' + password);

        return this.fetch(`${CONFIG.apiUrl}/api/Auth/SignIn`, {
            method: 'POST',
            body: JSON.stringify({
                username: username,
                password: password
            })
        }, true).then(
            res => {
                // console.log('fetch result:', res);

                if(!res) throw new Error(LANG.LogoutMessage)

                this.setToken(res.accessToken.token);
                this.setRefreshToken(res.refreshToken);
                return Promise.resolve(res);
            })
    }

    isLoggedIn() {
        const token = this.getToken();
        return !!token && !this.isTokenExpired(token);
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            return decoded.exp < Date.now() / 1000;
        } catch (err) {
            return false;
        }
    }

    setToken(idToken) {
        localStorage.setItem('id_token', idToken)
    }

    setRefreshToken(idToken) {
        localStorage.setItem('id_token_refresh', idToken)
    }

    getToken() {
        return localStorage.getItem('id_token')
    }

    getRefreshToken() {
        return localStorage.getItem('id_token_refresh')
    }

    logout() {
        // console.log('logout')
        localStorage.removeItem('id_token');
        localStorage.removeItem('id_token_refresh');
    }

    getProfile() {
        if (this.isLoggedIn()) {
            return decode(this.getToken());
        } else {
            return false;
        }
    }

    refreshToken() {
        // console.log('refreshing');
        return this.fetch(`${CONFIG.apiUrl}/api/Auth/Refresh`, {
            method: 'POST',
            body: JSON.stringify({
                accessToken: this.getToken(),
                refreshToken: this.getRefreshToken()
            })
        }, true)
            .then(res => {
                // console.log('fetch result:', res);
                const {accessToken, refreshToken} = res;

                this.setToken(accessToken.token);
                this.setRefreshToken(refreshToken);
                return Promise.resolve(res);
            })
            .catch(err => {throw err})
    }


    fetch(url, options, noRepeat) {
        // console.log('fetch called', noRepeat)

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }

        if(this.isLoggedIn()) {
            headers['Authorization'] = 'Bearer ' + this.getToken()
        }

        return fetch(url, {
            headers,
            ...options
        })
            .then(this._checkStatus)
            .then(response => {

                return response.text().then(function (text) {
                    // console.log('text', text);
                    return text ? JSON.parse(text) : {}
                });
            })
            .catch(error => {

                if(error.response && error.response.status === 401) {
                    // console.log('UNAUTHORISED')

                    if(noRepeat) {
                        // force logout
                        if(this.isLoggedIn()) {
                            const logout = CONFIG.basename.endsWith('/') ? 'logout' : '/logout';

                            window.location = window.location.protocol + '//' + window.location.host + CONFIG.basename + logout;

                            return false
                        } else {
                            // or throw error
                            throw error
                        }
                    }

                    if(this.isTokenExpired(this.getToken())) {
                        // console.log('TOKEN EXPIRED')
                        return this.refreshToken()
                            .then(res => {
                                // console.log(res)
                                return this.fetch(url, options, true)
                            } )
                    }
                }
                else {
                    throw error
                }
            })
    }

    async _checkStatus(response) {
        if (response.status >= 200 && response.status < 300) {
            return response;
        } else {
            return response.json().then(json => {
                const desc = json.name ? json.name : response.statusText;
                let error = new Error(desc);
                error.response = response;
                throw error;
            });
        }
    };
}

export {AuthService};
