import React from 'react';
import ReactDOM from 'react-dom';
import './assets/scss/app.scss';
import App from './App';
// Support fetch() in Internet Explorer
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
// Font Awesome icons
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAlignLeft,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faArrowCircleLeft,
  faArrowsAltV,
  faArrowUp,
  faBook,
  faBookMedical,
  faCheck,
  faCheckCircle,
  faCogs,
  faColumns,
  faCopy,
  faCube,
  faExternalLinkAlt,
  faGripLines,
  faHeadphones,
  faHeart,
  faHome,
  faImage,
  faImages,
  faListUl,
  faMapMarkedAlt,
  faMinus,
  faMobileAlt,
  faPaperPlane,
  faPen,
  faPenAlt,
  faPhotoVideo,
  faPlus,
  faQuestionCircle,
  faSave,
  faSearch,
  faSignOutAlt,
  faTasks,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUser,
  faUserCog,
  faUserGraduate,
  faUserPlus,
  faUsers,
  faUserShield,
  faUserTie,
  faVideo
} from '@fortawesome/free-solid-svg-icons';

library.add(faPen, faTrashAlt, faSearch, faSave, faTimes,
  faColumns, faGripLines, faPlus, faAngleLeft,
  faAngleRight, faListUl, faSignOutAlt, faArrowCircleLeft, faArrowsAltV, faAngleDown, faHeart, faUsers, faUserPlus, faBook,
  faBookMedical, faUserTie, faTasks, faCogs, faCube, faAlignLeft, faImage, faImages, faMinus, faCopy, faVideo, faHeadphones,
  faUser, faExternalLinkAlt, faImages, faHome, faCheckCircle, faTimesCircle, faMapMarkedAlt, faQuestionCircle,
  faUserGraduate, faPaperPlane, faCheck, faArrowUp, faMobileAlt, faUserShield, faUserCog, faPenAlt, faPhotoVideo);

// Render app
ReactDOM.render(<App/>, document.querySelector('main'))
