import React, { Component } from 'react';
import { LANG } from '../../../config/config';
import Form from 'react-bootstrap/Form';
import { TaskMap } from './TaskMap';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class TaskDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      task: props.task
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.task !== props.task) {
      return { task: props.task };
    }
    return null;
  }

  handleChange = (field, value) => {
    let task = this.state.task;
    if (!task) return;

    task[field] = value;
    this.setState({ task: task });
  };

  handleLocationChange = (lng, lat) => {
    let task = this.state.task;
    task.setLocation(lng, lat);

    this.setState({ task: task });
  };

  render() {

    const { task } = this.state;
    const { tasks, showMap } = this.props;

    console.log(task);

    return (
      <div className='TaskDetail'>
        <Form>
          <Form.Group>
            <Form.Label>
              {LANG.Name}
            </Form.Label>
            <Form.Control type="text"
                          size='lg'
                          className='font-weight-bold'
                          value={task && task.title ? task.title : ''}
                          onChange={(e) => this.handleChange('title', e.target.value)}/>
          </Form.Group>


          {showMap && <TaskMap task={task}
                               tasks={tasks}
                               setLocationOK={this.props.setLocationOK}
                               updateTask={this.handleChange}
                               updateTaskLocation={this.handleLocationChange}
                               changeActiveTask={this.props.changeActive}
          />}

          {task &&
          <>
            <div className='TaskAddSlide'>
              <Button variant='primary'
                      size='sm'
                      onClick={() => this.props.addSlide(task)}>
                {LANG.AddSlide}
                <FontAwesomeIcon icon='plus' className='ml-3'/>
              </Button>
            </div>
          </>}

          <div className='CloudAnchor'>
             <Form.Group>
               <Form.Label>CloudAnchor ID</Form.Label>
               <Form.Control type="text" 
                             value={task && task.cloudAnchorID ? task.cloudAnchorID : ''}
                             onChange={(e) => this.handleChange('cloudAnchorID', e.target.value)}/>
               <Form.Label>CloudAnchor orientation</Form.Label>
               <Form.Control type="text" 
                             value={task && task.cloudAnchorOrientation ? task.cloudAnchorOrientation : ''}
                             onChange={(e) => this.handleChange('cloudAnchorOrientation', e.target.value)}/>
             </Form.Group>
          </div>
        </Form>
      </div>
    );
  }
}

export { TaskDetail };
