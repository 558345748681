import { CONFIG } from '../config/config';
import { AuthService } from './auth.service';

class AssetsService {

    assetsKey = 'assets';
    bookIdKey = 'assetsBookId';
    Auth = new AuthService()

    getAssetsForBook(bookId) {
        const savedAssets = this.getCachedAssets(bookId);
        if(savedAssets.length) {
            return Promise.resolve(savedAssets);
        }

        return this.Auth.fetch(`${CONFIG.apiUrl}/api/Assets/${bookId}`, {
            method: 'GET'
        }).then(res => Promise.resolve(res)).then(assets => {
            this.storeAssets(bookId, assets);
            return assets;
        });
    }

    uploadAsset(formData, bookId) {

        const headers = {},
            assetName = formData.get('file').name;

        if (this.Auth.isLoggedIn()) {
            headers['Authorization'] = 'Bearer ' + this.Auth.getToken()
        }

        const options = {
            method: 'POST',
            body: formData,
            headers: headers
        }

        return fetch(`${CONFIG.apiUrl}/api/Assets/${bookId}/${assetName}`, options).then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response.json()
            } else {
                const text = response.status === 400 ? `Obrázek s názvem ${assetName} již existuje` : response.statusText;
                let error = new Error(text);
                error.response = response;
                throw error
            }
        });
    }

    storeAssets(bookId, assets) {
        sessionStorage.setItem(this.bookIdKey, bookId);
        sessionStorage.setItem(this.assetsKey, JSON.stringify(assets));
    }

    getCachedAssets(bookId) {
        const assets = sessionStorage.getItem(this.assetsKey);
        const assetsBookId = sessionStorage.getItem(this.bookIdKey);

        if(!assets) {
            return [];
        }

        if(bookId !== undefined && +assetsBookId !== +bookId) {
            sessionStorage.removeItem(this.bookIdKey);
            sessionStorage.removeItem(this.assetsKey);

            return [];
        }

        return JSON.parse(assets);
    }
}

export {AssetsService}
