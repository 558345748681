import { Description } from '../classes/Description';

class DescriptionService {

  convertToClass(object) {
    let type = '', content = '', order = -1;

    if (object._attributes) {
      type = object._attributes.type ? object._attributes.type : type;
      order = object._attributes.order ? object._attributes.order : order;
    }

    content = object._cdata ? object._cdata : content;

    return new Description(type, content, order);
  }
}

export { DescriptionService };
