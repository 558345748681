import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Col from 'react-bootstrap/Col';
import { MEDIA_TYPE } from '../../../config/constants/media';

const GalleryItem = ({ asset, chosenImage, toggleImage }) => {
  return (
    <Col xs={2} className='Gallery-Item' ref={asset.ref}>
      <div className='image' onClick={() => toggleImage(asset)}>
        <div className='img' style={asset.type === MEDIA_TYPE.IMAGE ? { background: `#f8f9fa url(${asset.fetchUrl})` } : {}}/>

        <div className='placeholder'>
          {asset.type === MEDIA_TYPE.IMAGE && <FontAwesomeIcon icon='images'/>}
          {asset.type === MEDIA_TYPE.VIDEO && <FontAwesomeIcon icon='video'/>}
          {asset.type === MEDIA_TYPE.AUDIO && <FontAwesomeIcon icon='headphones'/>}
        </div>

        {asset === chosenImage &&
        <span className='active-frame'>
            <div className='check-holder'>
                <FontAwesomeIcon icon='check'/>
            </div>
        </span>}
      </div>
      <div className='name'>{asset.name}</div>
    </Col>
  );
};

export { GalleryItem };
