import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {LANG} from "../../config/config";
import {SubmitButton} from "../../components/Buttons/SubmitButton";
import {FloatingAlert} from "../../components/Alert";
import {AuthService} from "../../services/auth.service";
import {UserService} from "../../services/user.service";

class ResetPasswordRequest extends Component {
    constructor(props) {
        super(props);

        this.Auth = new AuthService();
        this.User = new UserService();

        this.state = {
            username: '',
            submitted: false,
            loading: false,
            error: '',
            success: ''
        };
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value.replace(/\s/g, '') });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        this.setState({ error: false, submitted: true });
        const { username } = this.state;

        if (!username) {
            return;
        }

        this.setState({ loading: true });

        this.User.resetPassword(username).then(() => {
            this.setState({
                success: 'Na e-mail vám bude odeslán odkaz pro obnovení hesla',
                loading: false
            });
        }).catch(() => {
            this.setState({
                error: LANG.ErrorMessage,
                loading: false
            });
        });
    };

    render() {

        const { username, submitted, loading, error, success } = this.state;

        return (
            <div className='loginPage'>

                <h3 className="mb-4">Zapomenuté heslo</h3>

                {success && <div className='resetSuccess'>
                    <strong className="mb-4">{success}</strong>
                </div>}

                {!success &&
                    <form name="form" onSubmit={this.handleSubmit}>
                        <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                            <label htmlFor="username">E-mail</label>
                            <input type="text" className="form-control" name="username" value={username}
                                   onChange={this.handleChange}/>
                            {submitted && !username &&
                            <div className="alert alert-warning mt-2">{LANG.EmptyUsernameError}</div>}
                        </div>
                        <SubmitButton loading={loading} label={'Nastavit nové heslo'}/>
                    </form>
                }

                <div className='backToLogin'>
                    <NavLink to="/login">
                        <div className={'icon'}>
                            <FontAwesomeIcon icon='angle-left'/>
                        </div>
                        <span>Zrušit</span>
                    </NavLink>
                </div>

                <FloatingAlert type='error' message={error} show={error}/>
            </div>
        );
    }
}

export {ResetPasswordRequest};
