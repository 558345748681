import React from "react";
import {confirmAlert} from "react-confirm-alert";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {LANG} from "../config/config";


export const ConfirmationModal = ({message, action, show, hideAction}) => {

    const showClass = show ? ' show' : '';

    return <div className={'ConfirmationModal' + showClass}>
        <div className='ConfirmationModal-Content'>
            <div className='ConfirmationModal-Close' onClick={hideAction}>
                <FontAwesomeIcon icon='times'/>
            </div>

            <div className='message'>{message}</div>

            <div className='ConfirmationModal-Buttons'>
                <Button variant="secondary" onClick={hideAction}>
                    {LANG.No}
                </Button>
                <Button variant="primary" onClick={action}>
                    {LANG.Yes}
                </Button>
            </div>
        </div>
    </div>
}
const confirmNavigation = (nextLocation, handleConfirmedNavigation) => {
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-body'>
                            <h3>Opustit stránku bez uložení změn?</h3>
                        </div>
                        <div className='modal-footer'>
                            <Button variant="secondary" onClick={onClose}>
                                Storno
                            </Button>
                            <Button variant="danger" onClick={() => {
                                handleConfirmedNavigation(nextLocation.pathname)
                                onClose()
                            }}>
                                Zahodit změny a opustit stránku
                            </Button>
                        </div>
                    </div>
                </div>
            )
        }
    })
}

const confirmSlideDelete = (slide, action) => {
    // console.log(slide, action)
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-body'>
                            <h3>{LANG.AreYouSure} smazat obrazovku <span className='color-primary'>{slide.title}</span>?
                            </h3>
                        </div>
                        <div className='modal-footer'>
                            <Button variant="secondary" onClick={onClose}>
                                Storno
                            </Button>
                            <Button variant="danger" onClick={() => {
                                action(slide)
                                onClose()
                            }}>
                                Ano, smazat <FontAwesomeIcon icon="times" className='ml-3'/>
                            </Button>
                        </div>
                    </div>
                </div>
            )
        }
    })
}
const confirmKeyDelete = (key, action) => {
    // console.log(slide, action)
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-body'>
                            <h3>{LANG.AreYouSure} smazat klíč s názvem <span className='color-primary'>{key.name}</span>?
                            </h3>
                        </div>
                        <div className='modal-footer'>
                            <Button variant="secondary" onClick={onClose}>
                                Storno
                            </Button>
                            <Button variant="danger" onClick={() => {
                                action(key)
                                onClose()
                            }}>
                                Ano, smazat <FontAwesomeIcon icon="times" className='ml-3'/>
                            </Button>
                        </div>
                    </div>
                </div>
            )
        }
    })
}

const confirmTaskDelete = (task, action) => {
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-body'>
                            <h3>{LANG.AreYouSure} smazat úkol <span className='color-primary'>{task.title}</span>?
                            </h3>
                        </div>
                        <div className='modal-footer'>
                            <Button variant="secondary" onClick={onClose}>
                                Storno
                            </Button>
                            <Button variant="danger" onClick={() => {
                                action(task)
                                onClose()
                            }}>
                                Ano, smazat <FontAwesomeIcon icon="times" className='ml-3'/>
                            </Button>
                        </div>
                    </div>
                </div>
            )
        }
    })
}


export {confirmNavigation, confirmSlideDelete, confirmTaskDelete, confirmKeyDelete}
