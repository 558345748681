import {CONFIG} from "../config/config";
import {AuthService} from "./auth.service";

class ApiService {
    Auth = new AuthService()

    getAll() {
        return this.Auth.fetch(`${CONFIG.apiUrl}/api/Institutions/ApiKey`, {
            method: 'GET'
        }).then(res => Promise.resolve(res))
    }

    add(name) {
        return this.Auth.fetch(`${CONFIG.apiUrl}/api/Institutions/ApiKey`, {
            method: 'POST',
            body: JSON.stringify({
                name: name,
                books: [],
                scenarios: []
            })
        }).then(
            res => {
                return Promise.resolve(res)
            })
    }

    remove(key) {
        return this.Auth.fetch(`${CONFIG.apiUrl}/api/Institutions/ApiKey/${key.jti}`, {
            method: 'DELETE'
        }).then(
            res => {
                return Promise.resolve(res)
            })
    }

    update(key) {
        // console.log('updating', key)
        return this.Auth.fetch(`${CONFIG.apiUrl}/api/Institutions/ApiKey/${key.jti}`, {
            method: 'PUT',
            body: JSON.stringify({
                name: key.name,
                books: key.books,
                scenarios: key.scenarios
            })
        }).then(
            res => {
                return Promise.resolve(res)
            })
    }
}

export {ApiService}
