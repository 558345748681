import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { SortableItem } from './SortableItem';

export const SortableList = SortableContainer(({ items, openedIndex, book, updateAction, removeItem, toggleItem, loading }) =>
  <div className='SlideContents__items'>
    {items.map((value, index) => (
      <SortableItem key={`item-${index}`} index={index}
                    item={value}
                    myIndex={index}
                    openedIndex={openedIndex}
                    book={book}
                    loading={loading}
                    removeItem={removeItem}
                    toggleItem={toggleItem}
                    updateAction={updateAction}/>
    ))}
  </div>
);
