import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {Link} from "react-router-dom";

export const Breadcrumbs = ({ pageTitle }) => {

    return <div className='Breadcrumbs'>
        {!!pageTitle.length && pageTitle.map((part, key) => {
            const isLast = key === pageTitle.length - 1
            const $item = isLast ? <DisabledItem title={part.title} key={key}/> : <Item item={part} key={key}/>
            return $item
        })}
    </div>
}

const DisabledItem = ({title, ...rest}) => {
    return <div className='Breadcrumbs-Item' {...rest}>{title}</div>
}
const Item = ({item, ...rest}) => {
    return <>
        <Link to={item.path} {...rest}>{item.title}</Link>
        <FontAwesomeIcon icon='angle-right'/>
    </>
}
