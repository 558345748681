import React from 'react';
import Form from 'react-bootstrap/Form';

export const Response = ({ question, handleChange }) => (

  <Form.Group>
      <Form.Label>
          Reakce po odpovědi <i>(nepovinné)</i>
      </Form.Label>
      <Form.Control as="textarea"
                    rows="2"
                    value={question.response}
                    placeholder="např. vysvětlení proč je dána odpověď správná"
                    onChange={(e) => handleChange('response', e.target.value)}/>
  </Form.Group>
);
