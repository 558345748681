export const QUESTION = {
    SORT: 'sort',
    SINGLECHOICE: 'singlechoice',
    MULTICHOICE: 'multichoice',
    INTERVALQUESTION: 'intervalquestion',
    NUMBERQUESTION: 'numberquestion',
    TOGGLEBUTTONSGRID: 'togglebuttonsgrid',
    FILLTEXT: 'filltext',
    DRAGTOLINE: 'dragtoline',
    DRAGTOMIDDLE: 'dragtomiddle'
}
