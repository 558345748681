import React from 'react';
import 'status-indicator/styles.css';
import {FloatingAlert} from "./Alert";

export const SaveStatus = ({status}) => {
    return <>
        <FloatingAlert type='error' message={status.error} show={!!Object.keys(status).length}/>
        <FloatingAlert type='success' message={status.success} show={!!Object.keys(status).length}/>
        </>
}
