import React from 'react';
import { CONFIG } from '../config/config';
import { Editor } from '@tinymce/tinymce-react';

export function WysiwygEditor({ value, onChange }) {
  return (
    <Editor apiKey={CONFIG.editorKey}
            value={value}
            init={{
              encoding: 'xml',
              entities: 'á,é,í,ó,ú,ý,č,ď,ě,ň,ř,š,ť,ž,ů',
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code wordcount nonbreaking'
              ],
              toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link nonbreaking | removeformat | code',
              nonbreaking_wrap: false //nbsp neni obalena specialnimi tagy
            }}
            onEditorChange={onChange}
    />
  );
}
