import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import Card from 'react-bootstrap/Card';
import { Question } from '../../../../../../classes/Question';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LANG } from '../../../../../../config/config';
import { Description } from '../../../../../../classes/Description';
import { SlideQuestion } from '../parts/SlideQuestion';
import { SlideDescription } from '../parts/SlideDescription';
import { DragHandle } from '../../../../../../components/Sortable/DragHandle';
import { Asset, DoubleImage } from '../../../../../../classes/Asset';
import { SlideImage } from '../parts/SlideImage';
import { SlideDoubleImage } from '../parts/SlideDoubleImage';
import { Video } from '../../../../../../classes/Video';
import { SlideMedia } from '../parts/SlideMedia';
import { Audio } from '../../../../../../classes/Audio';

export const SortableItem = SortableElement(({ item, myIndex, openedIndex, book, updateAction, removeItem, toggleItem, loading }) =>
  <div className='SlideContents__item' ref={item.ref}>
    <Card>
      <Card.Header onClick={() => toggleItem(myIndex)}>
        {item instanceof Question ? <>
          <div className='item-type q'>
            <FontAwesomeIcon icon='question-circle'/>
            <span className="QuestionTypeLabel">{LANG.QuestionType[item.type.toUpperCase()]}</span>
          </div>
          <div className="title">{item.description}</div>
        </> : null}
        {item instanceof Description ? <>
          <div className="item-type d">
            <FontAwesomeIcon icon='align-left'/>
            <span className="QuestionTypeLabel">{LANG.DescriptionType[item.type.toUpperCase()]}</span>
          </div>
          <div className="title" dangerouslySetInnerHTML={{ __html: item.content }}/>
        </> : null}
        {item instanceof Asset || item instanceof DoubleImage ? <>
          <div className="item-type i">
            <FontAwesomeIcon icon='images'/>
            <span className="QuestionTypeLabel">{LANG.ImageType[item.type.toUpperCase()]}</span>
          </div>
          <div className="title">{item.label}</div>
        </> : null}
        {item instanceof Video ? <>
          <div className="item-type i">
            <FontAwesomeIcon icon='video'/>
            <span className="QuestionTypeLabel">Video</span>
          </div>
          <div className="title">{item.label}</div>
        </> : null}
        {item instanceof Audio ? <>
          <div className="item-type i">
            <FontAwesomeIcon icon='headphones'/>
            <span className="QuestionTypeLabel">Audio</span>
          </div>
          <div className="title">{item.label}</div>
        </> : null}
      </Card.Header>
      <Card.Body className={openedIndex === myIndex ? 'show' : 'hide'}>
        {item instanceof Question ? <SlideQuestion question={item}
                                                   ownIndex={myIndex}
                                                   updateAction={updateAction}
                                                   book={book}/> : null}
        {item instanceof Description ? <SlideDescription description={item}
                                                         index={myIndex}
                                                         updateAction={updateAction}/> : null}
        {item instanceof Asset ? <SlideImage index={myIndex}
                                             image={item}
                                             book={book}
                                             loading={loading}
                                             updateAction={updateAction}/> : null}
        {(item instanceof Video ||
          item instanceof Audio) ? <SlideMedia index={myIndex}
                                               media={item}
                                               book={book}
                                               updateAction={updateAction}/> : null}
        {item instanceof DoubleImage ? <SlideDoubleImage index={myIndex}
                                                         double={item}
                                                         book={book}
                                                         loading={loading}
                                                         updateAction={updateAction}/> : null}
        <div className='card-footer'>
          <div className='btn-a color-danger mr-3' onClick={() => removeItem(myIndex)}>
            {LANG.Delete} <FontAwesomeIcon icon='trash-alt' size='xs' className='ml-2'/>
          </div>
          <div className='btn-a color-secondary' onClick={() => toggleItem(myIndex)}>
            {LANG.Hide} <FontAwesomeIcon icon='arrow-up' size='xs' className='ml-2'/>
          </div>
        </div>
      </Card.Body>
    </Card>
    <DragHandle/>
  </div>
);
