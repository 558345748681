import React from 'react';
import { DeleteButton } from '../../components/Buttons/DeleteButton';
import { LANG } from '../../config/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

export function BookItem({ book, deleteAction }) {

  function getTasksLabel(number) {
    if (number === 1)
      return 'úkol';
    if (2 <= number && number <= 4)
      return 'úkoly';

    return 'úkolů';
  }

  return (
    <div className="BookItem">
      <div className="BookItem-image">
        {book.image && <div className="img" style={{ background: `url(${book.image})` }}/>}
        {!book.image && <div className="img">No image</div>}
      </div>
      <div className="BookItem-desc">
        <div className="text">
          <h3>{book.name}</h3>
          <div className="header">
            <div className="state">
              {book.isPublished && <span className='yes'>Publikováno <FontAwesomeIcon icon='check-circle' className='ml-1'/></span>}
              {!book.isPublished && <span className='no'>Nepublikováno <FontAwesomeIcon icon='times-circle' className='ml-1'/></span>}
            </div>
            <div className="tasks">
              <div className="number"><span>{book.numberOfTasks}</span>{getTasksLabel(book.numberOfTasks)}</div>
            </div>
          </div>

          <p dangerouslySetInnerHTML={{ __html: book.description }}/>
        </div>

        <div className="BookItem-actions">
          <Link to={'/book/' + book.id}
                className="btn btn-sm btn-primary mr-2">
            <span className="label mr-3">{LANG.EditBook}</span>
            <FontAwesomeIcon icon="pen"/>
          </Link>
          <DeleteButton action={deleteAction}
                        buttonText={LANG.DeleteBook}
                        message={LANG.AreYouSure + ' ' + LANG.DeleteBook + ' ' + book.name + '?'}/>
        </div>
      </div>
    </div>
  );
}
