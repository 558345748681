import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { LANG } from '../../../../../../config/config';

export function SlideDropdown({ title = LANG.Add, items = [] }) {

  if (items.length) {
    return (
      <Dropdown className='SlideDropdown'>
        <Dropdown.Toggle size='sm' variant='success'>
          {title}
        </Dropdown.Toggle>

        <Dropdown.Menu drop="left">
          {items.map((item, key) =>
            <Dropdown.Item key={key} onClick={item.action}>
              {item.label}
            </Dropdown.Item>)}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  return null;
}
