import React from 'react';
import Spinner from "react-bootstrap/Spinner";
import {LANG} from "../config/config";

export function Loading({ show, text = LANG.Loading, size = 'lg' }) {

    const showClass = show ? ' show': '';

    return <div className={"Loading" + showClass + ` ${size}`}>
        <span>{text}</span>
        <Spinner animation="border" role="status" variant="primary" size={size}/>
    </div>
}
