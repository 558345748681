import React, { Component } from 'react';
import { CONFIG, LANG } from '../../../config/config';
import Row from 'react-bootstrap/Row';
import { AssetsService } from '../../../services/assets.service';
import { Loading } from '../../../components/Loading';
import Alert from 'react-bootstrap/Alert';
import { FloatingAlert } from '../../../components/Alert';
import * as ReactDOM from 'react-dom';
import { DeleteButton } from '../../../components/Buttons/DeleteButton';
import { SubmitButton } from '../../../components/Buttons/SubmitButton';
import { audioFormats, imageFormats, MEDIA_TYPE, videoFormats } from '../../../config/constants/media';
import { GalleryItem } from './GalleryItem';
import { Asset } from '../../../classes/Asset';

class Gallery extends Component {

  constructor(props) {
    super(props);

    this.Assets = new AssetsService();

    this.state = {
      book: props.book,
      mediaType: props.mediaType ? props.mediaType : MEDIA_TYPE.ALL,
      assets: [],
      images: [],
      videos: [],
      audios: [],
      uploadedFile: null,
      chosenImage: null,
      uploadStatus: {},
      itemsRef: React.createRef()
    };
  }

  componentDidMount() {
    this.setState({
      assets: [],
      uploadStatus: {}
    });
    this.loadImages();
  }

  loadImages = async () => {
    const bookId = this.state.book.id;

    if (!bookId) return;
    console.log('Getting assets for book with id', bookId);

    this.setState({
      assets: { loading: true }
    });

    try {
      const assets = await this.Assets.getAssetsForBook(bookId);
      console.log('Assets', assets);

      // pokud nejsou v knizce assety, vratime prazdny seznam obrazku
      if (!assets || !assets.length) {
        this.setState({
          assets: []
        });
        return;
      }

      // Convert assets
      this.setState({
        assets: assets.map(a => {
          const asset = new Asset();

          asset.id = a.id;
          asset.name = a.name;
          asset.fetchUrl = CONFIG.apiUrl + a.fetchUrl;
          asset.type = this.getAssetType(a.name.toLowerCase());
          asset.ref = React.createRef();

          return asset;
        })
      }, () => {
        // Put asset into right list
        this.state.assets.forEach(asset => {
          if (asset.type === MEDIA_TYPE.IMAGE) {
            this.setState(prevState => ({ images: [...prevState.images, asset] }));
          } else if (asset.type === MEDIA_TYPE.VIDEO) {
            this.setState(prevState => ({ videos: [...prevState.videos, asset] }));
          } else if (asset.type === MEDIA_TYPE.AUDIO) {
            this.setState(prevState => ({ audios: [...prevState.audios, asset] }));
          }
        });

        // scroll to added image
        if (this.state.uploadStatus.success) {
          this.setState(prevState => ({
            chosenImage: prevState.assets.length ? prevState.assets[prevState.assets.length - 1] : null,
            uploadStatus: {}
          }), () => {
            const { itemsRef, chosenImage } = this.state;
            const itemsContainer = itemsRef.current;
            itemsContainer.scrollTop = ReactDOM.findDOMNode(chosenImage.ref.current).offsetTop;
          });
        }
      });
    } catch (e) {
      // kdyby neco, hod error
      this.setState({
        assets: { error: e.message ? e.message : LANG.ErrorMessage }
      });
    }
  };

  getAssetType = (name) => {
    const parts = name.split('.');
    const ext = parts[parts.length - 1];

    if (imageFormats.includes(ext)) {
      return MEDIA_TYPE.IMAGE;
    } else if (audioFormats.includes(ext)) {
      return MEDIA_TYPE.AUDIO;
    } else if (videoFormats.includes(ext)) {
      return MEDIA_TYPE.VIDEO;
    }

    return MEDIA_TYPE.UNKNOWN;
  };

  uploadImage = () => {

    const { uploadedFile } = this.state;
    const bookId = this.state.book.id;

    let formData = new FormData();
    formData.append('file', uploadedFile);

    this.setState({
      uploadStatus: { loading: true }
    });

    this.Assets.uploadAsset(formData, bookId)
    .then((asset) => {
      this.setState({
        uploadStatus: { success: true }
      }, this.loadImages);
    })
    .catch(error => {
      this.setState({
        uploadStatus: { error: error.message ? error.message : LANG.ErrorMessage }
      });
    });
  };

  toggleImage = (image) => {
    this.setState(prevState => ({
      chosenImage: prevState.chosenImage === image ? null : image
    }));
  };

  handleFileInput = (e) => {
    e.preventDefault();

    const mediaType = this.state.mediaType;

    let reader = new FileReader();
    let file = e.target.files[0];
    e.target.value = null;
    const parts = file.type.toLowerCase().split('/');
    const ext = parts[parts.length - 1];
    const type = this.getAssetType(ext);

    if (type !== MEDIA_TYPE.UNKNOWN && (mediaType === MEDIA_TYPE.ALL
      || type === MEDIA_TYPE.VIDEO || type === MEDIA_TYPE.AUDIO ||
      type === mediaType)) {
      reader.onloadend = () => {
        this.setState({
          uploadedFile: file
        }, this.uploadImage);
      };

      reader.readAsDataURL(file);
    } else {
      this.setState({
        uploadStatus: { error: 'Nepovolený typ souboru' }
      });
    }
  };

  handleDelete = () => {
    const image = this.state.chosenImage;

    console.log('deleting image', image);
  };

  handleImageAction = () => {
    if (!this.props.imageAction) return;

    this.props.imageAction('image', this.state.chosenImage);

    if (this.props.closeModal) {
      this.props.closeModal();
    }
  };

  render() {

    const { assets, images, mediaType, videos, audios, chosenImage, itemsRef, uploadStatus } = this.state;

    return (
      <div className='GalleryTab'>

        <div className={'Gallery-Upload' + (assets.loading ? ' disabled' : '')}>
          <div className='mr-5'>
            <strong className='mr-3'>{LANG.UploadImage}:</strong>
            <input className="fileInput"
                   type="file"
                   onChange={(e) => this.handleFileInput(e)}/>
          </div>

          <Loading show={uploadStatus.loading} text={LANG.Uploading} size='sm'/>
        </div>

        <div className='Gallery-Items' ref={itemsRef}>

          {!assets.loading && (mediaType === MEDIA_TYPE.ALL || mediaType === MEDIA_TYPE.IMAGE) &&
          <div className='mb-4'>
            <h3>Images</h3>
            {images.length > 0 && <Row>
              {images.map((asset, key) =>
                <GalleryItem key={key} asset={asset} chosenImage={chosenImage} toggleImage={this.toggleImage}/>
              )}
            </Row>}
            {images.length === 0 && <Alert variant='success'>{LANG.EmptyGalleryLabel}</Alert>}
          </div>}

          {!assets.loading && (mediaType === MEDIA_TYPE.ALL || mediaType === MEDIA_TYPE.VIDEO) && <div className='mb-4'>
            <h3>Videos</h3>
            {videos.length > 0 && <Row>
              {videos.map((asset, key) =>
                <GalleryItem key={key} asset={asset} chosenImage={chosenImage} toggleImage={this.toggleImage}/>
              )}
            </Row>}
            {videos.length === 0 && <Alert variant='success'>{LANG.EmptyGalleryLabel}</Alert>}
          </div>}

          {!assets.loading && (mediaType === MEDIA_TYPE.ALL || mediaType === MEDIA_TYPE.AUDIO) && <div>
            <h3>Audios</h3>
            {audios.length > 0 && <Row>
              {audios.map((asset, key) =>
                <GalleryItem key={key} asset={asset} chosenImage={chosenImage} toggleImage={this.toggleImage}/>
              )}
            </Row>}
            {audios.length === 0 && <Alert variant='success'>{LANG.EmptyGalleryLabel}</Alert>}
          </div>}

          <Loading show={assets.loading} size='sm'/>
        </div>

        <div className='Gallery-Footer'>
          {chosenImage && <>
            <DeleteButton buttonText='Smazat'
                          message={`Smazat ${chosenImage.name}?`}
                          action={this.handleDelete}/>

            {this.props.imageAction && <SubmitButton action={this.handleImageAction} loading={uploadStatus.loading} label={LANG.SetImage}/>}
          </>}
        </div>

        <FloatingAlert type='error' message={uploadStatus.error} show={uploadStatus.error}
                       hideAlert={() => this.setState({ uploadStatus: {} })}/>
        <FloatingAlert type='success' message={LANG.UploadSuccess} show={uploadStatus.success}
                       hideAlert={() => this.setState({ uploadStatus: {} })}/>
        <FloatingAlert type='error' message={assets.error} show={assets.error}
                       hideAlert={() => this.setState({ assets: [] })}/>
      </div>
    );
  }
}

export { Gallery };
