import React from 'react';
import {UserService} from "../../services/user.service";
import {UserList} from "./parts/UserList";
import {LANG} from "../../config/config";
import {TitleWithButton} from "../../components/TitleWithButton";
import {Loading} from "../../components/Loading";
import {FloatingAlert} from "../../components/Alert";
import {User} from "../../classes/User";
import {Invitation} from "../../classes/Invitation";
import {AddUsersButton} from "./parts/AddUsersButton";

class UsersPage extends React.Component {
    constructor(props) {
        super(props);

        this.UserService = new UserService();

        this.state = {
            users: [],
            invitations: []
        };
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {

        this.setState({
            users: { loading: true }
        });

        this.UserService.getAll()
            .then(res => {
                const users = res.members.map(user => new User(user));
                const invitations = res.invitations.map(inv => new Invitation(inv));
                this.setState({
                    invitations: invitations,
                    users: users
                });
            })
            .catch(() => {
                this.setState({
                    users: {
                        error: true,
                        loading: false
                    }
                });
            });
    };

    render() {
        const { users, invitations } = this.state;

        return (
            <section className='Users'>

                <TitleWithButton title={LANG.UsersList}
                                 button={<AddUsersButton update={this.loadData}/>}/>

                {users.length && <UserList users={users} invitations={invitations} update={this.loadData}/>}

                <Loading show={users.loading}/>
                <FloatingAlert type='error' message={LANG.ErrorMessage} show={users.error} hideAlert={() => this.setState({users: []})}/>
            </section>
        );
    }
}

export {UsersPage};
