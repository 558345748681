export const EN = {

    OtherLanguage: 'Čeština',

    FirstMenuTitle: 'Users',
    SecondMenuTitle: 'Content',

    ApiKeys: 'API keys',

    // Homepage
    Welcome: 'Welcome to EduARd',
    WelcomeTitle: 'Educational system EduARd',
    WelcomeDesc: 'The system should be used by primary and secondary schools to support teaching mainly in science, but also in humanities such as history and civics.',

    // Users
    Users: 'Users',
    UsersAdministration: 'Users',
    UsersList: 'Users',
    UsersAdd: 'New user',
    NoUsersInInstitution: 'Your institution has no users yet.',
    AddUsersByInvitation: 'Add a user to send them an invitation.',
    ActiveUsers: 'Active users',
    Invitations: 'Invitations',
    FilterLabel: 'Filters role',
    FilterEditors: 'teachers',
    FilterUsers: 'students',
    FilterAll: 'all',
    CancelInvitation: 'cancel invitation ',
    DeleteUser: 'delete user ',
    SendInvitations: 'Send invitations',
    AddUsers: 'Add user',
    EmailAddresses: 'E-mail addresses',
    EmailAddressesLead: 'Type comma separeted values, like',
    EmailAddressesExample: 'student1@instituce.cz, student2@instituce.cz',
    roles: {
        Editor: 'Teacher',
        User: 'Student',
        InstitutionAdmin: 'Institution admin',
        Sysadmin: 'Sysadmin'
    },

    // Books
    Books: 'Books',
    BooksAdministration: 'Books',
    BooksList: 'Books',
    BooksAdd: 'New book',
    ScenariosLinkLabel: '3D scenes',
    ScenarioLabel: '3D scene',
    AddScenario: 'Add any',
    Choose2D: ' or choose 2D content.',
    LayoutMap: 'only map',
    LayoutMapList: 'map and list',
    LayoutList: 'only list',
    NoBooksInInstutition: 'Continue by adding a new book',
    NoBooksFound: 'Nothing was found',
    ResetSearching: 'Reset search',
    SearchPlaceholder: 'Search in books...',
    EditTasks: 'Edit tasks',
    EditIntros: 'Edit intro screens',
    OpenGallery: 'Gallery',
    BasicInformation: 'Main information',
    TasksLayout: 'Tasks layout',
    DeleteBook: 'Delete',
    EditBook: 'Edit',
    NoScenesInInstitution: 'Your institution does not have any 3D scenes yet.',
    NewBook: 'New book',
    AddNewBook: 'Add new book',

    // Tasks
    Tasks: 'Tasks',
    Task: 'Task',
    Location: 'Location',
    DeleteTask: 'Delete task',
    AddTask: 'Add task',
    AddAnotherTask: 'Add another task',
    ChangeOrder: 'change tasks order',
    SaveOrder: 'save order',
    NoTasksInBook: 'Continue by adding a new task',

    Longitude: 'Longitude',
    Latitude: 'Latitude',
    ChooseTaskLocation: 'Choose task location',
    ShowOthersOnMap: 'Show other tasks',
    MapMarkerLead: 'Drag the marker to specify the location of the task. Click on the marker to select a task.',
    MapSuggestInputPlaceholder: 'Start typing the name of a city or point of interest...',
    MapZoom1: 'World',
    MapZoom2: 'Country',
    MapZoom3: 'County',
    MapZoom4: 'City',
    MapZoom5: 'Township',
    MapZoom6: 'Street',

    // Slides
    Slide: 'Screen',
    AddSlide: 'Add screen',
    DeleteSlide: 'Remove screen',
    ContentType: 'Content type',
    Content: 'content',
    AddAnotherSlide: 'Add another screen',
    IntroSlides: 'Intro screens',
    NoSlidesInTask: 'Continue by adding a new screen',

    DescriptionType: {
        TEXT: 'Obyčejný',
        HEADER: 'Header',
        INFO: 'V rámečku',
        HIDDEN: 'Sbalovácí',
        MODAL: 'Modální okno'
    },

    ImageType: {
        DEFAULT: 'Normální',
        PANORAMA: 'Panorama',
        FULLSCREEN: 'Celá obrázovka',
        BLIND: 'Srovnání dvou obrázků'
    },

    QuestionType: {
        FILLTEXT: 'Textová odpověď',
        NUMBERQUESTION: 'Číselná odpověď',
        SINGLECHOICE: 'Jedna správná odpověď',
        MULTICHOICE: 'Více správných odpovědí',
        SORT: 'Seřazovací',
        INTERVALQUESTION: 'Interval',
        TOGGLEBUTTONSGRID: 'Přepínácí',
        DRAGTOLINE: 'Přiřazení popisku k popisku',
        DRAGTOMIDDLE: 'Přiřazení popisku k obrázku'
    },

    // Questions
    DeleteQuestion: 'delete question',
    Questions: 'Questions',
    QuestionFillText: 'Text answer',
    QuestionNumber: 'Numeric answer',
    QuestionSingle: 'Single choice',
    QuestionMulti: 'Multi choice',
    QuestionSort: 'Sorting',
    QuestionInterval: 'Interval',
    QuestionToggle: 'Toggle',
    NoQuestionsInSlide: 'Continue by adding content',
    QuestionText: 'Question text',
    ShuffleAnswers: 'Shuffle answers',
    ValidateAnswers: 'validate',
    PossibleAnswers: 'Possible answers',
    RightAnswer: 'Correct answer',
    RightAnswers: 'Correct answers',
    AnswerText: 'Answer text',
    AddAnswer: 'add an answer',
    CheckRightAnswer: 'Check the correct answer',
    CaseSensitiveAnswer: 'Answer is case-sensitive',
    IntervalStep: 'Interval step',
    IntervalValid: 'Correct interval',
    IntervalInvalid: 'Interval range',
    From: 'From',
    To: 'To',
    Min: 'Min',
    Max: 'Max',
    AnswersSorted: 'Correctly sorted answers',
    AvailableOptions: 'Available options',
    OptionText: 'option text',
    AddOption: 'add an option',
    StatementsList: 'Statements',
    StatementsLead: 'Match correct options to the statements',
    StatementText: 'statement text',
    AddStatement: 'add a statement',
    AddPair: 'add more',
    QuestionDragToLine: 'Label to label assignment',
    QuestionDragToMiddle: 'Label to picture assignment',
    DragToTitle: 'Correct assignment',

    // Images and Gallery
    Gallery: 'Gallery',
    GallerySubLabel: 'images in the book',
    GalleryNote: 'Select one of the available images',
    ChooseImage: 'Select image',
    EmptyGalleryLabel: 'Continue by uploading an image',
    UploadImage: 'Image upload',
    SetImage: 'Set image',
    Image: 'Image',
    ChooseFromGallery: 'Choose from gallery',
    RemoveImageFromSlide: 'Remove from the screen',
    ImagePlacement: 'Tablet layout',
    ImagePlacementUp: 'Images above text',
    ImagePlacementDown: 'Images below text',
    ImagePlacementLeft: 'Images left to the text',
    ImagePlacementRight: 'Images right to the text',

    // LoginPage
    Username: 'Username',
    Password: 'Password',
    Login: 'Login',
    LoginToEduard: 'Login to EduARd',
    newPassword: 'New password',
    newPasswordConfirm: 'Password confirmation',

    // Common
    Logout: 'Logout',
    Email: 'E-mail',
    Roles: 'Roles',
    Actions: 'Actions',
    GoBack: 'Cancel',
    Save: 'Save',
    Yes: 'Yes',
    No: 'No',
    Maybe: 'Maybe',
    Cancel: 'cancel',
    Delete: 'delete',
    Add: 'add',
    Loading: 'Loading',
    Uploading: 'Uploading',
    ItemsPerPage: 'Items on page',
    Institution: 'Institution',
    OtherActions: 'Other actions',
    Name: 'Name',
    Description: 'Description',
    NumberOfAttempts: 'Attempts',
    NumberOfTasks: 'Tasks',
    Change: 'Change',
    Close: 'Close',
    Preview: 'Preview',
    Here: 'here',
    Hide: 'hide',
    ChooseItemFromList: 'Choose item from the list.',
    ChangeOrderLead: 'Move an item to change its order.',

    // Confirmation modals
    AreYouSure: 'Are you sure to ',

    // Loadings
    LoadingUsers: 'Loading users.',
    Saving: 'Saving',

    // Validation Error
    ErrorMessage: 'Something went wrong',
    NoEmptyListOfEmails: 'List of emails cannot be blank',
    EmailsNotValid: 'List of emails has a syntax error',
    InvitationsSendError: 'Invitations were not sent:',
    LogoutMessage: 'You have been logged out.',
    XMLLoadingError: 'Error loading XML',
    TaskNotFound: 'Task is not found',
    BookNotFound: 'Book is not found',
    SaveError: 'Save failed.',
    InvalidUsernameOrPassword: 'Invalid username or password',
    EmptyPasswordError: 'Password is required',
    EmptyUsernameError: 'Username is required',
    TaskLoadingError: 'Task was not loaded',
    NoEmptyName: 'Name is required',

    // Validation Success
    InvitationsSendSuccess: 'Invitations sent successfully.',
    UploadSuccess: 'Image uploaded successfully',
    SaveSuccess: 'Successfully saved',
    BookAddSuccess: 'Book successfully added.',
    BookAddRedirect: 'You will be redirected to edit page.',

    // Navigation
    PromptMessage: 'Leave page without saving?'
};
