import { QUESTION_LAYOUT } from '../config/constants/layout';
import { Asset } from './Asset';

export class Slide {
  title;
  desc;
  layout;
  image;
  is3d;
  scenarioId;

  contents;

  // connection to parent task
  task;

  //DS: pro zobrazeni cisla slidu
  innerID;

  constructor(title, layout, image, is3d, scenarioId = '', task = null, contents = [], innerID = 0) {
    this.title = title;
    this.layout = layout;
    this.image = image;
    this.is3d = is3d;
    this.scenarioId = scenarioId;
    this.task = task;
    this.contents = contents;
    this.innerID = innerID;
  }
}

export class EmptySlide extends Slide {
  constructor(task = null) {

    const title = 'Obrazovka',
      layout = QUESTION_LAYOUT.IMG_UP,
      image = new Asset(),
      is3d = false,
      scenarioId = null;

    super(title, layout, image, is3d, scenarioId, task);
  }
}


