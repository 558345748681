import React, { Component, createRef } from 'react';
import { SortList } from '../../components/Sortable/SortList';
import { Editor } from '../../components/Editor';
import { TaskDetail } from './Task/TaskDetail';
import { EmptyTask, Task } from '../../classes/Task';
import { EmptySlide, Slide } from '../../classes/Slide';
import { SlideDetail } from './Slide/SlideDetail';
import { BOOK_LAYOUT } from '../../config/constants/layout';
import { bookLanguage } from '../../classes/Book';
import { BookNavigation } from './BookNavigation';
import * as ReactDOM from 'react-dom';

class TasksTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      book: props.book,
      tasks: [],
      active: null,
      scrollableListRef: createRef(),
      showSlideContentsFirst: true
    };
  }

  componentDidMount() {
    this.updateTaskList();
  }

  componentWillUnmount() {
    const { book } = this.state;

    book.tasks.map(task => task.showChildren = false);
  }

  updateTaskList = () => {
    const { book, filteredLanguage } = this.state;
    let filteredTasks = book.tasks.length ? book.tasks.filter(task => task.name !== 'intro') : [];

    if (filteredLanguage !== bookLanguage.BOTH) {
      filteredTasks = filteredTasks.filter(t => t.lang === filteredLanguage);
    }

    this.setState({
      tasks: filteredTasks
    });
  };

  changeActive = (value, isNewlyAdded = false) => {
    if (value instanceof Task) {
      // show my children
      if (value.slides.length > 0) {
        value.showChildren = true;
      }
    }

    this.setState({
      active: value,
      showSlideContentsFirst: !isNewlyAdded
    });
  };

  add = () => {

    let { tasks } = this.state;

    const task = new EmptyTask(`task${tasks.length}`);
    task.title = 'Nový úkol';
    task.ref = createRef();

    const book = this.state.book;
    book.tasks = [...book.tasks, task];

    this.setState({
      book: book
    });

    this.updateTaskList();
    this.changeActive(task);
    setTimeout(() => this.scrollListToBottom(task), 100);
  };

  remove = (task) => {
    const book = this.state.book;
    let index = book.tasks.indexOf(task);
    if (index !== -1) book.tasks.splice(index, 1);

    this.setState({
      book: book,
      active: null
    });

    this.updateTaskList();
  };

  removeSlide = (slide) => {
    const task = slide.task;
    let index = task.slides.indexOf(slide);
    if (index !== -1) task.slides.splice(index, 1);

    this.setState({
      active: null
    });
  };

  addSlide = (parentTask) => {

    const { tasks } = this.state;
    const idx = tasks.indexOf(parentTask);

    const slide = new EmptySlide(parentTask);

    let number = parentTask.slides.length + 1;

    slide.title = slide.title + ' ' + number;
    slide.ref = createRef();

    parentTask.slides = [...parentTask.slides, slide];
    parentTask.showChildren = true;
    tasks[idx] = parentTask;

    this.updateTaskList();
    this.changeActive(slide, true);
    setTimeout(() => this.scrollListToBottom(slide), 100);
  };

  scrollListToBottom = (item) => {
    const list = this.state.scrollableListRef;
    if (list && list.current && item && item.ref) {
      const listElement = list.current;
      const itemElement = ReactDOM.findDOMNode(item.ref.current);

      const isSeen = listElement.scrollTop + listElement.offsetHeight > itemElement.offsetTop + itemElement.offsetHeight;
      if (itemElement && !isSeen) {
        listElement.scrollTop = itemElement.offsetTop;
      }
    }
  };

  render() {

    const { book, tasks, active, scrollableListRef, showSlideContentsFirst } = this.state;

    let $detailTemplate = null;
    if (active instanceof Task) {
      $detailTemplate = <TaskDetail task={active}
                                    tasks={tasks}
                                    showMap={book.layout !== BOOK_LAYOUT.LIST.value}
                                    setLocationOK={this.props.setLocationOK}
                                    changeActive={this.changeActive}
                                    remove={this.remove}
                                    addSlide={this.addSlide}/>;
    } else if (active instanceof Slide) {
      $detailTemplate = <SlideDetail slide={active}
                                     updateSlide={this.updateSlide}
                                     removeSlide={this.removeSlide}
                                     addSlide={this.addSlide}
                                     addTask={this.add}
                                     isIntro={false}
                                     showSlideContentsFirst={showSlideContentsFirst}
                                     book={book}/>;
    }

    const $rightSide = <>
      <BookNavigation activeItem={active}
                      book={book}
                      addAction={active instanceof Slide ? () => this.addSlide(active.task) : this.add}
                      removeActon={active instanceof Slide ? () => this.removeSlide(active) : () => this.remove(active)}/>
      {$detailTemplate}
    </>;

    return (
      <div className='TasksTab'>
        <Editor list={<SortList items={tasks}
                                scrollableListRef={scrollableListRef}
                                activeItem={active}
                                changeActive={this.changeActive}/>}
                detail={$rightSide}/>
      </div>
    );
  }
}

export { TasksTab };
