import React, { Component } from 'react';
import { Navigation } from '../../components/Navigation';

class DefaultLayout extends Component {
  render() {
    const { component: Component, isAdmin, isEditor, toggleLang, ...rest } = this.props;
    return (
      <>
        <Navigation isAdmin={isAdmin}
                    isEditor={isEditor}
                    toggleLang={toggleLang}/>
        <Component {...rest}
                   isAdmin={isAdmin}
                   isEditor={isEditor}/>
      </>
        )
    }
}

export {DefaultLayout}
