import React from 'react';

import {LANG} from "../../config/config";
import {AuthService} from "../../services/auth.service";
import {SubmitButton} from "../../components/Buttons/SubmitButton";
import {FloatingAlert} from "../../components/Alert";
import {Link} from "react-router-dom";

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        this.Auth = new AuthService();

        this.state = {
            username: '',
            password: '',
            submitted: false,
            loading: false,
            error: ''
        };
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value.replace(/\s/g, '') });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        this.setState({ error: false, submitted: true });
        const { username, password } = this.state;

        if (!(username && password)) {
            return;
        }

        this.setState({ loading: true });

        this.Auth.login(username, password)
            .then((res) => {
                console.log('login successful, changing location, res:', res);
                this.props.history.push('/');
            })
            .catch(error => {
                // console.log(error);
                let errorText = LANG.ErrorMessage;
                if (error.response && error.response.status === 401) errorText = LANG.InvalidUsernameOrPassword
                else if (error.message) errorText = error.message

                this.setState({ error: errorText, loading: false })
            });
    };

    render() {
        const { username, password, submitted, loading, error } = this.state;
        return (
            <div className="loginPage">
                <h1 className="text-center mb-4">EduARd</h1>
                <form name="form" onSubmit={this.handleSubmit}>
                    <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                        <label htmlFor="username">{LANG.Username}</label>
                        <input type="text" className="form-control" name="username" value={username}
                               onChange={this.handleChange}/>
                        {submitted && !username && <div className="alert alert-warning mt-2">{LANG.EmptyUsernameError}</div>}
                    </div>
                    <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                        <label htmlFor="password">{LANG.Password}</label>
                        <input type="password" className="form-control" name="password" value={password}
                               onChange={this.handleChange}/>
                        {submitted && !password && <div className="alert alert-warning mt-2">{LANG.EmptyPasswordError}</div>}
                    </div>
                    <SubmitButton loading={loading} label={LANG.Login}/>
                </form>

                <div className="loginPage-resetLink">
                    <Link to="/reset-request">Zapomenuté heslo</Link>
                </div>

                <FloatingAlert type='error' message={error} show={error}  hideAlert={() => this.setState({error: ''})}/>
            </div>
        );
    }
}

export {LoginPage};
