import React, { Component } from 'react';
import { AuthService } from '../../services/auth.service';
import { CONFIG } from '../../config/config';
import { Loading } from '../../components/Loading';

class ScenesPage extends Component {

  constructor(props) {
    super(props);
    this.ifr = React.createRef();
    this.Auth = new AuthService();

    this.state = {
      loading: true
    };
  }

  componentDidMount() {

    const { ifr } = this;

    console.log(ifr);

    ifr.onload = () => {

      ifr.contentWindow.postMessage({
        idToken: this.Auth.getToken()
      }, '*');

      this.setState({
        loading: false
      });
    };

  }

  render() {

    const { loading } = this.state;
    return (<section className="p-0">
      {loading && <div className="p-5"><Loading show={loading}/></div>}

      <iframe src={CONFIG.scenariosUrl}
              width="100%"
              height="100%"
              id="sceneEditor"
              title="sceneEditor"
              ref={(f) => this.ifr = f}
              style={{ display: loading ? 'none' : 'block' }}/>
    </section>);
  }
}

export { ScenesPage };
