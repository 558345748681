import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import React from 'react';
import { bookLanguage } from '../../../classes/Book';

export function BookFilter({ activeLang, updateAction }) {
  return (
    <div className="BookFilter">
      <span>Filtrovat podle jazyku:</span>
      <ButtonGroup>
        {Object.keys(bookLanguage).map((key, index) => (
          <Button key={index}
                  variant="outline-primary"
                  onClick={() => updateAction(key)}
                  className={key === activeLang ? 'active' : ''}>
            {bookLanguage[key]}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
}
