import React, { Component } from 'react';
import { TitleWithButton } from '../../components/TitleWithButton';
import { LANG } from '../../config/config';
import { BookService } from '../../services/book.service';
import { Loading } from '../../components/Loading';
import { AddBookButton } from './parts/AddBookButton';
import { Pagination } from './parts/Pagination';
import { BookItem } from './BookItem';
import { BookFilter } from './parts/BookFilter';

class BooksList extends Component {

    constructor(props) {
        super(props);

        this.BookService = new BookService();

        this.state = {
            title: LANG.BooksList,
            books: [],
            filteredBooks: [],
            booksOnPage: [],
            searchText: '',
            activeLang: 'both',

            // pagination
            pageCount: 0,
            booksPerPage: 10,
            offset: 0
        };
    }

    componentDidMount() {
        this.loadBooks();
    }

    async loadBooks() {
        this.setState({
            books: { loading: true }
        });

        const books = await this.BookService.getAll();
        console.log('Loaded books', books);
        this.setState({
            books: books,
            filteredBooks: books
        }, this.setBooksOnPage);
    };

    setBooksOnPage = () => {
        const { filteredBooks, offset, booksPerPage } = this.state;
        this.setState({
            booksOnPage: filteredBooks.slice(offset, offset + booksPerPage),
            pageCount: Math.ceil(filteredBooks.length / booksPerPage)
        });
    };

    // fulltext
    setFilteredBooks = (filteredBooks, searchText) => {
        this.setState({
            filteredBooks: filteredBooks,
            searchText: searchText
        }, this.setBooksOnPage);
    };

    clearSearchText = () => {
        this.setState(prevState => ({
            filteredBooks: prevState.books,
            searchText: ''
        }), this.setBooksOnPage);
    };

    // pagination
    handlePageClick = data => {
        let selected = data.selected;
        let offset = Math.ceil(selected * this.state.booksPerPage);

        this.setState({ offset: offset }, this.setBooksOnPage);
    };
    setBooksPerPage = num => {
        let value = parseInt(num);
        this.setState({
            booksPerPage: value,
            offset: 0
        }, () => {
            if (value > 0) this.setBooksOnPage();
        });
    };

    handleDelete = (id) => {
        this.BookService.delete(id)
        .then(() => this.loadBooks())
        .catch((err) => console.log(err));
    };

    filterBooksByLanguage = (lang) => {
        const books = this.state.books;
        const filteredBooks = lang === 'both' ? [...books] : books.filter(b => b.language === lang);

        this.setState({
            offset: 0,
            activeLang: lang,
            filteredBooks: filteredBooks
        }, () => this.setBooksOnPage());
    };

    render() {

        const { books, filteredBooks, booksOnPage, offset, searchText, activeLang, pageCount } = this.state;

        return (
          <section>
              <div className="BooksList-Header">
                  <TitleWithButton title={LANG.BooksList + (!books.loading ? ` (${books.length})` : '')} button={<AddBookButton/>}/>
                  {books.length > 0 && <BookFilter activeLang={activeLang} updateAction={this.filterBooksByLanguage}/>}
                  {/*{books.length > 0 && <BookSearch books={books}*/}
                  {/*                                 setFilteredBooks={this.setFilteredBooks}*/}
                  {/*                                 searchText={searchText}*/}
                  {/*                                 clearSearchText={this.clearSearchText}/>}*/}
              </div>

              {books.length === 0 && <div className='alert alert-success my-3'>{LANG.NoBooksInInstutition}</div>}

              {books.length > 0 && booksOnPage.length === 0 && <div className='d-flex my-5 w-100'>
                  <div className='alert alert-warning d-flex align-items-center mb-0 mr-3 flex-1 w-100'>
                      {LANG.NoBooksFound}
                      <span className="mx-2" role="img" aria-hidden={true} style={{ fontSize: '22px' }}>😢</span>
                  </div>
              </div>}

              {booksOnPage.length > 0 && <div className="BooksList">
                  {booksOnPage.map((book, key) => <BookItem key={key}
                                                            book={book}
                                                            deleteAction={() => this.handleDelete(book.id)}/>)}
              </div>
              }

              {books.length > 0 && <Pagination handlePageClick={this.handlePageClick}
                                               pageCount={pageCount}
                                               booksCount={books.length}
                                               booksOnPageCount={booksOnPage.length}
                                               booksPerPage={this.state.booksPerPage}
                                               setBooksPerPage={this.setBooksPerPage}/>}

              {books.loading && <Loading show={books.loading}/>}
          </section>
        );
    }


}

export { BooksList };
