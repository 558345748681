import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LANG } from '../../../config/config';

const latRegex = new RegExp('^[-+]?([1-8]?\\d(\\.\\d+)?|90(\\.0+)?)$');
const lngRegex = new RegExp('^[-+]?(180(\\.0+)?|((1[0-7]\\d)|([1-9]?\\d))(\\.\\d+)?)$');

class TaskMap extends Component {
  constructor(props) {
    super(props);

    this.map = null;
    this.markerLayer = null;

    const task = props.task;

    this.state = {
      wasMarkerDragged: false,
      otherMarkers: [],
      task: task,
      lat: task && task.latitude ? task.latitude : '',
      lng: task && task.longitude ? task.longitude : '',
      errors: {
        lat: false,
        lng: false
      }
    };
  }

  static getDerivedStateFromProps(props, state) {
    // console.log(props.task, state.task)
    if (state.task !== props.task) {
      const task = props.task;
      return {
        task: task,
        lat: task && task.latitude ? task.latitude : '',
        lng: task && task.longitude ? task.longitude : ''
      };
    }
    return null;
  }

  componentDidMount() {
    this.checkLocation();

    if (!window.Loader) {
      this.setMapLoader();
    } else {
      this.loadMap();
    }
  }

  componentWillUnmount() {
    this.props.setLocationOK(true);
  }

  checkLocation = () => {
    const { lat, lng } = this.state;

    const latOK = lat.length && latRegex.test(lat);
    const lngOK = lng.length && lngRegex.test(lng);

    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        lat: !latOK,
        lng: !lngOK
      }
    }));

    const locationOK = latOK && lngOK;
    this.props.setLocationOK(locationOK);

    return locationOK;
  };

  setMapLoader = (callCount = 1, limit = 1000) => {
    try {
      if (!window.Loader && callCount <= limit) {
        this.setMapLoader(callCount + 1, limit);
        return;
      }

      window.Loader.async = true;
      this.loadMap();

      return null;
    } catch (exception) {
      console.error(exception);
    }
  };

  loadMap = () => {
    window.Loader.load(null, { suggest: true, poi: true }, () => {
      this.createMap();
      this.updateMap();
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.map) {
      return;
    }

    const newTask = this.props.task;
    const currentTask = prevProps.task;
    if (newTask !== currentTask ||
      newTask.latitude !== currentTask.latitude ||
      newTask.longitude !== currentTask.longitude) {
      this.checkLocation();
      this.updateMap();
    }
  }

  updateMap(showOtherTasks = true) {
    this.addMarkerToMap();
    if (showOtherTasks) {
      this.showOtherMarkers();
    }
  }

  showOtherMarkers = () => {

    const { task, tasks, changeActiveTask } = this.props;
    const otherTasks = this._getOtherTasks(task.longitude, task.latitude);
    let otherMarkers = [];
    let centers = [this.map.getCenter()];

    if (otherTasks.length) {

      otherTasks.forEach(task => {
        if (task.latitude === '' || task.longitude === '') return;

        const taskIdx = tasks.indexOf(task);
        const center = window.SMap.Coords.fromWGS84(task.longitude, task.latitude);
        const parameters = {
          center: center,
          title: taskIdx,
          showAll: true,
          anchor: { left: 10, bottom: 1 }
        };

        const newMarker = this.addMarkerToMap(parameters);

        centers = [...centers, center];
        otherMarkers = [...otherMarkers, newMarker];
      });

      if (centers.length === 1) return;

      /* Spočítat pozici mapy tak, aby značky byly vidět */
      let cz = this.map.computeCenterZoom(centers);
      this.map.setCenterZoom(cz[0], cz[1]);

      // console.log('otherMarkers outside', otherMarkers)
      this.map.getSignals().addListener(this.map, 'marker-click', function (e) {
        // vybrany marker
        var marker = e.target;
        var id = marker.getId();
        // console.log('clicked id', id)

        // console.log('otherMarkers inside', otherMarkers);
        const filtered = otherMarkers.length ? otherMarkers.filter(m => !!m && m._id === id) : [];

        if (filtered.length) {
          const taskIdx = filtered[0]._options.title;
          const foundTask = tasks[taskIdx];

          if (!foundTask) return;

          // console.log('clicked', foundTask);
          changeActiveTask(foundTask);
        }
      });
    }
  };

  addMarkerToMap = (parameters = {}) => {

    const task = this.props.task;
    const lng = task.longitude;
    const lat = task.latitude;

    if (!lng.length || !lat.length) {
      return;
    }

    let { center, title, showAll } = parameters;

    if (!center) {
      center = window.SMap.Coords.fromWGS84(lng, lat);
    }

    // console.log('adding marker with center', center);

    // set marker on center
    var options = {
      url: showAll ? `/marker-gray.png` : '/marker-red.png',
      title: title,
      anchor: { left: 10, bottom: 1 }
    };
    let marker = new window.SMap.Marker(center, null, options);

    // pokud jsou jine markery a chceme je skryt
    if (this.markerLayer && this.markerLayer.getMarkers().length && !showAll) {
      this.markerLayer.removeAll();
      if (!this.props.showOthers) this.map.setZoom(15);
    }

    this.markerLayer.addMarker(marker);
    this.map.setCenter(center);

    return marker;
  };

  createMap = () => {

    // if(!this.state.task || this.state.task.latitude === '' || this.state.task.longitude === '') {
    //     return
    // }

    // console.log('createMap', this.state.task.latitude, this.state.task.longitude)

    // marker layer
    this.markerLayer = new window.SMap.Layer.Marker();

    // create map
    this.map = new window.SMap(window.JAK.gel('mapContainer'), null, 15);

    // default layer
    this.map.addDefaultLayer(window.SMap.DEF_BASE).enable();

    // controls
    const mouse = new window.SMap.Control.Mouse(window.SMap.MOUSE_PAN);
    this.map.addControl(mouse);
    this.map.addControl(new window.SMap.Control.Sync());
    this.map.addControl(new window.SMap.Control.Compass());
    this.map.addControl(new window.SMap.Control.Zoom({
      2: LANG.MapZoom1,
      5: LANG.MapZoom2,
      8: LANG.MapZoom3,
      11: LANG.MapZoom4,
      14: LANG.MapZoom5,
      17: LANG.MapZoom6
    }));
    this.map.addControl(new window.SMap.Control.ZoomNotification());

    // context menu
    this.map.addDefaultContextMenu();

    this.map.addLayer(this.markerLayer).enable();

    // poi layer
    const poiLayer = new window.SMap.Layer.Marker(undefined, { poiTooltip: true });
    this.map.addLayer(poiLayer).enable();

    /* dataProvider zastiti komunikaci se servery */
    var dataProvider = this.map.createDefaultDataProvider();
    dataProvider.setOwner(this.map);
    dataProvider.addLayer(poiLayer);
    dataProvider.setMapSet(window.SMap.MAPSET_BASE);
    dataProvider.enable();

    // marker drag
    let signals = this.map.getSignals();
    signals.addListener(window, 'marker-drag-stop', this.markerDragStop);
    signals.addListener(window, 'marker-drag-start', this.markerDragStart);

    // this.addMarkerToMap();
    // this.showOtherMarkers();

    console.log('map created');
  };

  handleSearchInput = (input) => {
    // console.log('search input', input.value);
    this.props.updateTask('place', input.value);

    let suggest = new window.SMap.Suggest(input, {
      provider: new window.SMap.SuggestProvider({
        updateParams: params => {
          /*
            tato fce se vola pred kazdym zavolanim naseptavace,
          params je objekt, staci prepsat/pridat klic a ten se prida
          do url
        */
          let c = this.map.getCenter().toWGS84();
          params.lon = c[0].toFixed(5);
          params.lat = c[1].toFixed(5);
          params.zoom = this.map.getZoom();
          // povolime kategorie
          params.enableCategories = 1;
          // priorita jazyku, oddelene carkou
          params.lang = 'cs,en';
        }
      })
    });
    suggest.addListener('suggest', suggestData => {
      // vyber polozky z naseptavace
      // console.log(suggestData)

      const { longitude, latitude } = suggestData.data;

      this.props.updateTaskLocation(longitude, latitude);
      this.props.updateTask('place', suggestData.phrase);

      this.setState({
        lat: latitude,
        lng: longitude,
        errors: {}
      });

      this.addMarkerToMap();
      this.showOtherMarkers();
    });
  };

  markerDragStart = (e) => {
    let node = e.target.getContainer();
    node[window.SMap.LAYER_MARKER].style.cursor = 'pointer';
  };

  markerDragStop = (e) => {
    this.setState({
      wasMarkerDragged: true
    });

    let coords = e.target.getCoords();
    this.props.updateTaskLocation(coords.x, coords.y);
    this.props.updateTask('place', '');
  };

  _getOtherTasks = (lng, lat) => {
    return this.props.tasks.filter(t => t.longitude !== lng || t.latitude !== lat);
  };

  async handleLocationChange(field, e) {
    const input = e.currentTarget.value;

    if (field === 'latitude') {
      await this.setState(prevState => ({
        lat: input
      }));
    } else {
      await this.setState(prevState => ({
        lng: input
      }));
    }

    const locationOK = this.checkLocation();

    if (locationOK) {
      this.props.updateTask(field, input);
      this.addMarkerToMap();
      this.showOtherMarkers();
    }
  }

  render() {

    const { task, lat, lng, errors } = this.state;
    const otherTasks = task ? this._getOtherTasks(task.longitude, task.latitude) : [];

    return (
      <div className={'TaskMap'}>
        <Row>
          <Col lg={5}>

            <Form.Group>
              <Form.Label>
                {LANG.Latitude}
              </Form.Label>
              <Form.Control type="text"
                            value={lat}
                            isInvalid={errors.lat}
                            placeholder="například: 50.123456789"
                            onChange={(e) => this.handleLocationChange('latitude', e)}/>
            </Form.Group>

            <Form.Group>
              <Form.Label>
                {LANG.Longitude}
              </Form.Label>
              <Form.Control type="text"
                            value={lng}
                            isInvalid={errors.lng}
                            placeholder="například: 14.123456789"
                            onChange={(e) => this.handleLocationChange('longitude', e)}/>
            </Form.Group>

            <Form.Group>
              <Form.Label>
                Vyhledat na mapě
              </Form.Label>
              <Form.Control type="text"
                            value={task && task.place ? task.place : ''}
                            placeholder={LANG.MapSuggestInputPlaceholder}
                            onChange={(e) => this.handleSearchInput(e.target)}/>
            </Form.Group>
          </Col>
          <Col lg={7} style={{ maxHeight: '300px', position: 'relative' }}>
            {task && (task.latitude === '' || task.longitude === '') && <div className='placeholder placeholder-map'>{LANG.ChooseTaskLocation}</div>}
            <div id='mapContainer' className='smap-defaults'/>
          </Col>
        </Row>

      </div>
    );
  }


}

export { TaskMap };
