import { LANG } from '../config';

const QUESTION_LAYOUT = {
    DEFAULT: 'basic',
    IMG_RIGHT: 'h_text_img',
    IMG_LEFT: 'h_img_text',
    IMG_DOWN: 'v_text_img',
    IMG_UP: 'v_img_text',
    VIDEO_DOWN: 'v_text_video',
    PANORAMA: 'panorama',
    FULLSCREEN: 'fullscreen'
};


const BOOK_LAYOUT = {
    MAP: {
        value: 'map',
        label: LANG.LayoutMap
    },
    MAP_LIST: {
        value: 'map_with_list',
        label: LANG.LayoutMapList
    },
    LIST: {
        value: 'list',
        label: LANG.LayoutList
    }
}

export {QUESTION_LAYOUT, BOOK_LAYOUT}
