import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LANG } from '../../config/config';

export const AddNewButton = ({ onClick, type = 'success', text = LANG.Add, icon = 'plus', className = 'ml-4' }) => {

  return (
    <button onClick={onClick} className={`btn btn-` + type + ` btn-sm ${className}`}>
      {text}
      <FontAwesomeIcon icon={icon} className={text.length ? 'ml-3' : ''}/>
    </button>
  );
};
