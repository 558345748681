import React, { Component, createRef } from 'react';
import { SlideDropdown } from './parts/SlideDropdown';
import { QUESTION } from '../../../../../config/constants/question';
import { LANG } from '../../../../../config/config';
import { Question } from '../../../../../classes/Question';
import { Asset, DoubleImage, IMAGE_TYPE } from '../../../../../classes/Asset';
import { Description, DESCRIPTION_TYPE } from '../../../../../classes/Description';
import { SortableList } from './sortable/SortableList';
import arrayMove from 'array-move';
import { ImageService } from '../../../../../services/image.service';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button } from 'react-bootstrap';
import * as ReactDOM from 'react-dom';
import { Video } from '../../../../../classes/Video';
import { Audio } from '../../../../../classes/Audio';

class SlideContents extends Component {

  constructor(props) {
    super(props);

    this.ImageService = new ImageService(this.props.book.id);

    this.state = {
      slide: props.slide,
      questionChoices: [],
      descriptionChoices: [],
      imageChoices: [],
      openedIndex: null,
      loading: false,
      otherSlides: [],
      buttonsOpened: false,
      containerRef: createRef()
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.slide !== props.slide) {
      return { slide: props.slide };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // reload images on slide update
    if (prevState.slide !== this.state.slide) {
      this.loadImages();
      this.initOtherSlides(); //DS: seznam otherSlidu zustaval po prekliknuti stejny, tak jsem ho tu pridal pro update
    }
  }

  componentDidMount() {
    this.initItemRefs();
    this.initOtherSlides();
    this.setChoices();
    this.loadImages();
  }

  initItemRefs = () => {
    const slide = this.state.slide;
    slide.contents.forEach(item => item.ref = React.createRef());
    this.setState({ slide });
  };

  initOtherSlides = () => {
    const slide = this.state.slide;
    this.setState({
      otherSlides: slide.task.slides.filter(s => s !== slide && s.contents.length > 0)
    });
  };

  async loadImages() {
    const slide = this.state.slide;

    if (!slide.contents.length) {
      return;
    }

    this.setState({
      loading: true
    });

    for (let item of slide.contents) {
      if (item instanceof Asset && item.name) {
        const loadedImage = await this.ImageService.getImageByFilename(item.name);
        item.fetchUrl = loadedImage.fetchUrl;
        item.name = loadedImage.name;
      } else if (item instanceof DoubleImage) {
        const images = await Promise.all([
          this.ImageService.getImageByFilename(item.firstImage.name),
          this.ImageService.getImageByFilename(item.secondImage.name)
        ]);
        if (images[0] && images[0].fetchUrl) {
          item.firstImage = images[0];
        }
        if (images[1] && images[1].fetchUrl) {
          item.secondImage = images[1];
        }
      }
    }

    this.setState({
      loading: false
    });
  }

  setChoices = () => {
    this.setState({
      questionChoices: this.constructChoices(QUESTION, 'QuestionType', this.addNewQuestion),
      descriptionChoices: this.constructChoices(DESCRIPTION_TYPE, 'DescriptionType', this.addNewDescription),
      imageChoices: this.constructChoices(IMAGE_TYPE, 'ImageType', this.addNewImage)
    });
  };

  constructChoices = (typesObject, langKey, command) => {
    const choices = [];

    for (const qType of Object.keys(typesObject)) {
      const label = LANG[langKey][qType];
      const action = () => {
        command(qType.toLowerCase());
      };

      choices.push({ label, action });
    }

    return choices;
  };

  addNewVideo = () => {
    const slide = this.state.slide;
    const video = new Video();
    video.ref = createRef();
    slide.contents = [...slide.contents, video];

    this.setState({ slide, buttonsOpened: false });
    this.toggleItem(slide.contents.length - 1);
  };

  addNewAudio = () => {
    const slide = this.state.slide;
    const audio = new Audio();
    audio.ref = createRef();
    slide.contents = [...slide.contents, audio];

    this.setState({ slide, buttonsOpened: false });
    this.toggleItem(slide.contents.length - 1);
  };

  addNewDescription = (type) => {
    console.log('adding new description of type', type);
    const slide = this.state.slide;
    const desc = new Description(type);
    desc.ref = createRef();
    slide.contents = [...slide.contents, desc];

    this.setState({ slide, buttonsOpened: false });
    this.toggleItem(slide.contents.length - 1);
  };

  addNewImage = (type) => {
    console.log('adding new image of type', type);
    const slide = this.state.slide;
    const image = type === IMAGE_TYPE.BLIND ? new DoubleImage() : new Asset(type);
    image.ref = createRef();
    slide.contents = [...slide.contents, image];

    this.setState({ slide, buttonsOpened: false });
    this.toggleItem(slide.contents.length - 1);
  };

  addNewQuestion = (type) => {

    console.log('adding new question of type', type);

    let newQ = new Question();
    newQ.ref = createRef();
    newQ.description = '';
    newQ.type = type;
    newQ.validate = false;
    newQ.caseSensitive = false;
    newQ.shuffle = false;
    newQ.variant = [''];

    switch (type) {
      case QUESTION.MULTICHOICE:
      case QUESTION.SINGLECHOICE:
        newQ.variant = [{ text: '', valid: true }];
        break;
      case QUESTION.SORT:
      case QUESTION.FILLTEXT:
        break;
      case QUESTION.NUMBERQUESTION:
        newQ.variant = 0;
        break;
      case QUESTION.INTERVALQUESTION:
        newQ.variant = 50;
        newQ.interval = [{ valid: false, start: 0, end: 100 }, { valid: true, start: 45, end: 55 }];
        newQ.step = 1;
        break;
      case QUESTION.TOGGLEBUTTONSGRID:
        newQ.options = [LANG.Yes, LANG.No, LANG.Maybe];
        newQ.variant = [{ text: '', option: 0 }];
        break;
      case QUESTION.DRAGTOLINE:
        newQ.options = [''];
        newQ.variant = [{ text: '', option: 0 }];
        break;
      case QUESTION.DRAGTOMIDDLE:
        newQ.options = [new Asset()];
        newQ.variant = [{ text: '', option: 0 }];
        break;
      default:
        return;
    }

    let slide = this.state.slide;
    slide.contents = [...slide.contents, newQ];

    this.setState({ slide, buttonsOpened: false });
    this.toggleItem(slide.contents.length - 1);
  };

  removeItem = (key) => {
    const slide = this.state.slide;
    slide.contents.splice(key, 1);

    this.setState({
      slide,
      openedIndex: null
    });
  };

  updateAction = (index, content) => {
    const slide = this.state.slide;
    const item = slide.contents[index];
    if (!item) {
      return;
    }

    //console.log('Updating to', content)

    if (item instanceof Description) {
      item.content = content;
    } else if (item instanceof Asset || item instanceof Video || item instanceof Audio) {
      item.fetchUrl = content.fetchUrl;
      item.name = content.name ? content.name : item.name;
      item.label = content.label ? content.label : item.label;
      if (item instanceof Asset) {
        item.overlaylabel = content.overlaylabel ? content.overlaylabel : item.overlaylabel;
      }
    } else if (item instanceof DoubleImage) {
      item.label = content.label ? content.label : item.label;
      if (content.first) {
        item.firstImage.fetchUrl = content.first.fetchUrl;
        item.firstImage.name = content.first.name;
      }
      if (content.second) {
        item.secondImage.fetchUrl = content.second.fetchUrl;
        item.secondImage.name = content.second.name;
      }
    }

    slide.contents[index] = item;
    this.setState({ slide });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const slide = this.state.slide;
    slide.contents = arrayMove(slide.contents, oldIndex, newIndex);
    this.setState({ slide });
  };

  copyContent = (sourceSlide) => {
    const slide = this.state.slide;
    slide.contents = [...sourceSlide.contents, ...slide.contents];
    this.setState({ slide });
  };

  toggleItem = (key) => {
    this.setState(prevState => ({
      openedIndex: prevState.openedIndex === key ? null : key
    }));

    // scroll to item
    const item = this.state.slide.contents[key];
    setTimeout(() => this.scrollToItem(item), 10);
  };

  scrollToItem = (item) => {
    const container = this.state.containerRef.current;
    if (!!item && !!item.ref && !!item.ref.current) {
      container.scrollTop = ReactDOM.findDOMNode(item.ref.current).offsetTop;
    }
  };

  toggleButtons = () => {
    this.setState(prevState => ({
      buttonsOpened: !prevState.buttonsOpened
    }));
  };

  render() {

    const {
      slide, questionChoices, descriptionChoices,
      imageChoices, openedIndex, loading,
      otherSlides, buttonsOpened, containerRef
    } = this.state;

    return (<div className="SlideContents" ref={containerRef}>

      {slide.contents.length === 0 && <Alert variant="success">{LANG.NoQuestionsInSlide}</Alert>}
      <SortableList items={slide.contents}
                    openedIndex={openedIndex}
                    book={this.props.book}
                    onSortEnd={this.onSortEnd}
                    useDragHandle
                    loading={loading}
                    removeItem={this.removeItem}
                    toggleItem={this.toggleItem}
                    updateAction={this.updateAction}/>

      <div className="SlideContents__floating">
        {buttonsOpened && <div className="content">
          <SlideDropdown title='Text' items={descriptionChoices}/>
          <Button variant='success' size='sm' onClick={this.addNewVideo}>Video</Button>
          <Button variant='success' size='sm' onClick={this.addNewAudio}>Audio</Button>
          <SlideDropdown title='Obrázek' items={imageChoices}/>
          <SlideDropdown title='Otázka' items={questionChoices}/>
        </div>}

        <button className="main-button" onClick={this.toggleButtons}>
          {buttonsOpened && <FontAwesomeIcon icon='minus'/>}
          {!buttonsOpened && <FontAwesomeIcon icon='plus'/>}
        </button>

        {otherSlides.length > 0 && <div className='item'>
          <Dropdown className='SlideDropdown mt-2'>
            <Dropdown.Toggle variant='outline-success' size='sm' className='copy-button'>
              Kopírovat obsah
              <FontAwesomeIcon icon='copy' className='ml-3'/>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {otherSlides.map((item, key) =>
                <Dropdown.Item key={key} onClick={() => this.copyContent(item)}>
                  {item.innerID} - {item.title}
                </Dropdown.Item>)}
            </Dropdown.Menu>
          </Dropdown>
        </div>}
      </div>

    </div>);
  }
}

export { SlideContents };
