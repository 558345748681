import React, { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import { SlideInformation } from './tabs/SlideInformation';
import Nav from 'react-bootstrap/Nav';
import { SlideContents } from './tabs/SlideContents/SlideContents';

export function SlideTabs({ slide, isIntro, book, handleChange, showSlideContentsFirst }) {
  const [key, setKey] = useState(showSlideContentsFirst ? 'content' : 'information');

  // change to default tab on slide change
  useEffect(() => {
    setKey(showSlideContentsFirst ? 'content' : 'information');
  }, [slide]);

  return (
    <div className='SlideTabs'>
      <Tab.Container
        id='slideTabs'
        activeKey={key}
        onSelect={(k) => setKey(k)}>

        <Nav>
          <Nav.Item>
            <Nav.Link eventKey="information">Obecné</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="content" disabled={slide.is3d}>Obsah ({slide.contents.length})</Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content>
          <Tab.Pane eventKey="information">
            {key === 'information' && <SlideInformation slide={slide} isIntro={isIntro} book={book} handleChange={handleChange}/>}
          </Tab.Pane>

          {!slide.is3d &&
          <Tab.Pane eventKey="content">
            {key === 'content' && <SlideContents slide={slide} book={book}/>}
          </Tab.Pane>
          }
        </Tab.Content>
      </Tab.Container>
    </div>

  );
}

