import React from "react";
import {BookService} from "../../../services/book.service";
import {AddNewButton} from "../../../components/Buttons/AddNewButton";
import Modal from "react-bootstrap/Modal";
import {LANG} from "../../../config/config";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {FloatingAlert} from "../../../components/Alert";
import {SubmitButton} from "../../../components/Buttons/SubmitButton";
import {Redirect} from "react-router-dom";

class AddBookButton extends React.Component {

    constructor(props) {
        super(props);

        this.BookService = new BookService();

        this.state = {
            show: false,
            bookName: '',
            newBookId: null,
            status: {},
            redirect: null
        };
    }

    handleClose = () => {
        this.setState({
            show: false,
            status: {}
        });
    };

    handleShow = () => {
        this.setState({
            show: true
        });
    };

    handleSubmit = (e) => {

        e.preventDefault();

        console.log('add')
        if (this.state.bookName === '') {
            return;
        }

        this.setState({
            status: { loading: true }
        });

        this.BookService.addNew(this.state.bookName)
            .then((book) => {
                this.setState({
                    newBookId: book.id,
                    status: { success: true }
                });

                setTimeout(() => {
                    this.handleClose();
                    this.setState({ redirect: `/book/${book.id}` });
                }, 1000);
            })
            .catch((error) => {
                this.setState({
                    status: { error: error.response === undefined ? LANG.ErrorMessage : error.response.statusText }
                });
            });
    };

    render() {

        const { status, show, redirect } = this.state;

        if (redirect) {
            return <Redirect to={redirect} />
        }

        return (
            <>
                <AddNewButton onClick={this.handleShow}/>

                <Modal show={show} onHide={this.handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h3>{LANG.AddNewBook}</h3>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={(e) => this.handleSubmit(e)}>
                            <Form.Group>
                                <Form.Label>
                                    <strong>{LANG.Name}</strong>
                                </Form.Label>
                                <Form.Control type="text" value={this.state.bookName}
                                              autoFocus={true}
                                              ref={(node) => {
                                                  this.input = node;
                                              }}
                                              onChange={(e) => this.setState({ bookName: e.currentTarget.value })}/>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            {LANG.GoBack}
                        </Button>
                        <SubmitButton loading={status.loading}
                                      variant="success"
                                      action={(e) => this.handleSubmit(e)}
                                      label={LANG.AddNewBook}/>
                    </Modal.Footer>
                </Modal>

                <FloatingAlert type='error' message={status.error} show={status.error}
                               hideAlert={() => this.setState({ status: {} })}/>
                <FloatingAlert type='success' message={LANG.BookAddSuccess}
                               show={status.success}/>
            </>);
    }
}

export {AddBookButton}
