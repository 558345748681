import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Description, Validate } from './parts';
import Row from 'react-bootstrap/Row';
import { LANG } from '../../../config/config';
import Col from 'react-bootstrap/Col';
import { Response } from './parts/_Response';

class DragToLineQuestion extends Component {

    constructor(props) {
        super(props);

        this.state = {
            question: props.question
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (state.question !== props.question) {
            return { question: props.question };
        }
        return null;
    }

    handleCheckBoxChange = (input) => {
        const { checked, name } = input;
        this.handleChange(name, checked === true);
    };

    handleChange = (field, value) => {
        let question = this.state.question;
        question[field] = value;

        this.setState({ question: question });
        this.props.updateAction(question);
    };

    handleVariantChange = (field, index, value) => {
        let question = this.state.question;
        let variants = question.variant;
        let v = variants[index];

        v[field] = value;

        variants[index] = v;

       this.handleChange('variant', variants);
    }

    handleOptionChange = (index, value) => {
        let q = this.state.question;
        let options = q.options;

        options[index] = value;

        this.handleChange('options', options);
    }

    addVariant = (e) => {
        e.preventDefault();
        let question = this.state.question;
        let variant = question.variant;
        let options = question.options;

        variant = [...variant, { text: '', option: options.length }];
        options = [...options, ''];

        this.handleChange('variant', variant);
        this.handleChange('options', options);
    }

    removeVariant = (e, index) => {
        e.preventDefault();
        let question = this.state.question;

        let variant = question.variant;
        variant.splice(index, 1);

        let options = question.options;
        options.splice(index, 1);

        this.handleChange('variant', variant);
        this.handleChange('options', options);
    }

    render() {
        const { question } = this.state;

        return (
            <div className='fillTextQuestion'>
                <Description question={question} handleChange={this.handleChange}/>
                <Form.Group>
                    <Form.Label>
                        {LANG.DragToTitle}
                    </Form.Label>

                    {question.variant &&

                        <div>
                            {question.variant.map((variant, key) => (
                                <div className='d-flex align-items-center mb-2' key={key}>
                                    <Row className='w-100'>
                                        <Col xs={6}>
                                            <Form.Control
                                              type='text'
                                              value={variant.text}
                                              placeholder={LANG.AnswerText.toLowerCase()}
                                              onChange={(e) => this.handleVariantChange('text', key, e.target.value)}/>
                                        </Col>
                                        <Col xs={6}>
                                            <Form.Control
                                              type='text'
                                                value={question.options[key]}
                                                placeholder={LANG.OptionText.toLowerCase()}
                                                onChange={(e) => this.handleOptionChange(key, e.target.value)}/>
                                        </Col>
                                    </Row>


                                    {question.variant.length > 1 &&
                                    <button className='btn-invisible px-3 color-danger'
                                                        onClick={(e) => this.removeVariant(e, key)}>
                                        <FontAwesomeIcon icon='times' size='xs' />
                                    </button>}
                                </div>
                            ))}

                            <div className='d-flex justify-content-end'>
                                <button className='btn-invisible btn-a color-primary'
                                        onClick={(e) => this.addVariant(e)}>
                                    {LANG.AddPair} <FontAwesomeIcon icon='plus' size='xs' className='ml-2'/>
                                </button>
                            </div>
                        </div>

                    }

                </Form.Group>

                <div className='Question-checkboxes'>
                    <Validate question={question}
                              id={this.props.id}
                              handleChange={this.handleCheckBoxChange}/>
                </div>

                <Response question={question} handleChange={this.handleChange}/>
            </div>
        )
    }
}

export {DragToLineQuestion}
