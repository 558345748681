import { Asset } from './Asset';

export class Book {
    id;
    name;
    description;
    numberOfAttempts;
    numberOfTasks;
    resourcePath;
    image;
    thumbnail;
    language;

    isPublished;
    assetThumbNailId;

    _tasks = [];

    //enable experimental features (like CloundAnchors UI)
    //this setting is NOT persisted to XML, only those exp. features.
    experimentalFeatures;

    constructor(id, name, description, language, numberOfAttempts, numberOfTasks, resourcePath, tasks = []) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.numberOfAttempts = numberOfAttempts;
        this.numberOfTasks = numberOfTasks;
        this.resourcePath = resourcePath;
        this.isPublished = false;
        this.thumbnail = new Asset();
        this.language = language;

        this._tasks = tasks;
    }
}

export const bookLanguage = {
    cs: 'Čeština',
    en: 'Angličtina',
    both: 'Vše'
};
