import { SlideService } from './slide.service';
import { BookService } from './book.service';
import { LANG } from '../config/config';
import { Task } from '../classes/Task';

class TaskService {

    tasks;
    bookId;

    constructor(bookId) {
        this.bookId = bookId;
    }

    getTasks() {
        // console.log('get tasks for book-page ', this.bookId);

        const bookService = new BookService(this.bookId);

        return bookService.getBook()
            .then((book) => {
                // console.log('loaded book-page', book-page)
                return {tasks: book.tasks, prevPages: [{path: '/books/', title: LANG.BooksList}, {path: `/book/${book.id}`, title: book.name}]}
            })
    }

    saveTasks(tasks) {
        const bookService = new BookService(this.bookId);

        return bookService.getBook()
            .then(book => {
                book.tasks = tasks

                return bookService.save(book);
            })
    }

    convertToClass = obj => {
        const
          name = obj._attributes.name,
          title = obj._attributes.title,
          place = obj._attributes.place,
          lang = obj._attributes.language,
          lat = obj.location.latitude._text,
          lng = obj.location.longitude._text,
          questionslide = obj.questionslide;

        const task = new Task(name ? name : '', title ? title : '', lat ? lat : '', lng ? lng : '', [], place, true, false, lang);

        if(obj.cloudanchor) {
            const 
                clAnchID = obj.cloudanchor._attributes.id,
                clAnchOrient = obj.cloudanchor._attributes.orientation;

            task.setCloudAnchor(clAnchID, clAnchOrient);
        }

        if(!questionslide) return task

        let slideService = new SlideService(this.bookId, task.name);

        return slideService.convertSlides(questionslide)
            .then(slides => {
                // console.log(slides)

                // set parentTask to all slides
                slides.map(slide => {
                    slide.task = task
                    return slide
                })

                task.slides = slides
                return task
            })
    }
}

export {TaskService}
