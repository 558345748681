import React from 'react';
import Form from 'react-bootstrap/Form';
import { ImageBlock } from '../../../../../../components/ImageBlock';

const SlideImage = ({ index, image, book, loading, updateAction }) => {
  return (
    <div className='Image'>
      <ImageBlock book={book}
                  loading={loading}
                  image={image}
                  handleChange={(property, value) => updateAction(index, value)}/>
      <Form.Group>
        <Form.Label>
          Popisek
        </Form.Label>
        <Form.Control type='text'
                      value={image.label}
                      onChange={(e) => updateAction(index, { label: e.target.value })}/>
      </Form.Group>

      <Form.Group>
        <Form.Label>
          Popisek (v overlay režimu)
        </Form.Label>
        <Form.Control type='text'
                      value={image.overlaylabel}
                      onChange={(e) => updateAction(index, { overlaylabel: e.target.value })}/>
      </Form.Group>
    </div>
  );
};

export { SlideImage };
