export const MEDIA_TYPE = {
  IMAGE: 'image',
  VIDEO: 'video',
  AUDIO: 'audio',
  ALL: 'all',
  UNKNOWN: 'unknown'
};

export const imageFormats = ['jpg', 'jpeg', 'png', 'gif'];
export const videoFormats = ['mov', 'avi', 'mp4', 'mpeg'];
export const audioFormats = ['mp3', 'wav', 'mpeg'];
