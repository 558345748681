import React, { Component } from 'react';
import { FormControl } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Description, Shuffle, Validate } from './parts';
import { LANG } from '../../../config/config';
import { Response } from './parts/_Response';

class SingleChoiceQuestion extends Component {

    constructor(props) {
        super(props);

        this.state = {
            question: props.question
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (state.question !== props.question) {
            return { question: props.question };
        }
        return null;
    }

    handleVariantChange = (index, field, value) => {
        let q = this.state.question;
        let variants = q.variant;
        let variant = variants[index];

        variant[field] = value;
        variants[index] = variant;
        q.variant = variants;

        this.setState({
            question: q
        })
        this.props.updateAction(q);
    }

    addVariant = (e) => {
        e.preventDefault();
        let question = this.state.question;
        let variant = question.variant;

        variant = [...variant, {text: '', valid: false}];

        this.handleChange('variant', variant);
    }

    removeVariant = (e, variant) => {
        e.preventDefault();
        let question = this.state.question;
        let variants = question.variant;

        // delete
        let index = variants.indexOf(variant);
        variants.splice(index, 1);

        // set new active
        if(variant.valid && variants.length) {
            let newValid = variants[0];
            newValid.valid = true;
            variants[0] = newValid;
        }

        this.handleChange('variant', variants);
    }

    unselectAll = () => {
        let question = this.state.question;
        let variants = question.variant;

        if (!variants.length) return;

        variants.map(variant => variant.valid = false);

        this.handleChange('variant', variants);
    }

    handleRadioChange = (index, field, input) => {
        const { checked } = input;
        this.unselectAll();
        this.handleVariantChange(index, field, checked === true);
    }

    handleCheckBoxChange = (input) => {
        const { checked, name } = input;
        this.handleChange(name, checked === true);
    }

    handleChange = (field, value) => {
        let question = this.state.question;
        question[field] = value;

        this.setState({ question: question });
        this.props.updateAction(question);
    };

    render() {
        const { question } = this.state;
        const { id } = this.props;

        return (
            <>
                <Description question={question} handleChange={this.handleChange}/>
                <Form.Group>
                    <Form.Label>
                        {LANG.PossibleAnswers}
                        <br/>
                        <small>{LANG.CheckRightAnswer}</small>
                    </Form.Label>

                    <div className='mt-2'>
                        {question.variant && question.variant.length && question.variant.map((variant, key) => (
                            <div className='d-flex align-items-center mb-2' key={key}>
                                <InputGroup key={key}>
                                    <InputGroup.Prepend>
                                        <InputGroup.Radio
                                            checked={variant.valid}
                                            onChange={(e) => this.handleRadioChange(key, 'valid', e.target)}
                                            name={`${question.type}-${id}-variant-${key}`}
                                        />
                                    </InputGroup.Prepend>
                                    <FormControl value={variant.text}
                                                 placeholder={LANG.AnswerText.toLowerCase()}
                                                 onChange={(e) => this.handleVariantChange(key, 'text', e.target.value)}/>
                                </InputGroup>

                                {question.variant.length > 1 &&
                                    <button className='btn-invisible px-3 color-danger'
                                            onClick={(e) => this.removeVariant(e, variant)}>
                                        <FontAwesomeIcon icon='times' size='xs' />
                                    </button>}
                            </div>
                        ))}

                        <div className='d-flex justify-content-end'>
                            <button className='btn-invisible btn-a color-primary'
                                    onClick={(e) => this.addVariant(e)}>
                                {LANG.AddAnswer} <FontAwesomeIcon icon='plus' size='xs' className='ml-2'/>
                            </button>
                        </div>
                    </div>

                </Form.Group>

                <div className='Question-checkboxes'>
                    <Validate question={question}
                              id={this.props.id}
                              handleChange={this.handleCheckBoxChange}/>
                    <Shuffle question={question}
                             id={this.props.id}
                             handleChange={this.handleCheckBoxChange}/>

                </div>

                <Response question={question} handleChange={this.handleChange}/>
            </>
        )
    }
}

export {SingleChoiceQuestion}
