import React from 'react';
import Form from 'react-bootstrap/Form';
import { ImageBlock } from '../../../../../../components/ImageBlock';
import { Col, Row } from 'react-bootstrap';
import { LANG } from '../../../../../../config/config';

const SlideDoubleImage = ({ index, double, book, loading, updateAction }) => {
  return (
    <div className='DoubleImage'>
      <Form.Group>
        <Form.Label>
          Popisek
        </Form.Label>
        <Form.Control type='text'
                      value={double.label}
                      onChange={(e) => updateAction(index, { label: e.target.value })}/>
      </Form.Group>
      <Row>
        <Col lg={6}>
          <ImageBlock book={book}
                      title={LANG.Image + ' 1'}
                      loading={loading}
                      image={double.firstImage}
                      handleChange={(property, value) => updateAction(index, { first: value })}/>
        </Col>
        <Col lg={6}>
          <ImageBlock book={book}
                      title={LANG.Image + ' 2'}
                      loading={loading}
                      image={double.secondImage}
                      handleChange={(property, value) => updateAction(index, { second: value })}/>
        </Col>
      </Row>
    </div>
  );
};

export { SlideDoubleImage };
