import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LANG } from '../config/config';
import { AuthService } from '../services/auth.service';

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.AuthService = new AuthService();

    this.state = {
      isOpenedOnMobile: false,
      institutionName: null
    };
  }

  componentDidMount() {
    const profile = this.AuthService.getProfile();
    if (profile && profile.inst_name) {
      this.setState({
        institutionName: profile.inst_name
      });
    }
  }

  toggleMobileMenu = () => {
    this.setState(prevState => ({
      isOpenedOnMobile: !prevState.isOpenedOnMobile
    }));
  };

  render() {

    const { isOpenedOnMobile, institutionName } = this.state,
      { isAdmin, isEditor, toggleLang } = this.props;

    return (
      <nav className='Navigation'>
        <div className="logo-toggle d-lg-none">
          <NavLink to="/" className='logo'>EduARd</NavLink>
          <div className="nav-toggle-holder">
            <div className={'nav-toggle' + (isOpenedOnMobile ? ' cross' : '')}
                 onClick={this.toggleMobileMenu}>
              <span/>
              <span/>
              <span/>
            </div>
          </div>
        </div>

        {institutionName && <div className="institution-name d-none d-lg-block">
          {institutionName}
        </div>}

        <div className={'menu' + (isOpenedOnMobile ? ' show' : '')}>
          <ul className="menu-main">

            {/* Editor section */}
            {isEditor && <>
              <h5>{LANG.SecondMenuTitle}</h5>
              <li>
                <NavLink to="/books" activeClassName="active">
                  <FontAwesomeIcon icon="list-ul"/>
                  {LANG.BooksList}
                </NavLink>
              </li>
              <li>
                <NavLink to="/scenes" activeClassName="active">
                  <FontAwesomeIcon icon="cube"/>
                  {LANG.ScenariosLinkLabel}
                </NavLink>
              </li>
            </>}

            {/* Admin section */}
            {isAdmin && <>
              <h5>{LANG.FirstMenuTitle}</h5>
              <li>
                <NavLink to="/users" activeClassName="active">
                  <FontAwesomeIcon icon="users"/>
                  {LANG.UsersList}
                </NavLink>
              </li>
              <li>
                <NavLink to="/keys" activeClassName="active">
                  <FontAwesomeIcon icon="mobile-alt"/>
                  {LANG.ApiKeys}
                </NavLink>
              </li>
            </>}
          </ul>

          <ul className="menu-footer">
            <li>
              <button onClick={toggleLang}>
                {LANG.OtherLanguage}
              </button>
            </li>
            <li>
              <Link to="/logout">
                {LANG.Logout}
              </Link>
            </li>
          </ul>
        </div>
      </nav>

    );
  }
}

export { Navigation };
