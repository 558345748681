import { SlideContent } from './SlideContent';

export const DESCRIPTION_TYPE = {
  TEXT: 'text',
  HEADER: 'header',
  INFO: 'info',
  HIDDEN: 'hidden',
  MODAL: 'modal'
};

export class Description extends SlideContent {
  type; // typ Description
  content;

  constructor(type, content, order) {
    super(order);

    this.type = type;
    this.content = content;
  }
}
