import React, { Component, createRef } from 'react';
import { Editor } from '../../components/Editor';
import { Task } from '../../classes/Task';
import { AddNewButton } from '../../components/Buttons/AddNewButton';
import { EmptySlide } from '../../classes/Slide';
import { SlideDetail } from './Slide/SlideDetail';
import { Loading } from '../../components/Loading';
import { SortableItemsDefault } from '../../components/Sortable/SortItem';
import arrayMove from 'array-move';
import { BookNavigation } from './BookNavigation';
import * as ReactDOM from 'react-dom';

class IntrosTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      book: props.book,
      introTask: null,
      active: null,
      scrollableListRef: createRef(),
      showSlideContentsFirst: true
    };
  }

  componentDidMount() {
    this.getIntros();
  }

  getIntros = () => {
    const book = this.state.book;
    let introTask = book.tasks ? book.tasks.find((t => t.name === 'intro')) : null;

    if (!introTask) {
      introTask = new Task('intro');
      book.tasks.push(introTask);
    }

    console.log(introTask);

    this.setState({ introTask });
  };

  changeActive = (value, isNewlyAdded = false) => {
    this.setState({
      active: value,
      showSlideContentsFirst: !isNewlyAdded
    });
  };

  removeSlide = (slide) => {
    const { introTask } = this.state;
    let index = introTask.slides.indexOf(slide);
    if (index !== -1) introTask.slides.splice(index, 1);

    this.setState({
      active: null
    });
  };

  addSlide = () => {

    const { book, introTask } = this.state;
    const tasks = book.tasks;
    const idx = tasks.indexOf(introTask);

    const slide = new EmptySlide(introTask);

    let number = introTask.slides.length + 1;

    slide.title = slide.title + ' ' + number;
    slide.ref = createRef();

    introTask.slides = [...introTask.slides, slide];
    introTask.showChildren = true;
    tasks[idx] = introTask;

    this.changeActive(slide, true);
    setTimeout(() => this.scrollListToBottom(slide), 100);
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { introTask } = this.state;

    introTask.slides = arrayMove(introTask.slides, oldIndex, newIndex);
    this.setState({ introTask });
  };

  scrollListToBottom = (item) => {
    const list = this.state.scrollableListRef;
    if (list && list.current && item && item.ref) {
      const listElement = list.current;
      const itemElement = ReactDOM.findDOMNode(item.ref.current);

      const isSeen = listElement.scrollTop + listElement.offsetHeight > itemElement.offsetTop + itemElement.offsetHeight;
      if (itemElement && !isSeen) {
        listElement.scrollTop = itemElement.offsetTop;
      }
    }
  };

  render() {

    const { book, introTask, active, scrollableListRef, showSlideContentsFirst } = this.state;

    const $detailTemplate = <>
      <BookNavigation activeItem={active}
                      book={book}
                      isIntro={true}
                      addAction={this.addSlide}
                      removeActon={() => this.removeSlide(active)}/>
      {!!active && <SlideDetail slide={active}
                                updateSlide={this.updateSlide}
                                removeSlide={this.removeSlide}
                                addSlide={this.addSlide}
                                showSlideContentsFirst={showSlideContentsFirst}
                                isIntro={true}
                                book={book}/>}</>;

    return (
      <div className='TasksTab'>
        {!introTask && <Loading show={!introTask}/>}
        {introTask && <Editor list={<SortableItemsDefault items={introTask.slides}
                                                          activeItem={active}
                                                          scrollableListRef={scrollableListRef}
                                                          changeActive={this.changeActive}
                                                          onSortEnd={this.onSortEnd}
                                                          toggleChildren={this.toggleChildren}
                                                          helperClass='dragging'
                                                          useDragHandle/>}
                              button={<AddNewButton onClick={this.addSlide}
                                                    className=''/>}
                              detail={$detailTemplate}/>}
      </div>
    );
  }
}

export { IntrosTab };
