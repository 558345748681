import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import arrayMove from 'array-move';
import { Description, Validate } from './parts';
import { LANG } from '../../../config/config';
import { DragHandle } from '../../../components/Sortable/DragHandle';
import { Response } from './parts/_Response';

class SortQuestion extends Component {

    constructor(props) {
        super(props);

        this.state = {
            question: props.question
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (state.question !== props.question) {
            return { question: props.question };
        }
        return null;
    }

    handleCheckBoxChange = (input) => {
        const { checked, name } = input;
        this.handleChange(name, checked === true);
    };

    handleChange = (field, value) => {
        let question = this.state.question;
        question[field] = value;

        this.setState({ question: question });
        this.props.updateAction(question);
    };

    handleVariantChange = (index, value) => {
        // console.log(index, value)
        let q = this.state.question;
        let variants = q.variant;
        variants[index] = value;
        q.variant = variants;

        this.setState({
            question: q
        })
        this.props.updateAction(q);
    }

    addVariant = (e) => {
        e.preventDefault();
        let question = this.state.question;
        let variant = question.variant;

        variant = [...variant, ''];

        this.handleChange('variant', variant);
    }

    removeVariant = (e, index) => {
        e.preventDefault();
        let question = this.state.question;
        let variant = question.variant;
        variant.splice(index, 1);

        this.handleChange('variant', variant);
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        let question = this.state.question;
        let variant = question.variant;

        variant = arrayMove(variant, oldIndex, newIndex);
        this.handleChange('variant', variant);
    };

    render() {
        const { question } = this.state;

        return (
            <>
                <Description question={question} handleChange={this.handleChange}/>
                <Form.Group>
                    <Form.Label>
                        {LANG.AnswersSorted}
                    </Form.Label>

                    <List items={question.variant}
                          handleChange={this.handleVariantChange}
                          removeVariant={this.removeVariant}
                          onSortEnd={this.onSortEnd}
                          helperClass='dragging'
                          useDragHandle />

                    <div className='d-flex justify-content-end'>
                        <button className='btn-invisible btn-a color-primary'
                                onClick={(e) => this.addVariant(e)}>
                            {LANG.AddAnswer} <FontAwesomeIcon icon='plus' size='xs' className='ml-2'/>
                        </button>
                    </div>

                </Form.Group>

                <div className='Question-checkboxes'>
                    <Validate question={question}
                              id={this.props.id}
                              handleChange={this.handleCheckBoxChange}/>
                </div>

                <Response question={question} handleChange={this.handleChange}/>
            </>
        )
    }
}

const List = SortableContainer(({ items, removeVariant, handleChange }) => {

    // console.log('items to sort:', items);

    return (
        <div>
        {items.map((value, index) => (
                <Item index={index}
                      key={index}
                      ownIndex={index}
                      value={value}
                      handleChange={handleChange}
                      removeVariant={removeVariant}
                      showRemoveOption={items.length > 1}/>
            )
        )}
    </div>)

});

const Item = SortableElement(({ ownIndex, value, showRemoveOption, removeVariant, handleChange }) =>
    <div className='SortableItem mb-2'>
        {showRemoveOption &&
        <button className='btn-invisible pr-3 color-danger'
                onClick={(e) => removeVariant(e, ownIndex)}>
            <FontAwesomeIcon icon='times' size='xs' />
        </button>
        }

        <div className='SortableItem-content'>
            <Form.Control type='text' value={value} onChange={(e) => handleChange(ownIndex, e.target.value)}/>
        </div>

        <div className='question-drag'>
            <DragHandle/>
        </div>


    </div>
);

export {SortQuestion}
