import React from 'react';

export function TitleWithButton({ title, button: Button }) {
    return (
        <div className='page-title'>
            <h2>{title}</h2>
            {Button}
        </div>
    )
}
