import { CONFIG } from '../config/config';
import { AuthService } from './auth.service';

class UserService {
  Auth = new AuthService();

  getAll() {
    return this.Auth.fetch(`${CONFIG.apiUrl}/api/Institutions/current`, {
      method: 'GET'
    }).then(
      res => {
        // console.log('fetch result:', res);
        return Promise.resolve(res);
      });
  }

  invite(email, role) {
    return this.Auth.fetch(`${CONFIG.apiUrl}/api/Institutions/Invite`, {
      method: 'POST',
      body: JSON.stringify({
        email: email,
        roles: [role]
      })
    }).then(
      res => {
        // console.log('fetch result:', res);
        return Promise.resolve(res);
      });
  }

  deleteUser(email) {
    return this.Auth.fetch(`${CONFIG.apiUrl}/api/Institutions/${email}`, {
      method: 'DELETE',
      body: JSON.stringify({
        email: email
      })
    }).then(
      res => {
        // console.log('fetch result:', res);
        return Promise.resolve(res);
      });
  }

  deleteInvitation(id) {
    return this.Auth.fetch(`${CONFIG.apiUrl}/api/Institutions/invite/${id}`, {
      method: 'DELETE',
      body: JSON.stringify({
        id: id
      })
    }).then(
      res => {
        // console.log('fetch result:', res);
        return Promise.resolve(res);
      });
  }

  updateUser(email, roles) {
    return this.Auth.fetch(`${CONFIG.apiUrl}/api/Institutions/Manage`, {
      method: 'POST',
      body: JSON.stringify({
        username: email,
        roles: roles
      })
    }).then(
      res => {
        // console.log('fetch result:', res);
        return Promise.resolve(res);
      });
  }

  resetPassword(email) {
    return this.Auth.fetch(`${CONFIG.apiUrl}/api/Users/ResetPassword`, {
      method: 'POST',
      body: JSON.stringify({
        email: email,
        'client': 'Editor'
      })
    }).then(
      res => {
        console.log('fetch result:', res);
        return Promise.resolve(res);
      });
  }

  applyPasswordReset(email, token, newPassword, newPasswordConfirm) {
    const decoded = decodeURIComponent(token);
    return this.Auth.fetch(`${CONFIG.apiUrl}/api/Users/ApplyPasswordReset`, {
      method: 'POST',
      body: JSON.stringify({
        username: email,
        token: decoded,
        newPassword: newPassword,
        newPasswordConfirm: newPasswordConfirm
      })
    }).then(res => {
      return Promise.resolve(res);
    });
  }
}

export { UserService };
