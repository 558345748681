import * as XMLWriter from 'xml-writer';
import { QUESTION } from '../config/constants/question';
import { Question } from '../classes/Question';
import { Description } from '../classes/Description';
import { Asset, DoubleImage } from '../classes/Asset';
import { Video } from '../classes/Video';
import { Audio } from '../classes/Audio';

class XMLService {

  createXML(book) {

    let xw = new XMLWriter();
    xw
    .startDocument()
    .startElement('questionset')
    .writeAttribute('name', book.name)
    .writeAttribute('description', book.desc)
    .writeAttribute('language', book.language)
    .writeAttribute('maxTries', book.numberOfAttempts ? book.numberOfAttempts : 1)
    .writeAttribute('isPublished', book.isPublished ? book.isPublished.toString() : 'false')
    .writeAttribute('image', book.thumbnail ? book.thumbnail.name : '')
    .writeAttribute('layout', book.layout);

    // Tasks
    xw = this._constructTasks(xw, book);

    // Finish
    xw
    .endElement()
    .endDocument();

    console.log('SAVED XML', xw.toString());

    return xw;
  }

  // ============================================= //
  // ================== Tasks ==================== //
  // ============================================= //
  _constructTasks(xw, book) {
    if (!book.tasks || !book.tasks.length) return xw;

    book.tasks.forEach((task, key) => {
      // <task>
      xw
      .startElement('task')
      .writeAttribute('name', task.name ? task.name : `task${key}`)
      .writeAttribute('title', task.title)

      // <location>
      .startElement('location')
      .writeElement('latitude', task.latitude)
      .writeElement('longitude', task.longitude)
      .endElement();
      // </location>

      //cloud anchors
      if(task.cloudAnchorID) {
        xw
        .startElement('cloudanchor')
        .writeAttribute('id', task.cloudAnchorID)
        .writeAttribute('orientation', task.cloudAnchorOrientation)
        .endElement();
      }
      //cloud anchors

      // slides
      xw = this._constructSlides(xw, task);

      xw.endElement();
      // </task>
    });

    return xw;
  }

  // ============================================= //
  // ================== Slides =================== //
  // ============================================= //
  _constructSlides(xw, task) {
    if (!task.slides || !task.slides.length) {
      return xw;
    }

    task.slides.forEach((slide, key) => {
      // <questionslide>
      xw
      .startElement('questionslide')
      .writeAttribute('name', `slide${key}`)
      .writeAttribute('title', slide.title);

      // 3D or 2D
      xw = slide.is3d ? this._construct3dSlide(xw, slide) : this._construct2dSlide(xw, slide);

      xw.endElement();
      // </questionslide>
    });

    return xw;
  }

  _construct3dSlide(xw, slide) {
    xw
    .writeAttribute('type', 'ar_scene')
    .writeAttribute('id', slide.scenarioId);
    return xw;
  }

  _construct2dSlide(xw, slide) {
    xw.writeAttribute('layout', slide.layout);
    xw.writeAttribute('bgimage', slide.image.name ? slide.image.name : '');

    xw = this._constructContent(xw, slide);

    return xw;
  }

  // ============================================= //
  // ============== Slide content ================ //
  // ============================================= //
  _constructContent(xw, slide) {
    if (!slide.contents || !slide.contents.length) {
      return xw;
    }

    // <questions>
    xw.startElement('questions');

    slide.contents.forEach((item, key) => {
      if (item instanceof Question) {
        xw = this._constructQuestion(xw, item, key + 1);
      } else if (item instanceof Description) {
        xw = this._constructDescription(xw, item, key + 1);
      } else if (item instanceof Asset) {
        xw = this._constructImage(xw, 'images', item, key + 1);
      } else if (item instanceof Video) {
        xw = this._constructMedia(xw, 'video', item, key + 1);
      } else if (item instanceof Audio) {
        xw = this._constructMedia(xw, 'audio', item, key + 1);
      } else if (item instanceof DoubleImage) {
        xw = this._constructDoubleImage(xw, item, key + 1);
      }
    });

    xw.endElement();
    // </questions>

    return xw;
  }

  // ============================================= //
  // ========= Slide content: Description ======== //
  // ============================================= //
  _constructDescription(xw, description, order) {
    xw
    .startElement('description')
    .writeAttribute('order', order)
    .writeAttribute('type', description.type)
    .writeCData(description.content ? description.content : '')
    .endElement();

    return xw;
  }

  // ============================================= //
  // ========= Slide content: Image ============== //
  // ============================================= //
  _constructImage(xw, mediaTagName, image, order) {
    xw
    .startElement(mediaTagName)
    .writeAttribute('order', order)
    .writeAttribute('type', image.type)
    .writeAttribute('label', image.label)
    .writeAttribute('overlaylabel', image.overlaylabel)
    .text(image.name ? image.name : '')
    .endElement();

    return xw;
  }

  // ============================================= //
  // ========= Slide content: OtherMedia ========= //
  // ============================================= //
  _constructMedia(xw, mediaTagName, image, order) {
    xw
    .startElement(mediaTagName)
    .writeAttribute('order', order)
    .writeAttribute('type', image.type)
    .writeAttribute('label', image.label)
    .text(image.name ? image.name : '')
    .endElement();

    return xw;
  }

  // ============================================= //
  // ========= Slide content: Double Image ======= //
  // ============================================= //
  _constructDoubleImage(xw, double, order) {
    xw
    .startElement('images')
    .writeAttribute('order', order)
    .writeAttribute('type', double.type)
    .writeAttribute('label', double.label);

    xw
    .startElement('image')
    .text(double.firstImage && double.firstImage.name ? double.firstImage.name : '')
    .endElement();

    xw
    .startElement('image')
    .text(double.secondImage && double.secondImage.name ? double.secondImage.name : '')
    .endElement();

    xw.endElement();

    return xw;
  }

  // ============================================= //
  // ========= Slide content: Questions ========== //
  // ============================================= //

  _constructQuestion(xw, question, order) {
    const type = question.type;

    // <question>
    xw
    .startElement('question')
    .writeAttribute('order', order)
    .writeAttribute('type', question.type.toLowerCase())
    .writeAttribute('validate', question.validate ? question.validate.toString() : 'false');

    if (type === QUESTION.FILLTEXT) {
      xw.writeAttribute('caseSensitive', question.caseSensitive ? question.caseSensitive.toString() : 'false');
    }
    if (type === QUESTION.SINGLECHOICE || type === QUESTION.MULTICHOICE || type === QUESTION.TOGGLEBUTTONSGRID) {
      xw.writeAttribute('shuffle', question.shuffle ? question.shuffle.toString() : 'false');
    }

    // <description>
    xw
    .startElement('description')
    .writeCData(question.description ? question.description : '')
    .endElement();
    // </description>

    // <response>
    if (question.response && question.response.length) {
      xw
      .startElement('response')
      .writeCData(question.response)
      .endElement();
    }
    // </response>

    if (question.variant instanceof Array && question.variant.length) {
      xw = this._constructMultipleVariants(xw, question);
    } else if (question.variant) {
      xw = this._constructSingleVariant(xw, question);
    }

    if (type === QUESTION.INTERVALQUESTION) {
      xw = this._constructInterval(xw, question);
    }

    if (type === QUESTION.TOGGLEBUTTONSGRID || type === QUESTION.DRAGTOLINE || type === QUESTION.DRAGTOMIDDLE) {
      xw = this._constructOptions(xw, question);
    }

    xw.endElement();
    // </question>

    return xw;
  }

  // TOGGLEBUTTONSGRID || DRAGTOLINE || DRAGTOMIDDLE
  _constructOptions(xw, question) {
    if (!question.options || !question.options.length) {
      return xw;
    }

    const type = question.type;
    question.options.forEach((option, key) => {
      // <option>
      xw
      .startElement('option')
      .writeAttribute('id', key.toString());

      if (type === QUESTION.TOGGLEBUTTONSGRID || type === QUESTION.DRAGTOLINE) {
        xw.text(option);
      } else if (type === QUESTION.DRAGTOMIDDLE) {
        // <image>
        xw
        .startElement('image')
        .text(option.name ? option.name : '')
        .endElement();
        // </image>
      }

      xw.endElement();
      // </option>
    });

    return xw;
  }

  // INTERVALQUESTION
  _constructInterval(xw, question) {
    if (question.interval && question.interval.length) {
      question.interval.forEach((interval) => {

        // <interval>
        xw
        .startElement('interval')
        .writeAttribute('valid', interval.valid ? interval.valid.toString() : 'false')

        // <start>
        .startElement('start')
        .text(interval.start ? interval.start.toString() : '')
        .endElement()
        // </start>

        // <end>
        .startElement('end')
        .text(interval.end.toString() ? interval.end.toString() : '')
        .endElement();
        // </end>

        xw.endElement();
        // </interval>

      });
    }

    // <step>
    xw.startElement('step');
    xw.text(question.step.toString());
    xw.endElement();
    // </step>

    return xw;
  }

  _constructMultipleVariants(xw, question) {
    const type = question.type;
    question.variant.forEach((variant) => {

      if (variant) {
        // <variant>
        xw.startElement('variant');

        if (type === QUESTION.FILLTEXT || type === QUESTION.SORT) {
          xw.text(variant);
        }

        if (type === QUESTION.SINGLECHOICE || type === QUESTION.MULTICHOICE
          || type === QUESTION.TOGGLEBUTTONSGRID || type === QUESTION.DRAGTOLINE || type === QUESTION.DRAGTOMIDDLE) {

          if (type === QUESTION.SINGLECHOICE || type === QUESTION.MULTICHOICE) {
            xw.writeAttribute('valid', variant.valid ? variant.valid.toString() : 'false');
          }

          if (type === QUESTION.TOGGLEBUTTONSGRID || type === QUESTION.DRAGTOLINE || type === QUESTION.DRAGTOMIDDLE) {
            xw.writeAttribute('option', variant.option);
          }

          xw.text(variant.text ? variant.text : '');
        }

        xw.endElement();
        // </variant>
      }
    });

    return xw;
  }

  _constructSingleVariant(xw, question) {
    // <variant>
    xw.startElement('variant');
    xw.text(question.variant.toString());
    xw.endElement();
    // </variant>
    return xw;
  }
}


export { XMLService };
