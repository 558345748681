import React from 'react';
import Form from 'react-bootstrap/Form';
import { LANG } from '../../../../config/config';

export const Validate = ({ question, id, handleChange }) =>
  <Form.Check type='checkbox'
              custom
              label={LANG.ValidateAnswers.toLowerCase()}
              name='validate'
              className='mb-2'
              id={`validate-${question.type}-${id}`}
              defaultChecked={question.validate}
              onClick={(e) => handleChange(e.target)}/>;
