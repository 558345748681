import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Editor({ list, detail }) {
  return (
    <div className='Editor'>
      <Row className='flex-grow-1'>
        <Col md='4' lg={3}>
          <div className='Editor-Left'>
            {list}
          </div>
        </Col>
        <Col md='8' lg={9}>
          <div className='Editor-Right'>
            {detail}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export { Editor };
