import React from 'react';
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";

export function SubmitButton({ loading, label, action, variant='primary', className = '' }) {
    return <div className={"SubmitButton " + className}>
        <Button variant={variant}
                type={action ? 'button' : 'submit'}
                className={loading ? 'loading' : ''}
                onClick={action}
                disabled={loading}>
            <span>{label}</span>
            {loading && <Spinner animation="border" role="status" variant="light" size="sm"/>}
        </Button>
    </div>
}
