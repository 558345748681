import { AssetsService } from './assets.service';
import { Asset, DoubleImage, IMAGE_TYPE } from '../classes/Asset';
import { Video } from '../classes/Video';
import { Audio } from '../classes/Audio';
import { CONFIG } from '../config/config';

class ImageService {

    Assets = new AssetsService();

    constructor(bookId) {
        this.bookId = bookId;
    }

    getImageByFilename(filename) {
        console.log(`Getting image with name="${filename}" for book with id=${this.bookId}`);

        const emptyImage = new Asset();
        if(filename === '') return Promise.resolve(emptyImage)

        return this.Assets.getAssetsForBook(this.bookId)
            .then(assets => {

                // najdeme asset se stejným jmenem jako obrazek
                const filteredAssets = assets.filter(a => a.name === filename)
                // console.log('filteredAssets', filteredAssets)

                // jsme nenasli vhodne assety, vratime prazdny obrazek
                if (!filteredAssets.length) {
                    return emptyImage
                }

                // jinak ziskame asset a zjistime pro nej objekt s obrazkem

                const asset = filteredAssets[0];
                console.log('Found asset', asset);

                return { ...asset, fetchUrl: CONFIG.apiUrl + asset.fetchUrl };
            })
        .catch(() => {
            // no assets for book-page, return empty imgSrc
            // console.log('no assets for book-page, return empty imgSrc')
            return emptyImage;
        })
    }

    convertImageToClass(object) {
        let type = '', label = '', overlaylabel = '', order = -1;

        if (object._attributes) {
            type = object._attributes.type ? object._attributes.type : type;
            order = object._attributes.order ? object._attributes.order : order;
            label = object._attributes.label ? object._attributes.label : label;
            overlaylabel = object._attributes.overlaylabel ? object._attributes.overlaylabel : overlaylabel;
        }

        // Double image
        if (object.image && object.image.length === 2) {
            const first = object.image[0];
            const firstName = first._text ? first._text : '';

            const second = object.image[1];
            const secondName = second._text ? second._text : '';

            return new DoubleImage(order, label, new Asset(IMAGE_TYPE.DEFAULT, null, firstName), new Asset(IMAGE_TYPE.DEFAULT, null, secondName));
        }

        let imgName = object._text ? object._text : '';
        return new Asset(type, order, imgName, label, overlaylabel);
    }

    convertVideoToClass(object) {
        let label = '', order = -1;

        if (object._attributes) {
            order = object._attributes.order ? object._attributes.order : order;
            label = object._attributes.label ? object._attributes.label : label;
        }

        let imgName = object._text ? object._text : '';
        return new Video(order, imgName, label);
    }

    convertAudioToClass(object) {
        let label = '', order = -1;

        if (object._attributes) {
            order = object._attributes.order ? object._attributes.order : order;
            label = object._attributes.label ? object._attributes.label : label;
        }

        let imgName = object._text ? object._text : '';
        return new Audio(order, imgName, label);
    }
}

export {ImageService}
