import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LANG } from '../../config/config';
import { SubmitButton } from '../../components/Buttons/SubmitButton';
import { FloatingAlert } from '../../components/Alert';
import Button from 'react-bootstrap/Button';
import { UserService } from '../../services/user.service';

class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.User = new UserService();

        this.state = {
            username: '',
            password: '',
            passwordConfirm: '',
            submitted: false,
            loading: false,
            error: '',
            token: '',
            success: ''
        }
    }

    componentDidMount() {
        const token = this.props.match.params.token

        if(!token.length) {
            return;
        }

        this.setState({
            token: token
        })
    }

    handleChange = (e) => {
        const { name, value } = e.target
        this.setState({ [name]: value.replace(/\s/g, '') })
    }

    handleSubmit = (e) => {
        e.preventDefault()

        this.setState({ error: false, submitted: true })
        const { username, password, passwordConfirm, token } = this.state

        if (!(username && password && passwordConfirm) || (password !== passwordConfirm)) {
            return
        }

        this.setState({ loading: true })

        this.User.applyPasswordReset(username, token, password, passwordConfirm)
            .then((res) => {
                this.setState({ success: 'Heslo bylo úspěšně změněno', loading: false })
            })
            .catch(error => {
                let errorText = LANG.ErrorMessage
                if (error.response && error.response.status === 401) errorText = LANG.InvalidUsernameOrPassword
                else if (error.message) errorText = error.message

                this.setState({ error: errorText, loading: false })
            })
    }

    render() {

        const { username, password, passwordConfirm, submitted, loading, error, success } = this.state

        return (
            <div className='loginPage'>

                {success && <div className='resetSuccess'>
                    <h3 className="mb-4">{success}</h3>
                    <Button variant='success' size='lg'
                            onClick={() => this.props.history.push('/login')}>{LANG.LoginToEduard}</Button>
                </div>}


                {!success && <><h3 className="mb-4">Resetovat heslo</h3>

                    <form name="form" onSubmit={this.handleSubmit}>
                        <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                            <label htmlFor="username">E-mail</label>
                            <input type="text" className="form-control" name="username" value={username}
                                   onChange={this.handleChange}/>
                            {submitted && !username &&
                            <div className="alert alert-warning mt-2">{LANG.EmptyUsernameError}</div>}
                        </div>
                        <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                            <label htmlFor="password">{LANG.newPassword}</label>
                            <input type="password" className="form-control" name="password" value={password}
                                   onChange={this.handleChange}/>
                            {submitted && !password &&
                            <div className="alert alert-warning mt-2">{LANG.EmptyPasswordError}</div>}
                        </div>
                        <div className={'form-group' + (submitted && !passwordConfirm ? ' has-error' : '')}>
                            <label htmlFor="password">{LANG.newPasswordConfirm}</label>
                            <input type="password" className="form-control" name="passwordConfirm"
                                   value={passwordConfirm}
                                   onChange={this.handleChange}/>
                            {submitted && !passwordConfirm &&
                            <div className="alert alert-warning mt-2">{LANG.EmptyPasswordError}</div>}
                            {submitted && passwordConfirm && passwordConfirm !== password &&
                            <div className="alert alert-warning mt-2">Hesla se neshodují</div>}
                        </div>
                        <SubmitButton loading={loading} label="Nastavit nové heslo"/>
                    </form>

                    <div className='backToLogin'>
                        <NavLink to="/login">
                            <div className={'icon'}>
                                <FontAwesomeIcon icon='angle-left'/>
                            </div>
                            <span>Zrušit</span>
                        </NavLink>
                    </div>
                </>}

                <FloatingAlert type='error' message={error} show={error}/>
            </div>
        )
    }
}

export {ResetPassword}
