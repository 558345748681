import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { LANG } from '../../../../../../config/config';
import Button from 'react-bootstrap/Button';
import { GalleryModal } from '../../../../Gallery/GalleryModal';
import { Video } from '../../../../../../classes/Video';
import { MEDIA_TYPE } from '../../../../../../config/constants/media';

function SlideMedia({ index, media, book, updateAction, label = 'Media' }) {
  const [showGallery, setShowGallery] = useState(false);

  return (
    <div className='Media'>
      <Form.Group>
        <Form.Label>
          {label}
        </Form.Label>
        <div className='Media-file'>
          <div className='button'>
            <Button variant='primary' onClick={() => setShowGallery(true)}>{LANG.ChooseFromGallery}</Button>
          </div>
          <strong>
            {media.name}
          </strong>
        </div>
      </Form.Group>

      <Form.Group>
        <Form.Label>
          Popisek
        </Form.Label>
        <Form.Control type='text'
                      value={media.label}
                      onChange={(e) => updateAction(index, { label: e.target.value })}/>
      </Form.Group>

      <GalleryModal book={book}
                    mediaType={media instanceof Video ? MEDIA_TYPE.VIDEO : MEDIA_TYPE.AUDIO}
                    show={showGallery}
                    handleClose={() => setShowGallery(false)}
                    handleChange={(property, value) => updateAction(index, value)}/>
    </div>
  );
}

export { SlideMedia };
