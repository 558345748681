import ReactPaginate from 'react-paginate';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LANG } from '../../../config/config';

export function Pagination({ booksCount, booksPerPage, pageCount, handlePageClick, setBooksPerPage, booksOnPageCount }) {

    return (
      <div className='Pagination'>
          {(booksCount > booksPerPage && pageCount > 1) &&
          <div className={'d-flex justify-content-center'}>
              <ReactPaginate
                previousLabel={<FontAwesomeIcon icon="angle-left"/>}
                nextLabel={<FontAwesomeIcon icon="angle-right"/>}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    previousClassName={'page-item'}
                    nextClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousLinkClassName={'page-link'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                />
            </div>}

            {booksOnPageCount > 0 && <div className={'recordsOnPageInput'}>
                {LANG.ItemsPerPage}:
                <input
                    className={'form-control'}
                    type='number'
                    value={booksCount < booksPerPage ? booksCount : booksPerPage}
                    min={1}
                    max={booksCount}
                    onChange={e => {
                        e.preventDefault();
                        setBooksPerPage(e.target.value);
                    }}/>
            </div>}
        </div>
    );
}
