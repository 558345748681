import React from 'react';
import Form from 'react-bootstrap/Form';
import { LANG } from '../../../../config/config';

export const Description = ({ question, handleChange }) => (

  <Form.Group>
    <Form.Label>
      {LANG.QuestionText}
    </Form.Label>
    <Form.Control as="textarea"
                  rows="2"
                  value={question.description}
                      placeholder={LANG.QuestionText.toLowerCase()}
                      onChange={(e) => handleChange('description', e.target.value)}/>
    </Form.Group>
)
