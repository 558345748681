import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import { Description, Validate } from './parts';
import { LANG } from '../../../config/config';
import { Response } from './parts/_Response';

class NumberQuestion extends Component {

    constructor(props) {
        super(props);

        this.state = {
            question: props.question
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (state.question !== props.question) {
            return { question: props.question };
        }
        return null;
    }

    handleCheckBoxChange = (input) => {
        const { checked, name } = input;
        this.handleChange(name, checked === true);
    };

    handleChange = (field, value) => {
        let question = this.state.question;
        question[field] = value;

        this.setState({ question: question });
        this.props.updateAction(question);
    };

    render() {
        const { question } = this.state;

        return (
            <>
                <Description question={question} handleChange={this.handleChange}/>
                <Form.Group>
                    <Form.Label>
                        {LANG.RightAnswer}
                    </Form.Label>

                    <input
                      className={'form-control'}
                      type="number"
                      value={question.variant}
                      onChange={e => this.handleChange('variant', e.target.value)}
                    />

                </Form.Group>

                <div className='Question-checkboxes'>
                    <Validate question={question}
                              id={this.props.id}
                              handleChange={this.handleCheckBoxChange}/>
                </div>

                <Response question={question} handleChange={this.handleChange}/>
            </>
        )
    }
}

export {NumberQuestion}
