export class Task {

    name;
    title;
    latitude;
    longitude;
    slides;
    place;

    //experimental for CloudAnchors
    cloudAnchorID;
    cloudAnchorOrientation;

    // for view
    showOthers
    showChildren;
    useCloudAnchor;

    constructor(name, title = '', latitude = '', longitude = '', slides = [], place = '', showOthers = true, showChildren = false) {
        this.name = name;
        this.title = title;
        this.latitude = latitude;
        this.longitude = longitude;
        this.slides = slides;
        this.place = place;
        this.showOthers = showOthers;
        this.showChildren = showChildren;
    }

    // setters
    setLocation(lng, lat) {
        this.latitude = lat
        this.longitude = lng
    }

    setCloudAnchor(id, orientation) {
        this.cloudAnchorID = id;
        this.cloudAnchorOrientation = orientation;
    }
}


export class EmptyTask extends Task {

    constructor(name) {
        const title = 'Úkol',
          latitude = '',
          longitude = '',
          slides = [],
          place = '',

          // for view
          showOthers = true,
          showChildren = false;

        super(name, title, latitude, longitude, slides, place, showOthers, showChildren);
    }
}

