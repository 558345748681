import React from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import { LANG } from '../../../config/config';

const rolesLabels = [LANG.FilterAll, LANG.FilterEditors, LANG.FilterUsers, 'správci instituce', 'administrátoři'];

export const Filter = ({ activeRole, updateActon }) =>
  <ButtonGroup>
      {rolesLabels.map((value, key) => (
        <Button key={key}
                variant="outline-secondary"
                onClick={() => updateActon(key)}
                className={key === activeRole ? 'active' : ''}>
            {value}
        </Button>
      ))}
  </ButtonGroup>;
