import { CONFIG, LANG } from '../config/config';
import { AuthService } from './auth.service';
import { XMLService } from './xml.service';
import { xml2js } from 'xml-js';
import { TaskService } from './task.service';
import { BOOK_LAYOUT } from '../config/constants/layout';
import { Asset } from '../classes/Asset';
import { AssetsService } from './assets.service';
import { ImageService } from './image.service';

class BookService {

    id;
    Auth;
    Tasks;
    XML;

    constructor(id) {
        this.id = id;
        this.Tasks = new TaskService(id);
        this.Auth = new AuthService();
        this.XML = new XMLService();
        this.Assets = new AssetsService();
    }

    fetchBook(id) {
        return this.Auth.fetch(`${CONFIG.apiUrl}/api/Books/${id}`, {
            method: 'GET'
        }).then(book => {
            console.log('Fetched book from API', book);
            return book;
        });
    }

    getBook(id = this.id) {
        return this.fetchBook(id).then(book => this.fixDeprecatedBook(book)).then(book => this.getBookXml(book));
    }

    async getAll() {
        return Promise.resolve(this.Auth.fetch(`${CONFIG.apiUrl}/api/Books`, {
            method: 'GET'
        }))
        .then(books => Promise.all(books.map(b => this.fixDeprecatedBook(b))))
        .then(fulfilledPromises => fulfilledPromises.filter(p => p));
    }

    async fixDeprecatedBook (book) {
        // Try to load book XML
        return this.loadXML(book).then(object => {
            const attributes = object.questionset._attributes;

            if (!attributes) {
                return book;
            }

            // Fix book published state
            book.isPublished = attributes.isPublished === 'true';
            console.warn(`Fixing book (id=${book.id}) published state to ${book.isPublished}`);

            // Fix book thumbnail if set in XML but not in database
            if (!book.image && attributes.image) {
                console.warn(`Book (id=${book.id}) "${book.name}" has image in XML but not in database.`);

                const imageName = attributes.image;
                const imageService = new ImageService(book.id);

                // Load asset by filename
                return imageService.getImageByFilename(imageName).then(asset => {
                    // Save fetch url as image
                    book.image = asset.fetchUrl ? asset.fetchUrl : null;
                    book.thumbnail = new Asset();
                    book.thumbnail.fetchUrl = book.image;
                    console.warn(`Thumbnail of book (id=${book.id}) "${book.name}" was set to ${book.image}`)

                    return book;
                })
            }

            return book;
        }).catch(e => {
            return undefined;
        });
    }

    getBookXml(book, loadTasks = true) {
        return this.loadXML(book).then(object => {

            console.log('Converting book from XML object', object);

            // set bookLayout
            const attributes = object.questionset._attributes;

            book.name = attributes && attributes.name ? attributes.name : book.name;
            book.description = attributes && attributes.description ? attributes.description : book.description;
            book.language = attributes && attributes.language ? attributes.language : (book.language ? book.language : 'cs');

            // Fix existing CZ languages to CS
            book.language = book.language === 'cz' ? 'cs' : book.language;

            book.numberOfAttempts = attributes && attributes.numberOfAttempts ? attributes.numberOfAttempts : (book.numberOfAttempts ? book.numberOfAttempts : 1);
            book.layout = attributes && attributes.layout ? attributes.layout : BOOK_LAYOUT.MAP.value;
            book.isPublished = attributes && attributes.isPublished ? attributes.isPublished === 'true' : false;
            book.thumbnail = new Asset();

            // ExperimentalFeatures are enabled
            book.experimentalFeatures = attributes && attributes.experimentalFeatures ? attributes.experimentalFeatures === 'true' : false;

            if(!book.image) {
                if (attributes && attributes.image) {
                    book.thumbnail.name = attributes.image;
                }
            } else {
                book.thumbnail.id = book.assetThumbNailId;
                book.thumbnail.fetchUrl = book.image;
            }

            // set tasks
            const tasks = this._getTasksFromObject(object);

            const bookClass = book;
            bookClass.tasks = tasks ? tasks : [];

            if (!bookClass.tasks.length || !loadTasks) return bookClass;

            // pokud jsou nejake ukoly, konvertujeme do trid
            return Promise.all(tasks.map((task) => this.Tasks.convertToClass(task)))
            .then(convertedTasks => {
                // console.log('convertedTasks', convertedTasks)
                bookClass.tasks = convertedTasks;
                return bookClass;
            });
        })
        .catch(error => {
            // console.log('error loading XML', error)
            throw new Error(LANG.XMLLoadingError);
        });
    }

    addNew(name) {
        return this.Auth.fetch(`${CONFIG.apiUrl}/api/Books`, {
            method: 'POST',
            body: JSON.stringify({
                name: name
            })
        }).then(
          res => {
              // console.log('fetch result:', res);
              return Promise.resolve(res);
          });
    }

    save(book) {
        console.log('saving book', book);
        return this.uploadXMLBinary(book).then(() => {
            return this.updateBook(book).then((res) => Promise.resolve(res));
        });
    }

    updateBook(book) {
        console.log('saving book', book);
        console.log(' - language', book.language);
        return this.Auth.fetch(`${CONFIG.apiUrl}/api/Books/${book.id}`, {
            method: 'PUT',
            body: JSON.stringify({
                name: book.name,
                description: book.description,
                numberOfAttempts: book.numberOfAttempts,
                numberOfTasks: book.tasks ? book.tasks.length : 0,
                language: book.language,
                assetThumbNailId: book.thumbnail ? book.thumbnail.id : null
            })
        }).then(
          res => {
              // console.log('fetch result:', res);
              return Promise.resolve(res);
          });
    }

    uploadXML(book) {

        return this.Auth.fetch(`${CONFIG.apiUrl}/api/Books/${book.id}/upload/xml`, {
            method: 'POST',
            body: JSON.stringify({
                xml: this.XML.createXML(book).toString()
            })
        }).then(
          res => {
              console.log('upload result:', res);
              return Promise.resolve(res);
          });
    }

    uploadXMLBinary(book) {

        const headers = {};

        if (this.Auth.isLoggedIn()) {
            headers['Authorization'] = 'Bearer ' + this.Auth.getToken();
        }

        const XMLFile = this.XML.createXML(book);
        let formData = new FormData();
        formData.append('file', new Blob([XMLFile]));

        const options = {
            method: 'POST',
            body: formData,
            headers: headers
        };

        return fetch(`${CONFIG.apiUrl}/api/Books/${book.id}/upload`, options)
        .then(res => {
            console.log('upload result:', res);
            return Promise.resolve(res);
        });
    }

    delete(id) {
        return this.Auth.fetch(`${CONFIG.apiUrl}/api/Books/${id}`, {
            method: 'DELETE'
        }).then(
          res => {
              // console.log('fetch result:', res);
              return Promise.resolve(res);
          });
    }

    loadXML(book) {
        let resourcePath = CONFIG.apiUrl + `/api/Books/${book.id}/download`;

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        if (this.Auth.isLoggedIn()) {
            headers['Authorization'] = 'Bearer ' + this.Auth.getToken();
        }

        return fetch(resourcePath, {
            headers,
            method: 'GET'
        })
        .then(this._checkStatus)
        .then(response => {
            return response.text()
            .then(text => {
                // console.log('LOADED XML', text);
                if (!text) return null;
                return xml2js(text, { compact: true, spaces: 4 });
            });
        });
    }

    _getTasksFromObject = object => {
        if (!object.questionset.task) return []; // return empty array
        if (!object.questionset.task.length) return [object.questionset.task]; // return array with one task

        return object.questionset.task; // return array of tasks
    };

    _checkStatus = response => {
        if (response.status >= 200 && response.status < 300) {
            return response;
        } else {
            let error = new Error(response.statusText);
            error.response = response;
            throw error;
        }
    };


}

export { BookService };
