import React, { Component } from 'react';
import { LANG } from '../../config/config';
import { BookService } from '../../services/book.service';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { Loading } from '../../components/Loading';
import { FloatingAlert } from '../../components/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SubmitButton } from '../../components/Buttons/SubmitButton';
import Button from 'react-bootstrap/Button';
import { Redirect } from 'react-router-dom';
import { BookTabs } from './BookTabs';
import { BOOK_LAYOUT } from '../../config/constants/layout';

class BookPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: props.match.params.bookId,
            breadcrumbsTitle: [{ path: '/books', title: LANG.BooksList }],
            book: {},
            saveStatus: {},
            redirect: null,
            locationOK: true
        };

        this.BookService = new BookService(this.state.id);
    }

    componentDidMount() {
        this.loadBook();
    }

    loadBook = () => {

        this.setState({
            book: { loading: true }
        });

        this.BookService.getBook(this.state.id)
        .then((bookLoaded) => {
            this.setState((prevState) => ({
                book: bookLoaded,
                breadcrumbsTitle: [...prevState.breadcrumbsTitle, { title: bookLoaded.name }]
            }));
        })
        .catch((error) => {

            let errorText = LANG.ErrorMessage;
            if (error.response || error.message) {
                if (error.response && error.response.status === 404) {
                    errorText = LANG.BookNotFound;
                } else {
                    errorText = error.response ? error.response.statusText : error.message;
                }
            }

            this.setState({
                book: { error: errorText }
            });
        });
    };

    saveBook = () => {
        this.setState({
            saveStatus: { loading: true }
        });

        this.BookService.save(this.state.book)
        .then(() => {
            this.setState({
                saveStatus: { success: LANG.SaveSuccess }
            });
        })
        .catch((err) => {
            this.setState({
                saveStatus: { error: LANG.SaveError }
            });
        });
    };

    setLocationOK = (locationOK) => {
        this.setState({ locationOK });
    };

    render() {

        const { breadcrumbsTitle, book, saveStatus, redirect, locationOK } = this.state;
        const saveLabel = <>{LANG.Save} {!saveStatus.loading && <FontAwesomeIcon icon='save' className='ml-2'/>}</>;
        const requestLocation = this.state.book.layout !== BOOK_LAYOUT.LIST.value;
        const saveEnabled = requestLocation ? locationOK : true;

        if (redirect) {
            return <Redirect to={redirect}/>;
        }
        return (
          <div className="BookPage">
              <Breadcrumbs pageTitle={breadcrumbsTitle}/>

              <div className='BookPage-Header'>
                  <h3>
                      <FontAwesomeIcon icon="pen-alt" className='mr-3'/>
                      Editace učebnice
                  </h3>

                  <div className='buttons'>
                      <Button variant='secondary'
                              onClick={() => this.setState({ redirect: '/books' })}>
                          {LANG.GoBack}
                          <FontAwesomeIcon icon="times" className='ml-2'/>
                      </Button>

                      {!book.loading && !book.error &&
                      <SubmitButton variant='success'
                                    className={'ml-3' + (saveEnabled ? '' : ' disabled')}
                                    label={saveLabel}
                                    loading={saveStatus.loading}
                                    action={this.saveBook}/>}
                  </div>
              </div>

              {!book.loading && !book.error && <BookTabs book={book} setLocationOK={this.setLocationOK}/>}

              <Loading show={book.loading}/>
              <FloatingAlert type='success' message={LANG.SaveSuccess} show={saveStatus.success}
                             hideAlert={() => this.setState({ saveStatus: {} })}/>
              <FloatingAlert type='error' message={saveStatus.error} show={saveStatus.error}
                             hideAlert={() => this.setState({ saveStatus: {} })}/>
              <FloatingAlert type='error' message={book.error} show={book.error}
                             hideAlert={() => this.setState({ book: {} })}/>
          </div>
        );
    }


}

export { BookPage };
