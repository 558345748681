import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {UserService} from "../../../services/user.service";
import {ROLE} from "../../../config/constants/role";
import {LANG} from "../../../config/config";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {FloatingAlert} from "../../../components/Alert";

class EditUserButton extends React.Component {

    constructor(props) {
        super(props);

        this.UserService = new UserService();

        this.state = {
            user: props.user,
            show: false,
            status: {}
        };
    }

    setRole = (input) => {
        const { checked, name } = input;
        const { user } = this.state;

        if (checked) {
            user.roles.push(name);
        } else {
            let index = user.roles.indexOf(name);
            user.roles.splice(index, 1);
        }
    };

    handleClose = () => {
        this.setState({
            show: false
        });
    };

    handleShow = () => {
        this.setState({
            show: true
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        const { user } = this.state;

        this.setState({
            status: { loading: true }
        });

        const rolesWithoutSysadmin = user.roles.filter(r => r !== ROLE.Sysadmin);

        this.UserService.updateUser(user.email, rolesWithoutSysadmin)
            .then(() => {
                this.handleClose();

                this.setState({
                    status: { success: true }
                });

                setTimeout(this.props.update, 1000);
            })
            .catch((error) => {
                this.setState({
                    status: { error: error.response.statusText ? error.response.statusText : LANG.ErrorMessage }
                });
            });
    };

    render() {

        const { user, show, status } = this.state;

        if (!user) return null;

        return (
            <>
                <Button variant="primary" size="sm" onClick={this.handleShow}>
                    <FontAwesomeIcon icon="pen"/>
                </Button>

                <Modal show={show} onHide={this.handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h3>Editace uživatele</h3>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={(e) => this.handleSubmit(e)}>
                            <Form.Group>
                                <Form.Label>
                                    E-mail
                                </Form.Label>
                                <Form.Control type="email" value={user.email ? user.email : ''}
                                              disabled={true}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    Role
                                </Form.Label>

                                <Row>
                                    {Object.keys(ROLE).map((key) => {
                                        const role = ROLE[key];
                                        const label = LANG.roles[key];
                                        return (
                                            <Col sm={6} key={key}>
                                                <Form.Check type="checkbox"
                                                            custom
                                                            label={label}
                                                            id={`role-${role}`}
                                                            name={role}
                                                            disabled={role === ROLE.InstitutionAdmin || role === ROLE.Sysadmin}
                                                            defaultChecked={user.roles.includes(role)}
                                                            onChange={(e) => this.setRole(e.currentTarget)}/>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            {LANG.Cancel}
                        </Button>
                        <Button variant="success" onClick={(e) => this.handleSubmit(e)} disabled={status.loading}>
                            {!status.loading && <>Potvrdit změny <FontAwesomeIcon icon="check" className='ml-3' /></>}
                            {status.loading && LANG.Loading}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <FloatingAlert type='error' message={status.error} show={status.error} hideAlert={() => this.setState({status: {}})}/>
                <FloatingAlert type='success' message="Úspěšně změněno" show={status.success} hideAlert={() => this.setState({status: {}})}/>
            </>);
    }
}

export {EditUserButton};
