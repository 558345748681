import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import { LANG } from '../config/config';
import { GalleryModal } from '../pages/BookPage/Gallery/GalleryModal';
import { Loading } from './Loading';
import { Asset } from '../classes/Asset';
import { Video } from '../classes/Video';
import { MEDIA_TYPE } from '../config/constants/media';

class ImageBlock extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showGallery: false,
      title: props.title ? props.title : LANG.Image
    };
  }

  removeImage = () => {
    this.props.handleChange('image', new Asset());
  };

  toggleGallery = () => {
    this.setState(prevState => ({
      showGallery: !prevState.showGallery
    }));
  };

  render() {
    const { showGallery, title } = this.state;
    const { image, loading, book, handleChange } = this.props;

    return (
      <div className='Images'>
        <Form.Label>{title}</Form.Label>

        <GalleryModal book={book}
                      show={showGallery}
                      mediaType={MEDIA_TYPE.IMAGE}
                      handleClose={this.toggleGallery}
                      handleChange={handleChange}/>

        <div className='Images-image'>
          {loading && <div className='placeholder placeholder-image'>
            <Loading show={loading} size="sm"/>
          </div>}
          {!loading && image && !image.fetchUrl && <div className='placeholder placeholder-image' onClick={this.toggleGallery}>
            {LANG.ChooseFromGallery}
          </div>}

          {!loading && image && image.fetchUrl &&
          <>
            <div className='img' style={{ background: `url(${image.fetchUrl})` }}>
              {image instanceof Video ? image.name : null}
            </div>

            <div className='Images-image__cog'>
              <Button variant='outline-light' size='sm'><FontAwesomeIcon icon='cogs'/></Button>
            </div>
            <div className='Images-image__options'>
              <Button variant='outline-light' onClick={this.toggleGallery}>{LANG.Change}</Button>
              <Button variant='outline-light' onClick={this.removeImage} className='d-flex align-items-center justify-content-center'>
                {LANG.RemoveImageFromSlide} <FontAwesomeIcon icon='times' className='ml-3'/>
              </Button>
            </div>
          </>}
        </div>
      </div>
    );
  }
}

export { ImageBlock };
