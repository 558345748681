import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Description, Validate } from './parts';
import { LANG } from '../../../config/config';
import { Response } from './parts/_Response';

class IntervalQuestion extends Component {

    constructor(props) {
        super(props);

        this.state = {
            question: props.question
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (state.question !== props.question) {
            return { question: props.question };
        }
        return null;
    }

    handleCheckBoxChange = (input) => {
        const { checked, name } = input;
        this.handleChange(name, checked === true);
    };

    handleChange = (field, value) => {
        let question = this.state.question;
        question[field] = value;

        this.setState({ question: question });
        this.props.updateAction(question);
    }

    handleIntervalChange = (index, field, value) => {
        let q = this.state.question;
        let ints = q.interval;
        let interval = ints[index];

        interval[field] = value;
        ints[index] = interval;
        q.interval = ints;

        this.setState({
            question: q
        })
        this.props.updateAction(q);
    }

    render() {
        const { question } = this.state;

        return (
            <>
                <Description question={question} handleChange={this.handleChange}/>
                <Form.Group>
                    <Form.Label>
                        {LANG.RightAnswer}
                    </Form.Label>
                    <input
                        className={'form-control'}
                        type="number"
                        value={question.variant}
                        onChange={e => this.handleChange('variant', e.target.value)}
                    />

                </Form.Group>

                {question.interval.map((interval, key) => (
                    <Form.Group key={key}>
                        <Form.Label>
                            {interval.valid && LANG.IntervalValid}
                            {!interval.valid && LANG.IntervalInvalid}
                        </Form.Label>
                        <div>
                            <strong>
                                {interval.valid && `${LANG.From}:`}
                                {!interval.valid && `${LANG.Min}:`}
                            </strong>
                            <Col sm='3' className='d-inline-block'>
                                <input
                                    className={'form-control'}
                                    type='number'
                                    value={interval.start}
                                    onChange={(e) => this.handleIntervalChange(key, 'start', e.target.value)}/>
                            </Col>
                            <strong>
                                {interval.valid && `${LANG.To}:`}
                                {!interval.valid && `${LANG.Max}:`}
                            </strong>
                            <Col sm='3' className='d-inline-block'>
                                <input
                                    className={'form-control'}
                                    type='number'
                                    value={interval.end}
                                    onChange={(e) => this.handleIntervalChange(key, 'end', e.target.value)}/>
                            </Col>
                        </div>
                    </Form.Group>
                ))}
                <Form.Group>
                    <Form.Label>
                        {LANG.IntervalStep}
                    </Form.Label>
                    <input
                      className={'form-control'}
                      type="number"
                      value={question.step}
                      onChange={e => this.handleChange('step', e.target.value)}
                    />

                </Form.Group>

                <div className='Question-checkboxes'>
                    <Validate question={question}
                              id={this.props.id}
                              handleChange={this.handleCheckBoxChange}/>
                </div>

                <Response question={question} handleChange={this.handleChange}/>
            </>
        )
    }
}

export {IntervalQuestion}
