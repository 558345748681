import React, {Component} from 'react';
import {ApiKeyDetail} from "./ApiKeyDetail";
import {CONFIG, LANG} from "../../config/config";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {ApiService} from "../../services/api.service";
import {BookService} from "../../services/book.service";
import {ScenarioService} from "../../services/scenario.service";
import {TitleWithButton} from "../../components/TitleWithButton";
import {Editor} from "../../components/Editor";
import {SortList} from "../../components/Sortable/SortList";
import {SaveStatus} from "../../components/SaveStatus";
import {Loading} from "../../components/Loading";
import {FloatingAlert} from "../../components/Alert";
import {AddNewButton} from "../../components/Buttons/AddNewButton";

class ApiKeys extends Component {

    constructor(props) {
        super(props);

        this.ApiService = new ApiService();
        this.BookService = new BookService();
        this.ScenarioService = new ScenarioService();

        this.state = {
            keys: [],
            books: [],
            scenarios: [],
            active: null,
            changesUnsaved: false,
            saveStatus: {},
            loading: false,
            error: false,
            showTokenDialog: false,
            newKeyToken: '',
            copySuccess: ''
        };
    }

    componentWillMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { saveStatus } = this.state,
            prevSaveStatus = prevState.saveStatus,
            shouldClearSaveStatus = Object.keys(saveStatus).length !== Object.keys(prevSaveStatus).length && !saveStatus.loading;

        if (shouldClearSaveStatus) {
            const pathname = this.props.history.location.pathname;
            const basename = CONFIG.basename.endsWith('/') ? CONFIG.basename.substring(0, CONFIG.basename.length - 1) : CONFIG.basename;
            const myLocation = basename + pathname;

            setTimeout(() => {
                if (window.location.pathname !== myLocation) return;
                this.setState({
                    saveStatus: {}
                });
            }, 4000);
        }
    }

    async loadData() {
        this.setState({
            loading: true
        });

        await Promise.all([
            this.loadKeys(),
            this.loadBooks(),
            this.loadScenarios()
        ]).then(() => {
            this.setState({
                loading: false
            });

            const { keys, books, scenarios } = this.state;

            // add info to keys
            if (!keys || !keys.length) return;

            keys.map(k => {

                k.ownBooks = books.filter(b => k.books.includes(b.id));
                k.otherBooks = books.filter(b => !k.books.includes(b.id));

                k.ownScenarios = scenarios.filter(s => k.scenarios.includes(s.id));
                k.otherScenarios = scenarios.filter(s => !k.scenarios.includes(s.id));

                return k;
            });

            this.setState({
                keys: keys
            });
        }).catch(error => {
            this.setState({
                error: error ? error : LANG.ErrorMessage
            });
        });
    }

    loadKeys = () => {

        return this.ApiService.getAll()
            .then((keys) => {
                const k = keys.reverse();
                this.setState({
                    keys: k,
                    active: k.length ? k[0] : null
                });

                return k;
            });
    };

    loadBooks = () => {
        return this.BookService.getAll()
            .then(books => {

                books.map(b => {
                    b.value = b.id;
                    b.label = b.name;

                    return b;
                });

                this.setState({
                    books: books
                });

                return books;
            });
    };

    loadScenarios = () => {
        return this.ScenarioService.getAll()
            .then(scenarios => {
                scenarios.map(s => {
                    s.value = s.id;
                    s.label = s.name;

                    return s;
                });

                this.setState({
                    scenarios: scenarios
                });

                return scenarios;
            });
    };

    changeActive = (value) => {
        this.setState({
            active: value
        });
    };

    save = apiKey => {
        this.setState({
            saveStatus: { loading: true }
        });

        this.ApiService.update(apiKey)
            .then(() => {
                this.setState({
                    changesUnsaved: false,
                    saveStatus: {
                        loading: false,
                        success: LANG.SaveSuccess
                    }
                });
            })
            .catch(err => {
                console.log(err);

                this.setState({
                    changesUnsaved: false,
                    saveStatus: {
                        loading: false,
                        error: LANG.SaveError
                    }
                });
            });
    };

    remove = apiKey => {
        this.ApiService.remove(apiKey)
            .then(this.loadData.bind(this))
            .catch(err => console.log(err));
    };

    add = () => {
        const name = `Nový API klíč`;
        this.ApiService.add(name)
            .then(key => {
                const newKey = { name: name, jti: key.jti, books: [], scenarios: [] };
                this.setState(prevState => ({
                    keys: [newKey, ...prevState.keys],
                    active: newKey,
                    showTokenDialog: true,
                    newKeyToken: key.token
                }));
            });
    };

    handleCopy = () => {
        this.textArea.select();
        document.execCommand('copy');
        this.textArea.focus();
        this.setState({ copySuccess: 'Token byl zkopírovan do schránky' });
    };

    handleClose = () => {
        this.setState({
            showTokenDialog: false,
            newKeyToken: '',
            copySuccess: ''
        });
    };

    render() {
        const {
            keys, active, books, scenarios, loading, saveStatus,
            error, showTokenDialog, newKeyToken, copySuccess
        } = this.state;

        return (
            <section>

                <TitleWithButton title='API klíče' button={<AddNewButton onClick={this.add}/>}/>

                {keys.length > 0 && !loading && !error &&

                <Editor list={<SortList items={keys}
                                        activeItem={active}
                                        changeActive={this.changeActive}/>}
                        detail={<ApiKeyDetail item={active}
                                              books={books}
                                              scenarios={scenarios}
                                              saveAction={this.save}
                                              removeAction={this.remove}/>}/>
                }

                <SaveStatus status={saveStatus}/>

                <Modal show={showTokenDialog} onHide={this.handleClose}>
                    <Modal.Body>
                        <h4 className={'mt-0'}>Vygenerovaný token:</h4>
                        <Form.Control as="textarea"
                                      rows={5}
                                      className='mb-3'
                                      defaultValue={newKeyToken}
                                      ref={(textarea) => this.textArea = textarea}
                        />
                        <Button variant="success" onClick={this.handleCopy}>
                            Kopírovat
                        </Button>
                        <span className={'ml-3 color-success'}>{copySuccess}</span>
                    </Modal.Body>
                    <Modal.Footer>
                        <span className={'color-danger'}>Po uzvaření okna nebude možné znovu zobrazit vygenerovaný token.</span>
                        <Button variant="danger" onClick={this.handleClose}>
                            Zavřít
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Loading show={loading}/>
                <FloatingAlert type='error' message={LANG.ErrorMessage} show={error}
                               hideAlert={() => this.setState({ error: false })}/>
            </section>
        );
    }
}

export {ApiKeys};
