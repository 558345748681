export const CS = {

  OtherLanguage: 'English',

  FirstMenuTitle: 'Nastavení',
  SecondMenuTitle: 'Obsah',

  ApiKeys: 'API klíče',

  // Homepage
  Welcome: 'Vítejte v systému EduARd',
  WelcomeTitle: 'Vzdělávací systém EduARd',
  WelcomeDesc: 'Projekt si klade za cíl vytvořit systém pro tvorbu učebních pomůcek tzv. virtuálních naučných stezek, které umožní přesunout výuku vybraných témat ze školních lavic ven. Systém by měl sloužit pro základní a střední školy při podpoře výuky hlavně v oblasti přírodních věd, ale i humanitních věd jako jsou dějepis a občanská nauka.',

  // Users
  Users: 'Uživatelé',
  UsersAdministration: 'Správa uživatelů',
  UsersList: 'Uživatelé',
  UsersAdd: 'Nový uživatel',
  NoUsersInInstitution: 'Vaše instituce zatím nemá žádné uživatelé.',
  AddUsersByInvitation: 'Přidáním uživatele mu odešlete pozvánku.',
  ActiveUsers: 'Aktivní uživatelé',
  Invitations: 'Pozvánky',
  FilterLabel: 'Filtrovat podle role',
  FilterEditors: 'učitele',
  FilterUsers: 'studenti',
  FilterAll: 'všichni',
  CancelInvitation: 'zrušit pozvánku ',
  DeleteUser: 'smazat uživatele ',
  SendInvitations: 'Odeslat pozvánky',
  AddUsers: 'Přidat uživatelé',
  EmailAddresses: 'E-mailové adresy',
  EmailAddressesLead: 'Zadávejte oddělené čárkou, např.',
  EmailAddressesExample: 'student1@instituce.cz, student2@instituce.cz',
  roles: {
    Editor: 'Učitel',
    User: 'Student',
    InstitutionAdmin: 'Správce instituce',
    Sysadmin: 'Systémový administrátor'
  },

  // Books
  Books: 'Učebnice',
  BooksAdministration: 'Správa učebnic',
  BooksList: 'Učebnice',
  BooksAdd: 'Nová učebnice',
  ScenariosLinkLabel: '3D scény',
  ScenarioLabel: '3D scéna',
  AddScenario: 'Přidejte nějakou',
  Choose2D: ' nebo zvolte 2D obsah.',
  LayoutMap: 'pouze mapa',
  LayoutMapList: 'mapa a seznam',
  LayoutList: 'pouze seznam',
  NoBooksInInstutition: 'Pokračujte přidáním nové učebnice',
  NoBooksFound: 'Nic se nenašlo',
  ResetSearching: 'Resetovat vyhledávání',
  SearchPlaceholder: 'Hledat v seznamu učebnic...',
  EditTasks: 'Editovat úkoly',
  EditIntros: 'Editovat úvodní obrazovky',
  OpenGallery: 'Galerie obrázků',
  BasicInformation: 'Základní informace',
  TasksLayout: 'Styl zobrazení úkolů',
  DeleteBook: 'Smazat',
  EditBook: 'Editovat',
  NoScenesInInstitution: 'Vaše instituce zatím nemá žádné 3D scény.',
  NewBook: 'Nová učebnice',
  AddNewBook: 'Přidat novou učebnici',

  // Tasks
  Tasks: 'Úkoly',
  Task: 'Úkol',
  Location: 'Lokace',
  DeleteTask: 'Smazat úkol',
  AddTask: 'Přidat úkol',
  AddAnotherTask: 'Další úkol',
  ChangeOrder: 'upravit pořadí úkolů',
  SaveOrder: 'uložit pořadí',
  NoTasksInBook: 'Pokračujte přidáním nového úkolu',

  Longitude: 'Zeměpisná délka',
  Latitude: 'Zeměpisná šířka',
  ChooseTaskLocation: 'Zadejte lokaci úkolu',
  ShowOthersOnMap: 'zobrazovat ostatní úkoly na mapě',
  MapMarkerLead: 'Potažením značky upřesníte umístění úkolu. Kliknutím na značku vyberete úkol.',
  MapSuggestInputPlaceholder: 'Začněte psát název města nebo historické památky...',
  MapZoom1: 'Svět',
  MapZoom2: 'Stát',
  MapZoom3: 'Kraj',
  MapZoom4: 'Město',
  MapZoom5: 'Obec',
  MapZoom6: 'Ulice',

  // Slides
  Slide: 'Obrazovka',
  AddSlide: 'Přidat obrazovku',
  DeleteSlide: 'Smazat',
  ContentType: 'Druh obsahu',
  Content: 'obsah',
  AddAnotherSlide: 'Další obrazovka',
  IntroSlides: 'Úvodní obrazovky',
  NoSlidesInTask: 'Pokračujte přidáním nové obrazovky',

  DescriptionType: {
    TEXT: 'Obyčejný',
    HEADER: 'Nadpis',
    INFO: 'V rámečku',
    HIDDEN: 'Sbalovácí',
    MODAL: 'Modální okno'
  },

  ImageType: {
    DEFAULT: 'Normální',
    PANORAMA: 'Panorama',
    FULLSCREEN: 'Celá obrázovka',
    BLIND: 'Srovnání dvou obrázků'
  },

  QuestionType: {
    FILLTEXT: 'Textová odpověď',
    NUMBERQUESTION: 'Číselná odpověď',
    SINGLECHOICE: 'Jedna správná odpověď',
    MULTICHOICE: 'Více správných odpovědí',
    SORT: 'Seřazovací',
    INTERVALQUESTION: 'Interval',
    TOGGLEBUTTONSGRID: 'Přepínácí',
    DRAGTOLINE: 'Přiřazení popisku k popisku',
    DRAGTOMIDDLE: 'Přiřazení popisku k obrázku'
  },

  // Questions
  DeleteQuestion: 'smazat otázku',
  Questions: 'Otázky',
  NoQuestionsInSlide: 'Pokračujte přidáním obsahu',
  QuestionText: 'Text otázky',
  ShuffleAnswers: 'Zamíchat odpovědi',
  ValidateAnswers: 'vyhodnotit',
  PossibleAnswers: 'Možné odpovědi',
  RightAnswer: 'Správná odpověď',
  RightAnswers: 'Správné odpovědi',
  AnswerText: 'Text odpovědi',
  AddAnswer: 'přidat odpověď',
  CheckRightAnswer: 'Správnou odpověď označte',
  CaseSensitiveAnswer: 'rozlišovat velká a malá písmena',
  IntervalStep: 'Krok intervalu',
  IntervalValid: 'Validní interval odpovědi',
  IntervalInvalid: 'Rozsah intervalu',
  From: 'Od',
  To: 'Do',
  Min: 'Min',
  Max: 'Max',
  AnswersSorted: 'Správně seřazené odpovědi',
  AvailableOptions: 'Dostupné opce',
  OptionText: 'text opce',
  AddOption: 'přidat opci',
  StatementsList: 'Tvrzení k vyhodnocení',
  StatementsLead: 'K tvrzením přiřaďte správné opce',
  StatementText: 'text tvrzení',
  AddStatement: 'přidat tvrzení',
  AddPair: 'přidat další',
  DragToTitle: 'Správné přiřazení',

  // Images and Gallery
  Gallery: 'Galerie',
  GallerySubLabel: 'obrázků v učebnici',
  GalleryNote: 'Vyberte jeden z dostupných obrázku v učebnici',
  ChooseImage: 'Zvolte obrázek',
  EmptyGalleryLabel: 'Pokračujte nahráním souboru',
  UploadImage: 'Nahrání nového souboru',
  SetImage: 'Nastavit',
  Image: 'Obrázek',
  ChooseFromGallery: 'Vybrat z galerie',
  RemoveImageFromSlide: 'Odstranit',
  ImagePlacement: 'Rozvržení pro tablet',
  ImagePlacementUp: 'Obrázky nad textem',
  ImagePlacementDown: 'Obrázky pod textem',
  ImagePlacementLeft: 'Obrázky vlevo od textu',
  ImagePlacementRight: 'Obrázky vpravo od textu',

  // LoginPage
  Username: 'Uživatelské jméno',
  Password: 'Heslo',
  Login: 'Přihlásit',
  LoginToEduard: 'Přihlásit se do systému EduARd',
  newPassword: 'Nové heslo',
  newPasswordConfirm: 'Potvrzení hesla',

  // Common
  Logout: 'Odhlásit',
  Email: 'E-mail',
  Roles: 'Role',
  Actions: 'Akce',
  GoBack: 'Storno',
  Save: 'Uložit',
  Yes: 'Ano',
  No: 'Ne',
  Maybe: 'Možná',
  Cancel: 'zrušit',
  Delete: 'smazat',
  Add: 'přidat',
  Loading: 'Načítám',
  Uploading: 'Nahrávám',
  ItemsPerPage: 'Záznamů na stránce',
  Institution: 'Instituce',
  OtherActions: 'Další akce',
  Name: 'Název',
  Description: 'Popis',
  NumberOfAttempts: 'Pokusy',
  NumberOfTasks: 'Úkoly',
  Change: 'Změnit',
  Close: 'Zavřít',
  Preview: 'Náhled',
  Here: 'zde',
  Hide: 'skrýt',
  ChooseItemFromList: 'Vyberte položku ze seznamu.',
  ChangeOrderLead: 'Posunutím prvku změníte jeho pořadí.',

  // Confirmation modals
  AreYouSure: 'Opravdu chcete',

  // Loadings
  LoadingUsers: 'Načítám seznam uživatelů.',
  Saving: 'Ukládám',

  // Validation Error
  ErrorMessage: 'Něco se pokazilo',
  NoEmptyListOfEmails: 'Seznam e-mailových adres nesmí být prázdný',
  EmailsNotValid: 'Seznam e-mailových adres obsahuje chybnou syntaxi',
  InvitationsSendError: 'Pozvánky nebyly odeslány:',
  LogoutMessage: 'Byl jste odhlášen.',
  XMLLoadingError: 'Error loading XML',
  TaskNotFound: 'Úkol neexistuje',
  BookNotFound: 'Učebnice neexistuje',
  SaveError: 'Učebnice nebyla uložena',
  InvalidUsernameOrPassword: 'Chybné uživatelské jméno nebo heslo',
  EmptyPasswordError: 'Heslo je povinné',
  EmptyUsernameError: 'Uživatelské jméno je povinné',
  TaskLoadingError: '',
  NoEmptyName: 'Název nesmí být prázdný',

  // Validation Success
  InvitationsSendSuccess: 'Pozvánky byly úspěšně odeslány.',
  UploadSuccess: 'Úspěšně nahráno',
  SaveSuccess: 'Úspěšně uloženo',
  BookAddSuccess: 'Učebnice byla úspěšně přidána.',
  BookAddRedirect: 'Za chvíli budete přesměrovaní na její editaci.',

  // Navigation
  PromptMessage: 'Opustit stránku bez uložení změn?'
};
