import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import { LANG } from '../../config/config';
import { DeleteButton } from '../../components/Buttons/DeleteButton';
import React from 'react';
import { Task } from '../../classes/Task';
import { Slide } from '../../classes/Slide';

export function BookNavigation({ activeItem, book, addAction, removeActon, isIntro = false }) {
  return (
    <div className='BookNavigation'>
      <div className="breadcrumbs">
        <div className={'item' + (!activeItem ? ' single' : '')}>{book.name}</div>
        {!!activeItem && <>
          {(!isIntro && !!activeItem.task) && <>
            <FontAwesomeIcon icon='angle-right' className='mx-2'/>
            <div className="item mr-2">{activeItem.task.title}</div>
          </>}
          <FontAwesomeIcon icon='angle-right' className='mx-2'/>
          <div className="item">{activeItem.title}</div>
        </>}
      </div>

      <div className="actions">
        <Button variant='primary'
                size="sm"
                className='mr-3'
                onClick={addAction}>
          {isIntro && !activeItem && LANG.AddSlide}
          {!isIntro && !activeItem && LANG.AddTask}
          {activeItem instanceof Task && LANG.AddAnotherTask}
          {activeItem instanceof Slide && LANG.AddAnotherSlide}
          <FontAwesomeIcon icon='plus' className='ml-3'/>
        </Button>

        {!!activeItem && <DeleteButton action={removeActon}
                                       message={LANG.Delete + ' ' + activeItem.title + '?'}
                                       buttonText={LANG.Delete}/>}
      </div>
    </div>
  );
}
