import React, { Component } from 'react';
import { SlideTabs } from './SlideTabs';

class SlideDetail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      slide: props.slide
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.slide !== props.slide) {
      return { slide: props.slide };
    }
    return null;
  }

  handleChange = (field, value) => {
    let slide = this.state.slide;
    slide[field] = value;

    this.setState({
      slide: slide
    });
  };

  render() {

    const { slide } = this.state;
    const { isIntro, book, showSlideContentsFirst } = this.props;

    return (
      <div className='SlideDetail'>
        <SlideTabs slide={slide}
                   showSlideContentsFirst={showSlideContentsFirst}
                   isIntro={isIntro}
                   book={book}
                   handleChange={this.handleChange}/>
      </div>
    );
  }
}

export { SlideDetail };
