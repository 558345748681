import {CONFIG} from '../config/config';
import {AuthService} from "./auth.service";

class ScenarioService {
    Auth = new AuthService();

    getAll() {
        return this.Auth.fetch(`${CONFIG.apiUrl}/api/Scenarios`, {
            method: 'GET'
        }).then(
            res => {
                // console.log('fetch result:', res);
                return Promise.resolve(res);
            })
    }


}

export {ScenarioService};
