import { CS } from './languages/cs';
import { EN } from './languages/en';

const CONFIG = {
  apiUrl: process.env.REACT_APP_API_URL,
  scenariosUrl: process.env.REACT_APP_SCENARIOS_URL,
  secret: process.env.REACT_APP_SECRET,
  basename: process.env.REACT_APP_BASENAME,
  editorKey: process.env.REACT_APP_TINY_MCE_KEY
};

let LANG = CS;

export function changeConfigLang(code) {
  LANG = code === 'en' ? EN : CS;
}

export { CONFIG, LANG };
