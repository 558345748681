import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import { LANG } from '../../config/config';

export function DeleteButton({ buttonText, message, action, className }) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
      <>
          <Button variant="danger" size="sm" onClick={handleShow} className={className}>
              {buttonText && <span className='label mr-3'>{buttonText}</span>}
                <FontAwesomeIcon icon="trash-alt"/>
            </Button>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton/>
                <Modal.Body>{message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {LANG.No}
                    </Button>
                    <Button variant="danger" onClick={() => {
                        handleClose();
                        action();
                    }}>
                        {LANG.Yes}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
