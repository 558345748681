import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Description, Validate } from './parts';
import { LANG } from '../../../config/config';
import { Response } from './parts/_Response';

class FillTextQuestion extends Component {

    constructor(props) {
        super(props);

        this.state = {
            question: props.question
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (state.question !== props.question) {
            return { question: props.question };
        }
        return null;
    }

    handleCheckBoxChange = (input) => {
        const { checked, name } = input;
        this.handleChange(name, checked === true);
    };

    handleChange = (field, value) => {
        let question = this.state.question;
        question[field] = value;

        this.setState({ question: question });
        this.props.updateAction(question);
    };

    handleVariantChange = (index, value) => {
        let q = this.state.question;
        let variants = q.variant;
        variants[index] = value;
        q.variant = variants;

        this.setState({
            question: q
        })
        this.props.updateAction(q);
    }

    addVariant = (e) => {
        e.preventDefault();
        let question = this.state.question;
        let variant = question.variant;

        variant = [...variant, ''];

        this.handleChange('variant', variant);
    }

    removeVariant = (e, index) => {
        e.preventDefault();
        let question = this.state.question;
        let variant = question.variant;
        variant.splice(index, 1);

        this.handleChange('variant', variant);
    }

    render() {
        const { question } = this.state;

        return (
            <div className='fillTextQuestion'>
                <Description question={question} handleChange={this.handleChange}/>
                <Form.Group>
                    <Form.Label>
                        {question.variant.length <= 1 && LANG.RightAnswer}
                        {question.variant.length > 1 && LANG.RightAnswers}
                    </Form.Label>

                    {question.variant &&

                        <div>
                            {question.variant.map((variant, key) => (
                                <div className='d-flex align-items-center mb-2' key={key}>
                                    <Form.Control type='text'
                                              value={variant}
                                              placeholder={LANG.AnswerText.toLowerCase()}
                                              onChange={(e) => this.handleVariantChange(key, e.target.value)}/>

                                    {question.variant.length > 1 &&
                                    <button className='btn-invisible px-3 color-danger'
                                                        onClick={(e) => this.removeVariant(e, key)}>
                                        <FontAwesomeIcon icon='times' size='xs' />
                                    </button>}
                                </div>
                            ))}

                            <div className='d-flex justify-content-end'>
                                <button className='btn-invisible btn-a color-primary'
                                        onClick={(e) => this.addVariant(e)}>
                                    {LANG.AddAnswer} <FontAwesomeIcon icon='plus' size='xs' className='ml-2'/>
                                </button>
                            </div>
                        </div>

                    }

                </Form.Group>

                <div className='Question-checkboxes'>
                    <Validate question={question}
                              id={this.props.id}
                              handleChange={this.handleCheckBoxChange}/>
                    <Form.Check type='checkbox'
                                custom
                                label={LANG.CaseSensitiveAnswer}
                                name='caseSensitive'
                                id={`caseSensitive-${question.type}-${this.props.id}`}
                                defaultChecked={question.caseSensitive}
                                onClick={(e) => this.handleCheckBoxChange(e.target)}/>
                </div>

                <Response question={question} handleChange={this.handleChange}/>
            </div>
        )
    }
}

export {FillTextQuestion}
