import React, { Component } from 'react';
import { SortableItems, SortableItemsDefault } from './SortItem';
import arrayMove from 'array-move';

class SortList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: props.items,
            activeItem: props.activeItem
        };
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        if ((!state.items && props.items) || (!state.items.length && props.items.length) || state.items.length !== props.items.length || state.items !== props.items) {
            newState.items = props.items;
        }

        if (state.activeItem !== props.activeItem) {
            newState.activeItem = props.activeItem;
        }

        return newState;
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        this.setState(({ items }) => ({
            items: arrayMove(items, oldIndex, newIndex)
        }), () => {
            this.props.updateTasks(this.state.items);
        });
    };

    onSlidesSortEnd = (task, { oldIndex, newIndex }) => {
        task.slides = arrayMove(task.slides, oldIndex, newIndex);
        this.updateItems(task);
    };

    toggleChildren = (task) => {
        task.showChildren = !task.showChildren;
        this.updateItems(task);
    };

    updateItems = (task) => {
        const { items } = this.props;
        const idx = items.indexOf(task);

        items[idx] = task;

        this.setState({
            items: items
        })
    };

    render() {

        const { items, activeItem } = this.state;
        const { changeActive, isEditingMode, scrollableListRef } = this.props;

        if (isEditingMode) return <SortableItemsDefault items={items}
                                                        activeItem={activeItem}
                                                        changeActive={changeActive}
                                                        onSortEnd={this.onSortEnd}
                                                        toggleChildren={this.toggleChildren}
                                                        isEditingMode={isEditingMode}
                                                        helperClass='dragging'
                                                        useDragHandle/>;

        return <SortableItems items={items}
                              activeItem={activeItem}
                              changeActive={changeActive}
                              scrollableListRef={scrollableListRef}
                              onSortEnd={this.onSlidesSortEnd}
                              slidesSortEnd={this.onSlidesSortEnd}
                              toggleChildren={this.toggleChildren}
                              isEditingMode={isEditingMode}
                              helperClass='dragging'
                              useDragHandle/>;

    }
}

export {SortList};
