import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {UserService} from "../../../services/user.service";
import {ROLE} from "../../../config/constants/role";
import {LANG} from "../../../config/config";
import {FloatingAlert} from "../../../components/Alert";
import {AddNewButton} from "../../../components/Buttons/AddNewButton";

class AddUsersButton extends React.Component {

    constructor(props) {
        super(props);

        this.UserService = new UserService();

        this.state = {
            show: false,
            status: {},
            users: '',
            role: ROLE.User
        };
    }

    setRole = (selector) => {
        const role = selector[selector.selectedIndex].value;

        this.setState({
            role: role
        });
    };

    validateInput = (input) => {
        let regex = /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/g;
        return regex.test(input);
    }

    handleClose = () => {
        this.setState({
            show: false,
            status: {}
        });
    };

    handleShow = () => {
        this.setState({
            show: true
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        this.setState({
            status: { loading: true }
        });

        let { users, role } = this.state;

        if (users === '') {
            this.setState({
                status: { error: LANG.NoEmptyListOfEmails }
            });
            return;
        }

        if (!this.validateInput(users)) {
            this.setState({
                status: { error: LANG.EmailsNotValid }
            });
            return;
        }

        this.setState({
            status: { loading: true }
        });

        const userList = users.split(',');
        // console.log(userList);
        // let errorText = 'Pozvánky nebyly odeslány: '

        Promise.all(
            userList.map(user => {
                // remove whitespaces
                user = user.replace(/\s/g, "");
                return this.UserService.invite(user, role)
                    .catch(() => {
                        // const errorText = `Pozvánka uživateli ${user} nebyla odeslána`;
                        this.setState(prevState => ({
                            status: { error: prevState.error ? prevState.error + ', ' + user : `${LANG.InvitationsSendError} ${user}` }
                        }));
                    });
            })).then(() => {

            if (this.state.error) {
                this.setState({
                    status: { loading: false }
                });
                return;
            }

            this.setState({
                status: { success: false }
            });

            this.handleClose();
            setTimeout(this.props.update, 1000);
        })
            .catch(error => {
                this.setState({
                    status: { error: error.response === undefined ? LANG.ErrorMessage : error.response.statusText }
                });
            });
    };

    render() {

        const { show, status } = this.state;

        return (
            <>
                <AddNewButton onClick={this.handleShow}/>

                <Modal show={show} onHide={this.handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h3>{LANG.AddUsers}</h3>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={(e) => this.handleSubmit(e)}>
                            <Form.Group>
                                <Form.Label>
                                    {LANG.Roles}
                                </Form.Label>
                                <Form.Control as="select" onChange={(e) => this.setRole(e.currentTarget)}>
                                    <option value={ROLE.User}>{LANG.roles.User}</option>
                                    <option value={ROLE.Editor}>{LANG.roles.Editor}</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    {LANG.EmailAddresses}
                                    <div className='note'>
                                        {LANG.EmailAddressesLead} <span className='code'>{LANG.EmailAddressesExample}</span>
                                    </div>
                                </Form.Label>
                                <Form.Control as="textarea"
                                              rows={6}
                                              value={this.state.users}
                                              onChange={(e) => this.setState({ users: e.currentTarget.value })}/>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            {LANG.GoBack}
                        </Button>
                        <Button variant="success" onClick={(e) => this.handleSubmit(e)} disabled={status.loading}>
                            {!status.loading && <>{LANG.SendInvitations} <FontAwesomeIcon icon="paper-plane" className='ml-3' /></>}
                            {status.loading && LANG.Loading}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <FloatingAlert type='error' message={status.error} show={status.error} hideAlert={() => this.setState({status: {}})}/>
                <FloatingAlert type='success' message={ LANG.InvitationsSendSuccess + '<br/>' + LANG.LoadingUsers } show={status.success}/>
            </>);
    }
}

export {AddUsersButton};
