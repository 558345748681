import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import { Description, Shuffle, Validate } from './parts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LANG } from '../../../config/config';
import { Response } from './parts/_Response';

class ToggleButtonQuestion extends Component {

    constructor(props) {
        super(props);

        this.state = {
            question: props.question
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (state.question !== props.question) {
            return { question: props.question };
        }
        return null;
    }

    handleCheckBoxChange = (input) => {
        const { checked, name } = input;
        this.handleQuestionChange(name, checked === true);
    };

    handleQuestionChange = (field, value) => {
        let question = this.state.question;
        question[field] = value;

        this.setState({ question: question });
        this.props.updateAction(question);
    };

    handleOptionChange = (index, value) => {
        let q = this.state.question;
        let options = q.options;

        options[index] = value;
        q.options = options;

        this.setState({
            question: q
        })
        this.props.updateAction(q);
    }

    handleVariantChange = (field, index, value) => {
        let question = this.state.question;
        let variants = question.variant;
        let v = variants[index];

        v[field] = value;

        variants[index] = v;

        this.handleQuestionChange('variant', variants);
    }

    remove = (e, field, index) => {
        e.preventDefault();
        let question = this.state.question;
        let array = question[field];
        array.splice(index, 1);

        this.handleQuestionChange(field, array);
    }

    add = (e, field) => {
        e.preventDefault();
        let question = this.state.question;
        let array = question[field];

        let newEl = '';
        if(field === 'variant') newEl = {text: '', option: 0}

        array = [...array, newEl];

        this.handleQuestionChange(field, array);
    }

    setOptionForVariant = (selector, variantIndex) => {
        const optionId = selector[selector.selectedIndex].value;
        this.handleVariantChange('option', variantIndex, optionId)
    }

    render() {
        const { question } = this.state;

        return (
            <>
                <Description question={question} handleChange={this.handleQuestionChange}/>
                <Form.Group>
                    <Form.Label>
                       {LANG.AvailableOptions}
                    </Form.Label>

                    {question.options && question.options.length && question.options.map((option, key) => (
                        <div className='d-flex align-items-center mb-2' key={key}>
                            <Form.Control type='text'
                                          value={option}
                                          placeholder={LANG.OptionText.toLowerCase()}
                                          onChange={(e) => this.handleOptionChange(key, e.target.value)}/>

                            {question.options.length > 1 &&
                            <button className='btn-invisible px-3 color-danger'
                                    onClick={(e) => this.remove(e, 'options', key)}>
                                <FontAwesomeIcon icon='times' size='xs' />
                            </button>}
                        </div>
                    ))}

                    <div className='d-flex justify-content-end'>
                        <button className='btn-invisible btn-a color-primary'
                                onClick={(e) => this.add(e, 'options')}>
                            {LANG.AddOption} <FontAwesomeIcon icon='plus' size='xs' className='ml-2'/>
                        </button>
                    </div>

                    <Form.Label>
                        {LANG.StatementsList}
                        <br/>
                        <small>{LANG.StatementsLead}</small>
                    </Form.Label>

                    {question.variant &&
                    <div>
                        {question.variant.map((variant, variantKey) => (
                            <div className='d-flex align-items-center mb-2' key={variantKey}>
                                <Form.Control type='text'
                                              value={variant.text}
                                              placeholder={LANG.StatementText.toLowerCase()}
                                              onChange={(e) => this.handleVariantChange('text', variantKey, e.target.value)}/>

                                {question.options && question.options.length &&
                                <Form.Control as="select"
                                              className='ml-3'
                                              onChange={(e) => this.setOptionForVariant(e.currentTarget, variantKey)}
                                              value={variant.option}>
                                    {question.options.map((option, optionKey) =>
                                        <option key={optionKey} value={optionKey}>{option}</option>
                                    )}
                                </Form.Control>}

                                {question.variant.length > 1 &&
                                <button className='btn-invisible px-3 color-danger'
                                        onClick={(e) => this.remove(e, 'variant', variantKey)}>
                                    <FontAwesomeIcon icon='times' size='xs' />
                                </button>}
                            </div>
                        ))}

                        <div className='d-flex justify-content-end'>
                            <button className='btn-invisible btn-a color-primary'
                                    onClick={(e) => this.add(e, 'variant')}>
                                {LANG.AddStatement} <FontAwesomeIcon icon='plus' size='xs' className='ml-2'/>
                            </button>
                        </div>
                    </div>

                    }

                </Form.Group>

                <div className='Question-checkboxes'>
                    <Validate question={question}
                              id={this.props.id}
                              handleChange={this.handleCheckBoxChange}/>
                    <Shuffle question={question}
                             id={this.props.id}
                             handleChange={this.handleCheckBoxChange}/>
                </div>

                <Response question={question} handleChange={this.handleChange}/>
            </>
        )
    }
}

export {ToggleButtonQuestion}
