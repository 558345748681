import { SlideContent } from './SlideContent';

export const IMAGE_TYPE = {
  DEFAULT: 'default',
  PANORAMA: 'panorama',
  BLIND: 'blind' // dva obrazky
};

export class Asset extends SlideContent {
  id;
  type;
  name;
  label;
  overlaylabel;
  fetchUrl;

  constructor(type = IMAGE_TYPE.DEFAULT, order = null, name = '', label = '', overlaylabel = '', fetchUrl) {
    super(order);
    this.type = type;
    this.name = name;
    this.label = label;
    this.overlaylabel = overlaylabel;
    this.fetchUrl = fetchUrl;
  }
}


export class DoubleImage extends SlideContent {
  firstImage;
  secondImage;
  type;
  label;

  constructor(order = -1, label = '', firstImage = new Asset(), secondImage = new Asset()) {
    super(order);

    this.type = IMAGE_TYPE.BLIND;
    this.firstImage = firstImage;
    this.secondImage = secondImage;
    this.label = label;
  }
}
