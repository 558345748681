import { QUESTION } from '../config/constants/question';
import { Question } from '../classes/Question';
import { Asset } from '../classes/Asset';

class QuestionService {

  convertToClass(object, bookId) {

      // console.log('Q OBJ', object);

      let type = '',
        shuffle = false,
        validate = false,
        caseSensitive = false,
        order = -1;

      if (object._attributes) {
          type = object._attributes.type ? object._attributes.type.toLowerCase() : type;
          shuffle = object._attributes.shuffle ? object._attributes.shuffle : shuffle;
          validate = object._attributes.validate ? object._attributes.validate : validate;
          caseSensitive = object._attributes.caseSensitive ? object._attributes.caseSensitive : caseSensitive;
          order = object._attributes.order ? object._attributes.order : order;
      }

      let description = object.description ? object.description._cdata : '';
      let response = object.response ? object.response._cdata : '';

      let variant = null;
      if (type === QUESTION.FILLTEXT || type === QUESTION.SORT) {
          variant = [];
          if (object.variant) {
              if (object.variant.length > 1) {
                  object.variant.forEach((v) => {
                      variant = [...variant, v._text];
                  });
              } else {
                  variant = [object.variant._text];
              }
          } else {
              variant = [''];
          }
      }

      if (type === QUESTION.NUMBERQUESTION) {
          variant = object.variant ? Number(object.variant._text) : 0;
      }

      if (type === QUESTION.SINGLECHOICE || type === QUESTION.MULTICHOICE) {
          variant = [];
          if (object.variant) {
              if (object.variant.length > 1) {
                  object.variant.forEach((v) => {
                      let valid = v._attributes && v._attributes.valid ? v._attributes.valid === 'true' : false;
                      variant = [...variant, { text: v._text, valid: valid }];
                  });
              } else {
                  const v = object.variant;
                  let valid = v._attributes && v._attributes.valid ? v._attributes.valid === 'true' : false;
                  variant = [{ text: v._text, valid: valid }];
              }
          } else {
              variant = [{ text: '', valid: true }];
          }
      }

      let interval = [], step = 1;
      if (type === QUESTION.INTERVALQUESTION) {
          variant = object.variant ? Number(object.variant._text) : 50;
          step = object.step ? Number(object.step._text) : step;

          if (object.interval) {
              if (object.interval.length > 1) {
                  object.interval.forEach((i) => {
                      let valid = i._attributes && i._attributes.valid ? i._attributes.valid === 'true' : false,
                        start = i.start ? i.start._text : 0,
                        end = i.end ? i.end._text : 100;
                      interval = [...interval, { valid: valid, start: start, end: end }];
                  });
              } else {
                  const i = object.interval;
                  let valid = i._attributes && i._attributes.valid ? i._attributes.valid === 'true' : false,
                    start = i.start ? i.start._text : 0,
                    end = i.end ? i.end._text : 100;
                  variant = [{ valid: valid, start: start, end: end }];
              }
          } else {
              interval = [{ valid: false, start: 0, end: 100 }, { valid: true, start: 45, end: 55 }];
          }
      }


      let options = [];
      if (type === QUESTION.TOGGLEBUTTONSGRID || type === QUESTION.DRAGTOLINE || type === QUESTION.DRAGTOMIDDLE) {
          // console.log('TOGGLEBUTTONSGRID', object)
          variant = [];
          if (object.variant) {
              if (object.variant.length > 1) {
                  object.variant.forEach((v) => {
                      let option = v._attributes && v._attributes.option ? v._attributes.option : null,
                        text = v._text ? v._text : '';
                      variant = [...variant, { text: text, option: option }];
                  });
              } else {
                  const v = object.variant;
                  let option = v._attributes && v._attributes.option ? v._attributes.option : null,
                    text = v._text ? v._text : '';
                  variant = [{ text: text, option: option }];
              }
              
              //repair indexes in these question types (unknown error source)
              if(type === QUESTION.DRAGTOLINE || type === QUESTION.DRAGTOMIDDLE) {
                this.repairVariantDragTo(variant);
              }
          }
          if (object.option) {
              if (object.option.length > 1) {
                  object.option.forEach((o) => {

                      if (type === QUESTION.TOGGLEBUTTONSGRID || type === QUESTION.DRAGTOLINE) {
                          let text = o._text ? o._text : '';
                          options = [...options, text];
                      }

                      if (type === QUESTION.DRAGTOMIDDLE) {
                          let imgName = o.image && o.image._text ? o.image._text : '';
                          options = [...options, { src: '', name: imgName }];
                      }
                  });
              } else {

                  if (type === QUESTION.TOGGLEBUTTONSGRID || type === QUESTION.DRAGTOLINE) {
                      let text = object.option._text ? object.option._text : '';
                      options = [text];
                  }

                  if (type === QUESTION.DRAGTOMIDDLE) {
                      let imgName = object.option.image._text ? object.option.image._text : '';

                      let img = new Asset();
                      img.name = imgName;
                      options = [img];
                  }

              }
          }

          // console.log('TOGGLEBUTTONSGRID', options, variant)
      }

      let q = new Question(order);
      q.type = type.toLowerCase();
      q.shuffle = shuffle === 'true';
      q.validate = validate === 'true';
      q.caseSensitive = caseSensitive === 'true';
      q.description = description;
      q.response = response;
      q.variant = variant !== null ? variant : '';
      q.interval = interval;
      q.step = step;
      q.options = options;

      return q;

  }

  //v nekterych otazkach byly spatne indexy (ve spatnem poradi) a nekde byl index nastaven na false
  repairVariantDragTo(variant) {
    var i;
    for( i = 0; i < variant.length; i++) {
        if(variant[i].option == "false") {
            console.warn("repairing false variant ID to "+i,variant[i]);
            variant[i].option = ""+i;
        }
        if(variant[i].option != i) {
            console.warn("repairing wrong variant ID to "+i,variant[i]);
            variant[i].option = ""+i;
        }
    }
  }
}

export { QuestionService };
