import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Gallery } from './Gallery';
import { MEDIA_TYPE } from '../../../config/constants/media';

export function GalleryModal({ book, show, handleClose, handleChange, mediaType = MEDIA_TYPE.ALL }) {

  return (
    <>
      <Modal show={show} onHide={handleClose} dialogClassName={'gallery-modal'} centered>
        <Modal.Header closeButton/>
        <Modal.Body>
          <Gallery book={book} shouldLoadStart={show} imageAction={handleChange} closeModal={handleClose} mediaType={mediaType}/>
        </Modal.Body>
      </Modal>
    </>
    );
}
