import React, { useState } from 'react';
import { InformationTab } from './InformationTab';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Gallery } from './Gallery/Gallery';
import { TasksTab } from './TasksTab';
import { IntrosTab } from './IntrosTab';

export function BookTabs({ book, setLocationOK }) {
  const [key, setKey] = useState('information');

  return (
    <Tabs className='BookTabs'
          id='bookTabs'
          activeKey={key}
          onSelect={(k) => setKey(k)}>

      <Tab eventKey="information" title="Obecné informace">
        {key === 'information' && <InformationTab book={book}/>}
            </Tab>
            <Tab eventKey="intros" title="Úvodní obrazovky">
              {key === 'intros' && <IntrosTab book={book}/>}
            </Tab>
            <Tab eventKey="tasks" title="Úkoly">
              {key === 'tasks' && <TasksTab book={book} setLocationOK={setLocationOK}/>}
            </Tab>
            <Tab eventKey="gallery" title="Galerie">
              {key === 'gallery' && <Gallery book={book}/>}
            </Tab>
        </Tabs>
    );
}

