import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Description, Validate } from './parts';
import { LANG } from '../../../config/config';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Asset } from '../../../classes/Asset';
import { GalleryModal } from '../Gallery/GalleryModal';
import { Loading } from '../../../components/Loading';
import { ImageService } from '../../../services/image.service';
import { Response } from './parts/_Response';
import { MEDIA_TYPE } from '../../../config/constants/media';

class DragToMiddleQuestion extends Component {


  constructor(props) {
    super(props);

    this.ImageService = new ImageService(this.props.book.id);
    this.charLimit = 40;

    this.state = {
      question: props.question,
      showGallery: false,
      activeOptionIndex: 0
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.question !== props.question) {
      return { question: props.question };
    }
    return null;
  }

  componentDidMount() {
    this.loadImages();
  }

  loadImages = () => {
    const question = this.state.question;

    question.options.forEach((image, key) => {
      if (!image.name) return;

      image.loading = true;

      this.ImageService.getImageByFilename(image.name).then(img => {
        question.options[key] = img;

        this.setState({ question });
      })
      .catch(() => {
        image.loading = false;
      });
    });
  };

  handleCheckBoxChange = (input) => {
    const { checked, name } = input;
    this.handleChange(name, checked === true);
  };

  handleChange = (field, value) => {
    let question = this.state.question;
    question[field] = value;

    this.setState({ question: question });
    this.props.updateAction(question);
  };

  handleVariantChange = (field, index, value) => {
    let question = this.state.question;
    let variants = question.variant;
    let v = variants[index];

    v[field] = value.slice(0, this.charLimit);

    variants[index] = v;

    this.handleChange('variant', variants);
  };

  addVariant = (e) => {
    e.preventDefault();
    let question = this.state.question;
    let variant = question.variant;
    let options = question.options;

    variant = [...variant, { text: '', option: options.length }];
    options = [...options, new Asset()];

    this.handleChange('variant', variant);
    this.handleChange('options', options);
  };

  removeVariant = (e, index) => {
    e.preventDefault();
    let question = this.state.question;

    let variant = question.variant;
    variant.splice(index, 1);

    let options = question.options;
    options.splice(index, 1);

    this.handleChange('variant', variant);
    this.handleChange('options', options);
  };

  handleImageChange = (field, image) => {
    const { question, activeOptionIndex } = this.state;
    let options = question.options;

    options[activeOptionIndex] = image;

    this.handleChange('options', options);
  };

  toggleGallery = (idx) => {
    this.setState(prevState => ({
      showGallery: !prevState.showGallery,
      activeOptionIndex: idx
    }));
  };

  render() {
    const { question, showGallery } = this.state;
    const { book } = this.props;

    return (
      <div className='imageQuestion'>
        <GalleryModal book={book}
                      mediaType={MEDIA_TYPE.IMAGE}
                      show={showGallery}
                      handleClose={this.toggleGallery}
                      handleChange={this.handleImageChange}/>

        <Description question={question} handleChange={this.handleChange}/>
        <Form.Group>
          <Form.Label>
            {LANG.DragToTitle}
          </Form.Label>

          <div className='note'>
            Doporučený poměr obrázku je 16:9.<br/>
            Text odpovědi může mít maximálně 40 symbolů.
          </div>

          {question.variant &&

          <div>
            {question.variant.map((variant, key) => {

              const option = question.options[key];

              let $img = '';
              if (option.fetchUrl) {
                $img = <div className='img' style={{ background: `url(${option.fetchUrl})` }}/>;
              } else if (option.loading) {
                $img = <Loading size="sm" show={option.loading}/>;
              } else {
                $img = <Button variant='primary' onClick={() => this.toggleGallery(key)}>{LANG.ChooseFromGallery}</Button>;
              }

              return (
                <div className='d-flex align-items-end mb-3' key={key}>
                  <Row className="image-row">
                    <Col xs={6}>
                      {$img}
                    </Col>
                    <Col xs={6}>
                      <Form.Control
                        type='text'
                        value={variant.text}
                        placeholder={LANG.AnswerText.toLowerCase()}
                        onChange={(e) => this.handleVariantChange('text', key, e.target.value)}/>
                    </Col>
                  </Row>


                  {question.variant.length > 1 &&
                  <button className='btn-invisible px-3 color-danger'
                          onClick={(e) => this.removeVariant(e, key)}>
                    <FontAwesomeIcon icon='times' size='xs'/>
                  </button>}
                </div>
              );
            })}

            <div className='d-flex justify-content-end'>
              <button className='btn-invisible btn-a color-primary'
                      onClick={(e) => this.addVariant(e)}>
                {LANG.AddPair} <FontAwesomeIcon icon='plus' size='xs' className='ml-2'/>
              </button>
            </div>
          </div>

          }

        </Form.Group>

        <div className='Question-checkboxes'>
          <Validate question={question}
                    id={this.props.id}
                    handleChange={this.handleCheckBoxChange}/>
        </div>

        <Response question={question} handleChange={this.handleChange}/>
      </div>
    );
  }
}

export { DragToMiddleQuestion };
