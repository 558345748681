import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { DragHandle } from './DragHandle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Slide } from '../../classes/Slide';
import { Task } from '../../classes/Task';

const SortableItems = SortableContainer(({ items, activeItem, changeActive, scrollableListRef, childrenClass, toggleChildren, isEditingMode, slidesSortEnd }) => {
    return (
      <ul className={childrenClass} ref={scrollableListRef}>
          {items.map((value, index) => (
            <SortableItem key={`item-${index}`}
                          index={index}
                          value={value}
                          activeItem={activeItem}
                          changeActive={changeActive}
                          toggleChildren={toggleChildren}
                          isEditingMode={isEditingMode}
                          slidesSortEnd={slidesSortEnd}
            />
          ))}
      </ul>
    );
});
const SortableItemsDefault = SortableContainer(({ items, activeItem, changeActive, scrollableListRef, childrenClass, toggleChildren, isEditingMode }) => {
    return (
      <ul className={childrenClass} ref={scrollableListRef}>
          {items.map((value, index) => (
            <SortableItemDefault key={`item-${index}`}
                                 index={index}
                                 value={value}
                                 activeItem={activeItem}
                                 changeActive={changeActive}
                                 toggleChildren={toggleChildren}
                                 isEditingMode={isEditingMode}
            />
          ))}
        </ul>
    )
})

const SortableItemDefault = SortableElement(({ value, activeItem, changeActive, toggleChildren, isEditingMode }) => {
    const $itemDefault = <ItemDefault value={value}
                                      activeItem={activeItem}
                                      changeActive={changeActive}
                                      toggleChildren={toggleChildren}
                                      isEditingMode={isEditingMode}/>

    return $itemDefault

})
const SortableItem = SortableElement(({ value, activeItem, changeActive, toggleChildren, isEditingMode, slidesSortEnd }) => {
    const $itemDefault = <ItemDefault value={value}
                                      activeItem={activeItem}
                                      changeActive={changeActive}
                                      toggleChildren={toggleChildren}
                                      isEditingMode={isEditingMode}
                                      slidesSortEnd={slidesSortEnd}
        />,
        $itemWithChildren = <ItemWithChildren value={value}
                                              activeItem={activeItem}
                                              changeActive={changeActive}
                                              toggleChildren={toggleChildren}
                                              isEditingMode={isEditingMode}
                                              slidesSortEnd={slidesSortEnd}
        />

    if (value instanceof Task && value.slides.length) return $itemWithChildren

    return $itemDefault

})

const ItemWithChildren = ({ value, activeItem, changeActive, toggleChildren, isEditingMode, slidesSortEnd }) => {

    const defaultItem = <ItemDefault value={value}
                                     activeItem={activeItem}
                                     changeActive={changeActive}
                                     toggleChildren={toggleChildren}
                                     isEditingMode={isEditingMode}/>

    if (isEditingMode) return defaultItem

    if (!isEditingMode) return <ParentHolder defaultItem={defaultItem}
                                             value={value}
                                             activeItem={activeItem}
                                             changeActive={changeActive}
                                             toggleChildren={toggleChildren}
                                             isEditingMode={isEditingMode}
                                             slidesSortEnd={slidesSortEnd}/>
}

const ParentHolder = ({ defaultItem, value, activeItem, changeActive, toggleChildren, isEditingMode, slidesSortEnd }) => {

    const hideClass = value.showChildren ? '' : ' hide'

    return <div className='item-w-children'>
        {defaultItem}
        <SortableItems childrenClass={`children ${hideClass}`}
                       items={value.slides}
                       activeItem={activeItem}
                       changeActive={changeActive}
                       toggleChildren={toggleChildren}
                       isEditingMode={isEditingMode}
                       helperClass='dragging dragging-child'
                       onSortEnd={(props) => slidesSortEnd(value, props)}
                       useDragHandle/>
    </div>

}

const ItemDefault = ({ value, activeItem, changeActive, toggleChildren, isEditingMode }) => {

    const
        isActive = value === activeItem,
        showChildrenToggle = value instanceof Task && !!value.slides.length && !isEditingMode,
        showDragHandle = (value instanceof Task && isEditingMode) || (value instanceof Slide && !isEditingMode)


    return <li className={'Editor-Left__item' + (isActive ? ' active' : '')} ref={value.ref}>
        <div className='title'
             onClick={() => {
                 if (!isEditingMode) changeActive(value);
             }}>
            <span>{value.innerID >= 0 ? value.innerID+' - ' : null}{value.title ? value.title : value.name}</span>
        </div>

        {(showDragHandle || showChildrenToggle) && <div className='actions'>
            {showDragHandle && <DragHandle/>}
            {showChildrenToggle &&
            <div className={'actions-toggle' + (value.showChildren ? ' show' : '')}
                 onClick={() => toggleChildren(value)}>
                <FontAwesomeIcon icon="angle-down"/>
            </div>
            }
        </div>}
    </li>
}

export {SortableItems, SortableItemsDefault}
