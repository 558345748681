import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { ROLE_CLAIM } from '../config/constants/claim';
import { ROLE } from '../config/constants/role';
import { AuthService } from '../services/auth.service';
import { DefaultLayout } from './layouts/DefaultLayout';

export class PrivateRoute extends React.Component {

    constructor(props) {
        super(props);

        this.Auth = new AuthService();

        this.state = {
            user: this.Auth.getProfile()
        }
    }

    isRoleValid = (userRoles, validRoles) => {
        if (!userRoles.length) return false;
        if (Array.isArray(userRoles)) return userRoles.some(role => validRoles.includes(role));
        return validRoles.includes(userRoles);
    }

    render() {
        const { component: Component, roles, toggleLang, ...rest } = this.props;
        const { user } = this.state;

        return (
          <Route {...rest} render={props => {

              // console.log('PR USER: ', user, roles);

              if (user && user[ROLE_CLAIM] && this.Auth.isLoggedIn()) {
                  const userRoles = user[ROLE_CLAIM];
                  const hasValidRole = this.isRoleValid(userRoles, roles);
                  const isAdmin = userRoles.includes(ROLE.InstitutionAdmin),
                            isEditor = userRoles.includes(ROLE.Editor);

                    if (hasValidRole) {
                        // console.log('HAS VALID ROLE ', user[ROLE_CLAIM]);
                        return <DefaultLayout component={Component}
                                              isAdmin={isAdmin}
                                              isEditor={isEditor}
                                              toggleLang={toggleLang}
                                              {...props} />
                    }
                }

                // not logged in or has invalid role
                return <Redirect to={{ pathname: '/login', state: { from: props.location } }}/>;

            }}>
            </Route>
        )
    }
}
