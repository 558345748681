export class Invitation {
    id;
    email;
    roles;

    constructor({ id, email, roles }) {
        this.id = id;
        this.email = email;
        this.roles = roles;
    }
}
