import React, {Component} from "react";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import {CONFIG, LANG} from "../../../config/config";
import {ScenarioService} from "../../../services/scenario.service";
import {Loading} from "../../../components/Loading";
import {FloatingAlert} from "../../../components/Alert";

class SlideScenarioLayout extends Component {

    constructor(props) {
        super(props)

        this.ScenarioService = new ScenarioService()

        this.state = {
            activeScenario: props.activeScenario,
            scenarios: []
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.activeScenario !== this.state.activeScenario) {
            this.setState({
                activeScenario: nextProps.activeScenario
            })
        }
    }

    componentDidMount() {
        this.setState({
            scenarios: {
                loading: true
            }
        })

        this.ScenarioService.getAll()
            .then((scenarios) => {
                this.setState({
                    scenarios: scenarios
                })
            })
            .catch((error) => {
                this.setState({
                    scenarios: {
                        error: true
                    }
                })
            })

    }

    setScenario = (selector) => {
        const id = selector[selector.selectedIndex].value

        this.props.handleChange('scenarioId', id)
    }

    render() {
        const { activeScenario, scenarios } = this.state
        return (
            <>
                {scenarios.length === 0 && <Alert variant='warning mt-6'>
                    {LANG.NoScenesInInstitution}<br/>
                    {LANG.AddScenario}
                    <button className='btn-invisible' onClick={() => window.open(CONFIG.scenariosUrl, '_blank')}>
                        <strong className='ml-1'> {LANG.Here}</strong>
                    </button> {LANG.Choose2D}</Alert>}

                {scenarios.length > 0 && <Form.Group>
                    <Form.Label>
                        {LANG.ScenarioLabel}
                    </Form.Label>
                    <Form.Control as="select" onChange={(e) => this.setScenario(e.currentTarget)}
                                  value={activeScenario}>
                        {scenarios.map((s, i) =>
                            <option key={i} value={s.id}>{s.name}</option>
                        )}
                    </Form.Control>
                </Form.Group>
                }

                <Loading show={scenarios.loading} size='sm'/>
                <FloatingAlert type='error' message={scenarios.error} show={scenarios.error}
                               hideAlert={() => this.setState({ scenarios: [] })}/>
            </>
        )
    }
}

export {SlideScenarioLayout}
