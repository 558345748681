import React, {Component} from 'react';
import Form from "react-bootstrap/Form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {LANG} from "../../config/config";
import Select from "react-select";
import {ConfirmationModal} from "../../components/Confirmations";
import {Prompt} from "react-router-dom";

class ApiKeyDetail extends Component {

    constructor(props) {
        super(props)

        this.state = {
            item: props.item,
            showDeleteModal: false,
            changesUnsaved: false
        }
    }

    static getDerivedStateFromProps(nextProps, state) {
        return state.item !== nextProps.item ? { item: nextProps.item } : null
    }

    handleChange = (field, value) => {
        let { item } = this.state
        item[field] = value

        this.setState({
            item: item,
            changesUnsaved: true
        }, () => console.log('saved item', item))
    }

    handleSelect = (field, values) => {
        this.handleChange(field, values)

        let ids = []
        if (values) values.map(v => {
            return ids.push(v.id)
        })

        const idsField = field.replace('own', '').toLowerCase()

        this.handleChange(idsField, ids)
    }

    hideModal = () => {
        this.setState({ showDeleteModal: false })
    }

    removeKey = () => {
        this.hideModal()
        this.props.removeAction(this.state.item)
    }

    saveKey = () => {
        this.setState({
            changesUnsaved: false
        })
        this.props.saveAction(this.state.item)
    }

    render() {

        const { item, showDeleteModal, changesUnsaved } = this.state
        const { books, scenarios } = this.props

        console.log('active ', item.ownBooks)

        return (
            <div className='ApiKeyDetail'>

                    <h4 className='mt-0'>{LANG.Name}</h4>
                    <Form.Control type="text"
                                  size='lg'
                                  className='font-weight-bold mb-5'
                                  value={item.name}
                                  onChange={(e) => this.handleChange('name', e.target.value)}/>

                    <h4 className='mt-0'>Klíč</h4>
                    <Form.Control type="text"
                                  className='mb-5'
                                  value={item.jti}
                                  disabled/>

                    <Row>
                        <Col md={6}>
                            <h4 className='mt-0'>{LANG.Books}</h4>

                            <Select
                                value={item.ownBooks}
                                options={books}
                                isMulti
                                isClearable={false}
                                closeMenuOnSelect={false}
                                onChange={(values) => this.handleSelect('ownBooks', values)}
                            />
                        </Col>

                        <Col md={6}>
                            <h4 className='mt-0'>{LANG.ScenariosLinkLabel}</h4>

                            <Select
                                value={item.ownScenarios}
                                options={scenarios}
                                isMulti
                                isClearable={false}
                                closeMenuOnSelect={false}
                                onChange={(values) => this.handleSelect('ownScenarios', values)}
                            />
                        </Col>
                    </Row>

                    <div className='d-flex flex-column align-items-start mt-5'>
                        <Button onClick={this.saveKey} variant='success'>
                            {LANG.Save}
                            <FontAwesomeIcon icon="save" className='ml-3'/>
                        </Button>

                        <Button variant='danger' className='mt-3'
                                onClick={() => this.setState({ showDeleteModal: true })}>
                            Smazat API klíč
                            <FontAwesomeIcon icon='trash-alt' className='ml-3'/>
                        </Button>
                    </div>

                <Prompt when={changesUnsaved} message={LANG.PromptMessage}/>

                <ConfirmationModal show={showDeleteModal}
                                   action={this.removeKey}
                                   message={`Smazat úkol ${item.name}?`}
                                   hideAction={this.hideModal}/>
            </div>
        )
    }
}

export {ApiKeyDetail}
