import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import { LANG } from '../../config/config';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { BOOK_LAYOUT } from '../../config/constants/layout';
import Button from 'react-bootstrap/Button';
import { WysiwygEditor } from '../../components/Wysiwyg';
import { ImageBlock } from '../../components/ImageBlock';
import { ImageService } from '../../services/image.service';
import Dropdown from 'react-bootstrap/Dropdown';
import { bookLanguage } from '../../classes/Book';

class InformationTab extends Component {
    constructor(props) {
        super(props);

        this.ImageService = new ImageService(this.props.book.id);

        this.state = {
            book: props.book,
            loading: false
        };
    }

    componentDidMount() {
        this.loadThumbnail();
    }

    loadThumbnail = () => {
        const book = this.state.book;

        if (!book.image || !book.image.name) return;

        this.setState({
            loading: true
        });

        this.ImageService.getImageByFilename(book.image.name)
        .then(image => {
            book.image = image;

            this.setState({
                loading: false
            });
        })
        .catch(() => {
            this.setState({
                loading: false
            });
        });
    };

    handleChange = (field, value) => {
        let book = this.state.book;
        if (!book) return;

        book[field] = value;
        this.setState({ book: book });
    };

    handleThumbChange = (_, image) => {
        let book = this.state.book;
        if (!book) return;

        console.log('Changing book thumbnail to', image)
        book.thumbnail = image;
        this.setState({ book: book });
    }

    render() {

        const { book, loading } = this.state;

        return (
          <div className='InformationTab'>

              {/* TODO: original book footer */}

              <Form>
                  <Form.Group>
                      <Form.Label>
                          {LANG.Name}
                      </Form.Label>
                      <Form.Control type="text"
                                    size='lg'
                                    className='font-weight-bold'
                                    value={book.name === null ? '' : book.name}
                                    onChange={e => this.handleChange('name', e.target.value)}/>
                  </Form.Group>

                  <Form.Group as={Row}>
                      <Col xl={6}>
                          <Form.Group>
                              <Form.Label>
                                  {LANG.NumberOfAttempts}
                              </Form.Label>
                              <input className='form-control'
                                     type="number"
                                     value={book.numberOfAttempts ? book.numberOfAttempts : 1}
                                     min={1}
                                     max={30}
                                     onChange={e => this.handleChange('numberOfAttempts', e.target.value)}/>
                          </Form.Group>
                      </Col>
                      <Col xl={6}>
                          <Form.Label>
                              Jazyk
                          </Form.Label>
                          <Dropdown className='LanguageDropdown'>
                              <Dropdown.Toggle size='sm'>
                                  {bookLanguage[book.language]}
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                  {Object.keys(bookLanguage).map((key, index) => {
                                        return (key !== 'both' && <Dropdown.Item key={index}
                                                                                 onClick={() => this.handleChange('language', key)}>
                                            {bookLanguage[key]}
                                        </Dropdown.Item>);
                                    }
                                  )}
                              </Dropdown.Menu>
                          </Dropdown>
                      </Col>
                  </Form.Group>

                  <Form.Group as={Row}>

                      <Col xl={6}>
                          <Form.Label>
                              {LANG.TasksLayout}
                          </Form.Label>
                          <ButtonGroup>
                              {Object.entries(BOOK_LAYOUT).map(([value, layout], key) => (
                                <Button key={key}
                                        variant="outline-secondary"
                                        onClick={() => this.handleChange('layout', layout.value)}
                                        className={layout.value === book.layout ? 'active' : ''}>
                                    {layout.label}
                                </Button>
                              ))}
                          </ButtonGroup>
                      </Col>

                      <Col xl={6} className="mt-4 mt-xl-0">
                          <Form.Label>
                              Stav
                          </Form.Label>
                          <ButtonGroup>
                              <Button variant="outline-success"
                                      onClick={() => this.handleChange('isPublished', true)}
                                      className={book.isPublished ? 'active' : ''}>
                                  Publikováno
                              </Button>
                              <Button variant="outline-danger"
                                      onClick={() => this.handleChange('isPublished', false)}
                                      className={!book.isPublished ? 'active' : ''}>
                                  Nepublikováno
                              </Button>
                          </ButtonGroup>
                      </Col>

                      {/*  <Col xl={6} className="mt-4 mt-xl-0">
                            <Form.Label>
                                Povolení experimentálních nastavení
                            </Form.Label>
                            <ButtonGroup>
                                <Button variant="outline-secondary"
                                        onClick={() => this.handleChange('experimentalFeatures', false)}
                                        className={!book.experimentalFeatures ? 'active' : ''}>
                                    Vypnuto
                                </Button>
                                <Button variant="outline-danger"
                                        onClick={() => this.handleChange('experimentalFeatures', true)}
                                        className={book.experimentalFeatures ? 'active' : ''}>
                                    Zapnuto
                                </Button>
                            </ButtonGroup>
                        </Col>
                              */}
                  </Form.Group>

                  <Form.Group>
                      <Form.Label>
                          {LANG.Description}
                      </Form.Label>
                      <WysiwygEditor value={book.description === null ? '' : book.description}
                                     onChange={(e) => this.handleChange('description', e)}/>
                  </Form.Group>
              </Form>

              <div className='book-thumbnail'>
                  <ImageBlock book={book}
                              loading={loading}
                              image={book.thumbnail}
                              handleChange={this.handleThumbChange}/>
              </div>
          </div>
        );
    }
}

export { InformationTab };
