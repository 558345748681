import React from 'react';
import { FillTextQuestion, IntervalQuestion, MultiChoiceQuestion, NumberQuestion, SingleChoiceQuestion, SortQuestion, ToggleButtonQuestion } from '../../../../Question';
import { DragToLineQuestion } from '../../../../Question/_DragToLineQuestion';
import { DragToMiddleQuestion } from '../../../../Question/_DragToMiddleQuestion';
import { QUESTION } from '../../../../../../config/constants/question';

const SlideQuestion = ({ question, ownIndex, updateAction, book }) => {
  return (
    <div className='Question'>
      {question.type === QUESTION.SINGLECHOICE && <SingleChoiceQuestion question={question} id={ownIndex} updateAction={updateAction}/>}
      {question.type === QUESTION.MULTICHOICE && <MultiChoiceQuestion question={question} id={ownIndex} updateAction={updateAction}/>}
      {question.type === QUESTION.SORT && <SortQuestion question={question} id={ownIndex} updateAction={updateAction}/>}
      {question.type === QUESTION.NUMBERQUESTION && <NumberQuestion question={question} id={ownIndex} updateAction={updateAction}/>}
      {question.type === QUESTION.INTERVALQUESTION && <IntervalQuestion question={question} id={ownIndex} updateAction={updateAction}/>}
      {question.type === QUESTION.FILLTEXT && <FillTextQuestion question={question} id={ownIndex} updateAction={updateAction}/>}
      {question.type === QUESTION.TOGGLEBUTTONSGRID && <ToggleButtonQuestion question={question} id={ownIndex} updateAction={updateAction}/>}
      {question.type === QUESTION.DRAGTOLINE && <DragToLineQuestion question={question} id={ownIndex} updateAction={updateAction}/>}
      {question.type === QUESTION.DRAGTOMIDDLE && <DragToMiddleQuestion question={question} id={ownIndex} updateAction={updateAction} book={book}/>}
    </div>
  );
};

export { SlideQuestion };
