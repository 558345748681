import React from 'react';
import Form from 'react-bootstrap/Form';
import { WysiwygEditor } from '../../../../../../components/Wysiwyg';

const SlideDescription = ({ description, index, updateAction }) => {
  return (
    <div className='Description'>
      <Form.Group>
        <Form.Label>
          Text
        </Form.Label>
        <WysiwygEditor value={description.content ? description.content : ''}
                       onChange={(content) => updateAction(index, content)}/>
      </Form.Group>
    </div>
  );
};

export { SlideDescription };
