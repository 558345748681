import { BookService } from './book.service';
import { TaskService } from './task.service';
import { QuestionService } from './question.service';
import { ImageService } from './image.service';
import { LANG } from '../config/config';
import { EmptyTask, Task } from '../classes/Task';
import { NotFoundError } from '../classes/Error';
import { Slide } from '../classes/Slide';
import { Asset, IMAGE_TYPE } from '../classes/Asset';
import { DescriptionService } from './description.service';
import { Description, DESCRIPTION_TYPE } from '../classes/Description';

class SlideService {

    bookId;
    taskName;
    slides;

    QS = new QuestionService();
    DS = new DescriptionService();
    IS = new ImageService(this.bookId);


    constructor(bookId, taskName) {
        // console.log('SlideService', bookId, taskName)
        this.bookId = bookId;
        this.taskName = taskName;
        this.isIntro = taskName === 'intro';
        this.ImageService = new ImageService(bookId);
    }

    getSlides() {
        const bookService = new BookService(this.bookId)

        return bookService.getBook()
            .then(book => {

                const prevBookPages = [{path: '/books/', title: LANG.BooksList}, {path: `/book/${book.id}`, title: book.name}];

                const emptyTask = new EmptyTask();
                const emptySlides = {task: emptyTask, slides: [], prevPages: prevBookPages}

                const tasks = book.tasks
                if(!tasks.length) return emptySlides

                const task = this._findTaskByName(tasks, this.taskName);

                // task not found
                if(!task) {
                    // throw exception
                    if(!this.isIntro) throw new NotFoundError(LANG.TaskNotFound)

                    // create new intro task
                    emptyTask.name = 'intro'
                    emptySlides.task = emptyTask
                    return emptySlides
                }

                // task was found
                // console.log('found task', task)
                return {task: task, slides: task.slides, prevPages: prevBookPages}

            })
    }

    save(slides) {
        // console.log('SAVING SLIDES', slides);

        const
            bookService = new BookService(this.bookId),
            taskService = new TaskService(this.bookId);

        let loadedBook;

        return bookService.getBook()
            .then(book => {
                loadedBook = book;
                return taskService.getTasks()
            })
            .then(({tasks}) => {
                let taskIdx = null;
                let task = null;

                // try to find task or throw an error
                task = this._findTaskByName(tasks, this.taskName);
                if(!task) {
                    if(this.taskName !== 'intro') throw new Error(LANG.TaskNotFound)

                    // create new task with added slides
                    task = new Task('intro', '', 0, 0, slides)

                    // add task to beggining of array
                    tasks.unshift(task);
                } else {

                    // change slides in the task
                    taskIdx = tasks.indexOf(task);
                    task.slides = slides;

                    // change tasks
                    tasks[taskIdx] = task;
                }

                // change book-page
                loadedBook.tasks = tasks;

                // console.log('book-page to save', loadedBook);

                // save book-page
                return bookService.save(loadedBook);
            })
            .catch((err) => {
                throw new Error(err)
            })
    }

    convertSlides(object) {
        // console.log(object);

        let array = object.length ? object : [object];

        return Promise.all(array.map((slide, index) => {
            // array[index] =
            // console.log('slides convert '+index);
            slide.innerID = index;
            return this._convertToClass(slide);
        })).then(slides => {
            // console.log('converted slides', slides)

            return slides
        })
    }

    _findTaskByName(tasks, name) {
        const filteredTasks = tasks.filter(task => {
            return task.name === name;
        });

        return filteredTasks.length ? filteredTasks[0] : null;
    }

    _convertToClass = obj => {
        // console.log('OBJ SLIDE', obj);
        const
          title = obj._attributes.title,
          layout = obj._attributes.layout,
          bgImage = obj._attributes.bgimage,
          desc = obj.description ? obj.description._cdata : '',
          is3d = !!obj._attributes.type,
          scenarioId = obj._attributes.id,
          innerID = obj.innerID;

        // contents
        let questions = [];
        let descriptions = [];
        let images = [];
        let videos = [];
        let audios = [];
        if (obj.questions) {
            // questions
            if (obj.questions.question) {
                const objQuestions = obj.questions.question;
                questions = objQuestions.length ? objQuestions : [objQuestions];
            }
            if (questions.length) {
                questions = questions.map((item) => this.QS.convertToClass(item, this.bookId));
            }

            // descriptions
            if (obj.questions.description) {
                const objQuestions = obj.questions.description;
                descriptions = objQuestions.length ? objQuestions : [objQuestions];
            }
            if (descriptions.length) {
                descriptions = descriptions.map((item) => this.DS.convertToClass(item));
            }

            // images
            if (obj.questions.images) {
                const objQuestions = obj.questions.images;
                images = objQuestions.length ? objQuestions : [objQuestions];
            }
            if (images.length) {
                images = images.map((item) => this.IS.convertImageToClass(item));
            }

            // video
            if (obj.questions.video) {
                const objVideo = obj.questions.video;
                videos = objVideo.length ? objVideo : [objVideo];
            }
            if (videos.length) {
                videos = videos.map((item) => this.IS.convertVideoToClass(item));
            }

            // audio
            if (obj.questions.audio) {
                const objAudio = obj.questions.audio;
                audios = objAudio.length ? objAudio : [objAudio];
            }
            if (audios.length) {
                audios = audios.map((item) => this.IS.convertAudioToClass(item));
            }
        }

        const contents = [...questions, ...descriptions, ...images, ...videos, ...audios];
        contents.sort((a, b) => (parseInt(a.order) > parseInt(b.order)) ? 1 : -1);  //DS: zmenil jsem string (a.order > b.order) na int, aby fungovalo razeni, pokud je vice jak 9 polozek v seznamu (pouzival se alfabeticky order)

        // add image tag as content
        if (obj.images && obj.images._text && obj.images._text.length) {
            contents.unshift(new Asset(IMAGE_TYPE.DEFAULT, 2, obj.images._text));
        }

        // add description as content
        if (desc && desc.length) {
            contents.unshift(new Description(DESCRIPTION_TYPE.TEXT, desc, 1));
        }

        return new Slide(title ? title : '',
          layout ? layout : '',
          bgImage ? new Asset(IMAGE_TYPE.DEFAULT, null, bgImage) : new Asset(),
          is3d,
          scenarioId ? scenarioId : '',
          null,
          contents,
          innerID);
    }



}

export {SlideService}
