import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import React from 'react';
import Table from "react-bootstrap/Table";
import {UserService} from "../../../services/user.service";
import {AuthService} from "../../../services/auth.service";
import {ROLE} from "../../../config/constants/role";
import {LANG} from "../../../config/config";
import {NAME_CLAIM} from "../../../config/constants/claim";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {Filter} from "./Filter";
import {User} from "../../../classes/User";
import {Invitation} from "../../../classes/Invitation";
import {DeleteButton} from "../../../components/Buttons/DeleteButton";
import {EditUserButton} from "./EditUserButton";

export class UserList extends React.Component {

    constructor(props) {
        super(props);

        this.UserService = new UserService();
        this.AuthService = new AuthService();

        const allUsers = props.users;

        this.state = {
            me: null,
            roles: {
                0: 'ALL',
                1: ROLE.Editor,
                2: ROLE.User,
                3: ROLE.InstitutionAdmin,
                4: ROLE.Sysadmin
            },
            activeRole: 0,
            users: allUsers,
            filteredUsers: allUsers,
            invitations: props.invitations
        };
    }

    componentDidMount() {
        let profile = this.AuthService.getProfile();
        if (profile && profile[NAME_CLAIM]) {
            this.setState({
                me: profile[NAME_CLAIM]
            });
        }
    }

    handleDelete = (entity) => {
        if (entity instanceof User) {
            this.handleUserDelete(entity.email);
        } else if (entity instanceof Invitation) {
            this.handleInvitationDelete(entity.id);
        }
    };

    handleUserDelete = (email) => {
        if (!email) return;
        this.UserService.deleteUser(email)
            .then(() => {
                this.props.update();
            });
    };

    handleInvitationDelete = (id) => {
        if (!id) return;
        this.UserService.deleteInvitation(id)
            .then(() => {
                this.props.update();
            });
    };

    filterUsers = (id) => {

        let filteredUsers = this.state.users;

        if (id > 0) {
            const role = this.state.roles[id];

            filteredUsers = this.state.users.filter((user) => {
                return user.roles.includes(role);
            });
        }

        this.setState({
            filteredUsers: filteredUsers,
            activeRole: id
        });
    };

    render() {
        const { me, users, filteredUsers, activeRole, invitations } = this.state;

        return (
            <div className='flex-grow-1'>

                {users.length === 0 && <div className='alert alert-success mt-3 mb-5'>
                    <span className='mr-1'>{LANG.NoUsersInInstitution}</span>
                    {invitations.length === 0 && 'Pokračujte přidáním nového uživatele'}
                    {invitations.length > 0 && 'Vyčkejte na přijetí pozvánek'}
                </div>}

                {invitations.length > 0 && <div className='mt-4 mb-5'>
                    <h3>{LANG.Invitations}</h3>
                    {invitations.length === 0 &&
                    <div className='alert alert-success my-3'>{LANG.AddUsersByInvitation}</div>}
                    {invitations.length > 0 && <div>

                        <Table striped bordered className='users'>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>{LANG.Email}</th>
                                <th>{LANG.Roles}</th>
                                <th>{LANG.Actions}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {invitations.map((inv, index) =>
                                <tr key={index}>
                                    <th scope="row" className="text-center">{index + 1}</th>
                                    <td>{inv.email}</td>
                                    <td>
                                        <div className='Users-User-Roles'>
                                            {inv.roles.map((role, key) => {
                                                switch (role) {
                                                    case ROLE.Editor:
                                                        return <OverlayTrigger key={`overlay-${key}`} placement='bottom'
                                                                               overlay={<Tooltip
                                                                                   id={`tooltip-${key}`}>{LANG.roles.Editor}</Tooltip>}>
                                                            <button className='btn btn-invisible'><FontAwesomeIcon
                                                                key={key} icon="user-tie"/></button>
                                                        </OverlayTrigger>;
                                                    case ROLE.User:
                                                        return <OverlayTrigger key={`overlay-${key}`} placement='bottom'
                                                                               overlay={<Tooltip
                                                                                   id={`tooltip-${key}`}>{LANG.roles.User}</Tooltip>}>
                                                            <button className='btn btn-invisible'><FontAwesomeIcon
                                                                key={key} icon="user-graduate"/></button>
                                                        </OverlayTrigger>;
                                                    default:
                                                        return null;
                                                }
                                            })}
                                        </div>
                                    </td>
                                    <td className={'text-center'}>
                                        <DeleteButton action={() => this.handleDelete(inv)}
                                                      message={LANG.AreYouSure + ' ' + LANG.CancelInvitation + ' ' + inv.email + '?'}/>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                    </div>}
                </div>}

                {users.length > 0 && <>
                    <div className='institution justify-content-between'>
                        <h3>{LANG.ActiveUsers}</h3>
                        <Filter activeRole={activeRole} updateActon={this.filterUsers}/>
                    </div>

                    <Table striped bordered className='users'>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>{LANG.Email}</th>
                            <th>{LANG.Roles}</th>
                            <th>{LANG.Actions}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {filteredUsers.map((user, index) =>
                            <tr key={index}>
                                <th scope="row" className="text-center">{index + 1}</th>
                                <td>{user.email}</td>
                                <td>
                                    <div className='Users-User-Roles'>
                                        {user.roles.map((role, key) => {
                                            switch (role) {
                                                case ROLE.Editor:
                                                    return <OverlayTrigger key={`overlay-${key}`} placement='bottom'
                                                                           overlay={<Tooltip
                                                                               id={`tooltip-${key}`}>{LANG.roles.Editor}</Tooltip>}>
                                                        <button className='btn btn-invisible'><FontAwesomeIcon key={key}
                                                                                                               icon="user-tie"/>
                                                        </button>
                                                    </OverlayTrigger>;
                                                case ROLE.User:
                                                    return <OverlayTrigger key={`overlay-${key}`} placement='bottom'
                                                                           overlay={<Tooltip
                                                                               id={`tooltip-${key}`}>{LANG.roles.User}</Tooltip>}>
                                                        <button className='btn btn-invisible'><FontAwesomeIcon key={key}
                                                                                                               icon="user-graduate"/>
                                                        </button>
                                                    </OverlayTrigger>;
                                                case ROLE.InstitutionAdmin:
                                                    return <OverlayTrigger key={`overlay-${key}`} placement='bottom'
                                                                           overlay={<Tooltip
                                                                               id={`tooltip-${key}`}>{LANG.roles.InstitutionAdmin}</Tooltip>}>
                                                        <button className='btn btn-invisible'><FontAwesomeIcon key={key}
                                                                                                               icon="user-cog"/>
                                                        </button>
                                                    </OverlayTrigger>;
                                                case ROLE.Sysadmin:
                                                    return <OverlayTrigger key={`overlay-${key}`} placement='bottom'
                                                                           overlay={<Tooltip
                                                                               id={`tooltip-${key}`}>{LANG.roles.Sysadmin}</Tooltip>}>
                                                        <button className='btn btn-invisible'><FontAwesomeIcon key={key}
                                                                                                               icon="user-shield"/>
                                                        </button>
                                                    </OverlayTrigger>;
                                                default:
                                                    return null;
                                            }
                                        })}
                                    </div>
                                </td>
                                <td className={'text-center'}>
                                    <EditUserButton user={user} update={this.props.update}/>
                                    {user.email !== me && <DeleteButton action={() => this.handleDelete(user)}
                                                                        className={'ml-2'}
                                                                        message={LANG.AreYouSure + ' ' + LANG.DeleteUser + ' ' + user.email + '?'}/>}
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                </>}
            </div>
        );
    }


}
